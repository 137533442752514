import { BUTTON, SUBSCRIPTION_LINK, TOOLTIP, coreMessage } from '@capasystems/constants';
import {
    Button,
    ButtonBase,
    CapaOneBranding,
    CapaoneApplicationSwitcher,
    CopyTextButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    EmptyState,
    Grid,
    Icon,
    IconButton,
    LayoutCenter,
    LayoutCentered,
    LayoutColumn,
    LayoutRow,
    Loading,
    MainContent,
    NavLink,
    Navigation,
    Padding,
    Paper,
    Select,
    Sidebar,
    Tab,
    Tabs,
    Tooltip,
    Topbar,
    useLocation,
    useParams,
} from '@capasystems/ui';
import { dispatchResizeEvent, formatTimestamp, useWindowSize } from '@capasystems/utils';
import { LOCAL_STORAGE_ID, PRODUCT_NAME, windowsApplication } from '@thirdparty/constants';
import {
    ActionsDialog,
    CapaOneLink,
    ConfirmDialog,
    CoreContext,
    DeviceFilterAction,
    GroupListManagement,
    GroupManagement,
    Heading,
    IntegrationManagementLandingPage,
    PageNotFound,
    PageTitle,
    PreReleaseTag,
    PrivateLandingPage,
    ReliabilityContextProvider,
    ReliabilityDashboardPage,
    ReliabilityPermissionsCheck,
    ReportingManagementLandingPage,
    TutorialsPage,
    UserManagementUserID,
    UserManagementUserList,
    WindowsApplicationConfigDetails,
    WindowsApplicationNameAndVersion,
    WindowsApplicationThumbnail,
    WindowsEndpointContextProvider,
    WithExperimentalFeatures,
    useApi,
    useAuthContext,
    useCoreContext,
    useCurrentUnsavedChanges,
    useDevicesSocket,
    useLocalStorageState,
    useNavigate,
} from '@thirdparty/ui';

import frontendBuildInfo from 'buildInfo';
import classNames from 'classnames';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UploadContext } from './components/contenxt/UploadContext';
import WindowsEditScript from './pages/WindowsEditScript';
import { SelectedConfigurationContent } from './pages/WindowsEnrollment';
import WindowsNewRepositoryApp from './pages/WindowsNewRepositoryApp';
import WindowsNewScript from './pages/WindowsNewScript';
const UploadingPage = React.lazy(() => import('./pages/WindowsUploadStatusCustomApp'));
const Workflow = React.lazy(() => import('./pages/Workflow'));
const Settings = React.lazy(() => import('./pages/settings/Settings'));
const WorkflowEditor = React.lazy(() => import('./pages/WorkflowEditor'));
const ViewWorkflow = React.lazy(() => import('./pages/ViewWorkflow'));
const AdminOnDemandLandingPage = React.lazy(() => import('./pages/adminOnDemand/AdminOnDemandLandingPage'));
const DevicesLandingPage = React.lazy(() => import('./pages/DevicesLandingPage'));
const WindowsEndpoint = React.lazy(() => import('./pages/WindowsEndpoint'));
const WindowsDashboards = React.lazy(() => import('./pages/WindowsDashboards'));
const WindowsExperienceDashboard = React.lazy(() => import('./pages/WindowsExperienceDashboard'));
const WindowsSecurityLandingPage = React.lazy(() => import('./pages/WindowsSecurityLandingPage'));
const UpdaterLandingPage = React.lazy(() => import('./pages/UpdaterLandingWrapper'));
const UpdaterApplicationManagement = React.lazy(() => import('./pages/UpdaterApplicationManagementWrapper'));
const AndroidEnrollment = React.lazy(() => import('./pages/AndroidEnrollmentWrapper'));
const AndroidCreateEnrollmentConfiguration = React.lazy(() => import('./pages/AndroidCreateEnrollmentConfigurationWrapper'));
const AndroidDeviceList = React.lazy(() => import('./pages/AndroidDeviceListWrapper'));
const AndroidEndpoint = React.lazy(() => import('./pages/AndroidEndpointWrapper'));
const AndroidApplicationList = React.lazy(() => import('./pages/AndroidApplicationListWrapper'));
const AndroidConfigurationCreateAndEdit = React.lazy(() => import('./pages/AndroidConfigurationCreateAndEditWrapper'));
const AndroidApplicationCreateAndEdit = React.lazy(() => import('./pages/AndroidApplicationCreateAndEditWrapper'));
const AndroidApplicationAndConfigurationMembershipManagement = React.lazy(
    () => import('./pages/AndroidApplicationAndConfigurationMembershipManagementWrapper'),
);
const AndroidConfigurationList = React.lazy(() => import('./pages/AndroidConfigurationListWrapper'));
const WindowsEnrollment = React.lazy(() => import('./pages/WindowsEnrollment'));
const WindowsCreateAndEditEnrollmentConfiguration = React.lazy(() => import('./pages/WindowsCreateAndEditEnrollmentConfiguration'));
const EditIntegration = React.lazy(() => import('./pages/EditIntegration'));
const WindowsConfigurationList = React.lazy(() => import('./pages/WindowsConfigurationList'));
const WindowsConfigurationMembership = React.lazy(() => import('./pages/WindowsConfigurationMembership'));
const WindowsConfigurationCreateAndEditAod = React.lazy(() => import('./pages/WindowsConfigurationCreateAndEditAod'));
const WindowsConfigurationCreateAndEditDrivers = React.lazy(() => import('./pages/WindowsConfigurationCreateAndEditDrivers'));
const DriverLandingPage = React.lazy(() => import('./pages/DriverLandingWrapper'));
const WindowsApplicationList = React.lazy(() => import('./pages/WindowsApplicationList'));
const WindowsNewCustomApp = React.lazy(() => import('./pages/WindowsNewCustomApp'));
const WindowsEditCustomApp = React.lazy(() => import('./pages/WindowsEditCustomApp'));
const WindowsEditRepositoryApp = React.lazy(() => import('./pages/WindowsEditRepositoryApp'));
const WindowsApplicationMembershipManagement = React.lazy(() => import('./pages/WindowsApplicationMembership'));
const AppleEndpointList = React.lazy(() => import('./pages/apple/AppleEndpointListWrapper'));
const AppleEnrollment = React.lazy(() => import('./pages/apple/AppleEnrollmentWrapper'));
const AppleCreateEnrollmentConfiguration = React.lazy(() => import('./pages/apple/AppleCreateEnrollmentConfigurationWrapper'));
const AppleEndpoint = React.lazy(() => import('./pages/apple/AppleEndpointWrapper'));
const AppleApplicationList = React.lazy(() => import('./pages/apple/AppleApplicationListWrapper'));
const AppleConfigurationCreateAndEdit = React.lazy(() => import('./pages/apple/AppleConfigurationCreateAndEditWrapper'));
const AppleApplicationCreateAndEdit = React.lazy(() => import('./pages/apple/AppleApplicationCreateAndEditWrapper'));
const AppleApplicationAndConfigurationMembershipManagement = React.lazy(
    () => import('./pages/apple/AppleApplicationAndConfigurationMembershipManagementWrapper'),
);
const AppleConfigurationList = React.lazy(() => import('./pages/apple/AppleConfigurationListWrapper'));
const AppleCertificates = React.lazy(() => import('./pages/apple/AppleCertificatesWrapper'));
const AppleFirstTimeSetup = React.lazy(() => import('./pages/apple/AppleFirstTimeSetupWrapper'));
const AppleVppLicenseList = React.lazy(() => import('./pages/apple/AppleVppLicenseListWrapper'));
const AppleVPPAssignmentsList = React.lazy(() => import('./pages/apple/AppleVPPAssignmentsListWrapper'));

const logoutAction = {
    id: 'logout',
    name: coreMessage.logOut,
};

const accessDeniedFilter = ({ accessDenied }) => !accessDenied;
// const showResponsiveBreakpoints = process.env.NODE_ENV === 'development';

const ApplicationLayoutAndRoutes = () => {
    return (
        <>
            <ApplicationTopBar />
            <ApplicationSidebar />
            <ApplicationContent />
        </>
    );
};

const beamerInitialize = (user) => {
    if (!window) {
        return;
    }

    window.beamer_config = {
        ...window.beamer_config,
        product_id: 'lZOrNgaZ52949', //Do not change, product code on Beamer. //TODO Move to env variable
        button: false,
        user_firstname: user.firstName || '',
        user_lastname: user.lastName || '',
        user_id: user.id || '',
        user_email: user.email || '',
    };
    var beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js';
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.defer = 'defer';
    script.src = beamerURL;
    head.appendChild(script);
};

const ApplicationTopBar = () => {
    const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null,
        actions: [],
        pages: [],
    });
    const { logout, user, organizations, contracts, selectedOrganization, switchOrganization } = useAuthContext();
    const [currentSelectedOrganizations, setCurrentSelectedOrganizations] = useState([selectedOrganization]);
    const { setGettingStartedIsVisible, showBetaFlag } = useContext(CoreContext);
    const navigate = useNavigate();

    const openActionsDialog = (e) => {
        const actions = [];

        actions.push(logoutAction);
        setActionsDialogState({
            open: true,
            anchorEl: e.currentTarget,
            actions,
            pages: [
                {
                    name: 'My subscriptions',
                    url: SUBSCRIPTION_LINK,
                    target: '_blank',
                },
            ],
        });
    };

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onSwitchOrganization = () => {
        switchOrganization(currentSelectedOrganizations[0].id);
    };

    const onActionClick = (action) => {
        closeActionsDialog();
        switch (action.id) {
            case logoutAction.id:
                logout();
                break;
        }
    };

    useEffect(() => {
        beamerInitialize(user);
    }, []);

    return (
        <Topbar>
            <CapaOneBranding
                to={navigate.appendBaseURL('')}
                contracts={contracts}
                organizationId={user.organization.id}
                isParentOrganization={user.organization.isParentOrganization}
                showBetaFlag={showBetaFlag}
            />
            <LayoutRow
                verticalAlign="center"
                align="end"
                fill
            >
                {showBetaFlag && (
                    <WithExperimentalFeatures tooltipPosition={TOOLTIP.POSITION.LEFT}>
                        <div>
                            <Tooltip content="CapaOne administration">
                                <IconButton href={navigate.appendBaseURL('administration/capaone')}>
                                    <Icon
                                        type="verifiedUser"
                                        className="tw-text-orange-600"
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </WithExperimentalFeatures>
                )}
                <UploadView />
                <Tooltip content="Notifications">
                    <IconButton
                        id="beamerButton"
                        className="beamerTrigger"
                    >
                        <Icon type="notificationsActive"></Icon>
                    </IconButton>
                </Tooltip>
                <SystemInfoButton />
                <div style={{ maxWidth: '25vw' }}>
                    <Select
                        options={organizations}
                        selectedOptions={currentSelectedOrganizations}
                        fullWidth={false}
                        light
                        rounded
                        required
                        placeholder="Organization"
                        onChange={setCurrentSelectedOrganizations}
                        onExited={onSwitchOrganization}
                        inputClassName="tw-font-semibold tw-cursor-pointer tw-text-xs"
                        containerClassName="hover:tw-bg-slate-50 tw-cursor-pointer tw-border-gray-100"
                    />
                </div>
                <Button
                    noMargin
                    onClick={openActionsDialog}
                    className="tw-ml-1 tw-rounded-full tw-px-3 tw-py-2 tw-text-xs hover:tw-bg-slate-50"
                >
                    &nbsp;
                    {user.firstName}
                    <span className="tw-hidden md:tw-inline-block">
                        &nbsp;
                        {user.lastName}
                    </span>
                    &nbsp;
                    <Icon
                        type="chevronDown"
                        size="small"
                    />
                </Button>
                <ActionsDialog
                    title={user.firstName + ' ' + user.lastName}
                    description={<span className="tw-font-medium tw-text-gray-800">{user.email}</span>}
                    onClose={closeActionsDialog}
                    onActionClick={onActionClick}
                    {...actionsDialogState}
                />
                <CapaoneApplicationSwitcher
                    contracts={contracts}
                    authToken={user.authToken}
                    exclude={[11, 12, 13]}
                />
            </LayoutRow>
        </Topbar>
    );
};

const UploadView = () => {
    const { windowsApplications, removeCustomApplication } = useContext(UploadContext);
    const navigate = useNavigate();
    const api = useApi();
    const [deleteState, setDeleteState] = useState({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
        errorMessage: '',
        rowData: {},
    });

    if (windowsApplications.length === 0) {
        return null;
    }

    const requiredConfigFields = (config) => {
        if (!config.commandLineOptions || config.commandLineOptions.trim() === '') {
            return false;
        }
        if (!config.uninstallCommand || config.uninstallCommand.trim() === '') {
            return false;
        }
        if (!config.version || config.version.trim() === '') {
            return false;
        }
        return true;
    };

    const handleClick = (application) => {
        if (application.fileInfo.fileUploaded === false) {
            navigate.to(`windows/application/uploading/${application.id}`);
        } else if (application.fileInfo.fileUploaded === true) {
            if (requiredConfigFields(application.config)) {
                navigate.to(`windows/application/${application.id}/membership`);
            } else {
                navigate.to(`windows/application/${application.id}/edit/customapps?mode=create`);
            }
        } else {
            setTimeout(() => {
                removeCustomApplication(application.id);
            }, 1000);
        }
    };

    const onDelete = () => {
        api.deleteCustomApplication(deleteState.rowData.id, true)
            .then(() => {
                closeDeleteDialog();
                removeCustomApplication(deleteState.rowData.id);
            })
            .catch((e) => {
                setDeleteState({
                    open: true,
                    anErrorOccurred: true,
                    errorMessage: e.data,
                });
            });
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    return (
        <>
            <Tooltip
                position={TOOLTIP.POSITION.TOP_END}
                noPadding
                interactive
                fullWidth
                content={
                    <div
                        className="tw-overflow-auto tw-p-4"
                        style={{ maxHeight: 280 }}
                    >
                        <div className="tw-grid-rows-1fr tw-grid tw-gap-x-2 tw-gap-y-4">
                            {windowsApplications.map((application) => (
                                <LayoutRow>
                                    <div
                                        key={application.id}
                                        onClick={() => handleClick(application)}
                                    >
                                        <WindowsApplicationThumbnail application={application} />
                                        <CapaOneLink
                                            to={`windows/application/uploading/${application.id}`}
                                            color="inherit"
                                        >
                                            <LayoutRow>
                                                <div className="tw-grid tw-gap-0.5">
                                                    <div className="tw-text-xs tw-font-semibold">
                                                        <WindowsApplicationNameAndVersion application={application} />
                                                    </div>
                                                    <div className="tw-text-tiny tw-font-medium">
                                                        <WindowsApplicationConfigDetails application={application} />
                                                    </div>
                                                </div>
                                                <div className="tw-grid tw-gap-0.5 tw-pl-2">
                                                    <span>Total progress: {Math.trunc(application?.fileInfo?.totalProgress) || 0}%</span>
                                                    <span>Current status: {application?.fileInfo?.uploadStatus || 'Starting upload'}</span>
                                                </div>
                                            </LayoutRow>
                                        </CapaOneLink>
                                    </div>
                                    {application?.fileInfo?.failed && (
                                        <LayoutCentered>
                                            <IconButton
                                                onClick={(e) => {
                                                    setDeleteState({ ...deleteState, open: true, rowData: application });
                                                }}
                                            >
                                                <Icon
                                                    type="removeOutlined"
                                                    className="tw-text-white"
                                                ></Icon>
                                            </IconButton>
                                        </LayoutCentered>
                                    )}
                                </LayoutRow>
                            ))}
                        </div>
                    </div>
                }
            >
                <div className="tw-mx-4 tw-flex tw-items-center tw-gap-2">
                    <Icon
                        type="uploadOutlined"
                        size="large"
                        className="tw-animate-pulse tw-text-emerald-500"
                    />
                </div>
            </Tooltip>
            <ConfirmDialog
                onConfirm={onDelete}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                isProcessing={false}
                name={deleteState.rowData.name}
                title={`Delete ${deleteState.rowData.name}?`}
                error={deleteState.anErrorOccurred && deleteState.errorMessage}
            >
                Are you sure you want to delete this application?
            </ConfirmDialog>
        </>
    );
};

const ApplicationSidebar = () => {
    const [isCollapsed, setIsCollapsed] = useLocalStorageState(LOCAL_STORAGE_ID.SIDE_NAV_COLLAPSE, false);
    const windowSize = useWindowSize();
    const { managementSpace } = useCoreContext();

    const forceCollapsed = windowSize.width < 1280;

    const collapsed = isCollapsed || forceCollapsed;

    return (
        <Sidebar
            className={classNames('tw-min-w-0 tw-p-0', {
                'cs-sidebar-full': !collapsed,
            })}
        >
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr-auto">
                <div>
                    <Routes>
                        <Route
                            path=":namespace/*"
                            element={<SectionSwitcher />}
                        />
                    </Routes>
                </div>
                <div className="tw-h-full tw-overflow-auto tw-overflow-x-hidden">
                    <Routes>
                        <Route
                            path="windows/*"
                            element={<WindowsSidebar isCollapsed={collapsed} />}
                        />
                        <Route
                            path="android/*"
                            element={<AndroidSidebar isCollapsed={collapsed} />}
                        />
                        <Route
                            path="apple/*"
                            element={<AppleSidebar isCollapsed={collapsed} />}
                        />
                        <Route
                            path={`${managementSpace.namespace}/*`}
                            element={<ManagementSidebar isCollapsed={collapsed} />}
                        />
                        <Route
                            path="*"
                            element={<HideSidebar />}
                        />
                    </Routes>
                </div>
                {!forceCollapsed && (
                    <div>
                        <hr />
                        <Tooltip
                            content={isCollapsed ? 'Expand the sidebar' : 'Collapse the sidebar'}
                            position={TOOLTIP.POSITION.RIGHT}
                        >
                            <Button
                                fullWidth
                                onClick={() => {
                                    setIsCollapsed(!isCollapsed);
                                    setTimeout(dispatchResizeEvent);
                                }}
                                noMargin
                                className="tw-rounded-none tw-py-3 tw-text-slate-600 hover:tw-text-slate-700"
                                size="large"
                            >
                                <Icon
                                    type="doubleArrow"
                                    className={classNames({
                                        'tw-rotate-180': !isCollapsed,
                                    })}
                                    size="small"
                                />
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </div>
        </Sidebar>
    );
};

const HideSidebar = () => {
    useEffect(() => {
        const dataAttr = 'data-sidebar-control';
        document.getElementById('root').setAttribute(dataAttr, true); // Used to control sidebar width.
        return () => {
            document.getElementById('root').removeAttribute(dataAttr);
        };
    }, []);
    return null;
};

const FirstTimeUser = () => {
    const { availableWindowsServices, availableAndroidServices, availableAppleServices, managementSpace } = useContext(CoreContext);
    const api = useApi();
    const [configuration, setConfiguration] = useState();

    const navigate = useNavigate();

    useDevicesSocket(() => {
        window.location.reload();
    });

    useEffect(() => {
        api.getEnrollmentConfiguration().then((response) => {
            if (response === null || response.length === 0) {
                api.createEnrollment('Default', 'This is the default enrollment', [], [], [])
                    .then((response) => {
                        setConfiguration(response);
                    })
                    .catch(() => null);
            } else {
                setConfiguration(response[0]);
            }
        });
    }, []);

    const [step, setStep] = useState(0);

    if (
        availableAndroidServices.length === 0 &&
        availableWindowsServices.length === 0 &&
        availableAppleServices.length === 0 &&
        managementSpace.services.length === 0
    ) {
        return (
            <MainContent className="tw-p-0">
                <div className="tw-h-full tw-overflow-auto tw-bg-slate-900 tw-bg-gradient-to-b tw-from-slate-900 tw-to-slate-800 tw-p-8 tw-text-white">
                    <LayoutCenter fill>
                        <Paper className="tw-min-w-[600px] tw-max-w-md tw-p-4 ">
                            <div className="tw-text-center">Welcome</div>
                            <div className="tw-text-center tw-text-xl tw-font-bold">Let's get started</div>
                            <p className="tw-mt-4 tw-text-center">
                                This organization does not have any contracts, please contact sales. Or add contracts to the organization via the portal, if it
                                is a sub organization.
                            </p>
                        </Paper>
                    </LayoutCenter>
                </div>
            </MainContent>
        );
    }

    if (step === 1) {
        return (
            <MainContent className="tw-p-0">
                <div className="tw-h-full tw-overflow-auto tw-bg-slate-900 tw-bg-gradient-to-b tw-from-slate-900 tw-to-slate-800 tw-p-8 tw-text-white">
                    <Paper
                        className="tw-mx-auto tw-grid tw-h-full tw-grid-rows-auto-1fr-auto tw-overflow-auto"
                        style={{ maxWidth: '90vw', width: 900 }}
                    >
                        <div className="tw-p-4 tw-pb-2">
                            <div className="tw-text-xl tw-font-semibold">Enroll your first endpoint</div>
                            <div className="tw-text-sm tw-font-medium">We will continue when the first endpoint is registered</div>
                        </div>
                        <div className="tw-grid tw-grid-rows-auto-1fr tw-overflow-auto">
                            <SelectedConfigurationContent selectedConfiguration={configuration} />
                        </div>
                        <div className="tw-flex tw-justify-end tw-p-4 tw-pb-2">
                            <Button
                                variant={BUTTON.RAISED}
                                onClick={() => setStep(0)}
                                noMargin
                            >
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </div>
            </MainContent>
        );
    }

    return (
        <MainContent className="tw-p-0">
            <div className="tw-h-full tw-overflow-auto tw-bg-slate-900 tw-bg-gradient-to-b tw-from-slate-900 tw-to-slate-800 tw-p-8 tw-text-white">
                <LayoutCenter fill>
                    <Paper className="tw-min-w-[600px] tw-p-4">
                        <div className="tw-text-center">Welcome</div>
                        <div className="tw-text-center tw-text-xl tw-font-bold">Let's get started</div>
                        <div className="tw-mt-8 tw-flex tw-gap-4">
                            {availableWindowsServices.length > 0 && (
                                <div
                                    className="tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-rounded tw-bg-slate-100 tw-p-8 hover:tw-bg-slate-200"
                                    onClick={() => setStep(1)}
                                >
                                    <Icon
                                        type="windows"
                                        size="150"
                                        className="tw-text-windows"
                                    />
                                    <p className="tw-mt-2 tw-text-xl">Start with Windows</p>
                                </div>
                            )}
                            {availableAndroidServices.length > 0 && (
                                <div
                                    className="tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-rounded tw-bg-slate-100 tw-p-8 hover:tw-bg-slate-200"
                                    onClick={() => {
                                        navigate.to('android/enrollment');
                                    }}
                                >
                                    <Icon
                                        type="android"
                                        className="tw-text-[150px] tw-text-android"
                                    />
                                    <p className="tw-mt-2 tw-text-xl">Start with Android</p>
                                </div>
                            )}
                            {availableAppleServices.length > 0 && (
                                <div
                                    className="tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-rounded tw-bg-slate-100 tw-p-8 hover:tw-bg-slate-200"
                                    onClick={() => {
                                        navigate.to('apple/enrollment');
                                    }}
                                >
                                    <Icon
                                        type="apple"
                                        className="tw-text-[150px] tw-text-appleDark"
                                        size="150"
                                    />
                                    <p className="tw-mt-2 tw-text-xl">Start with Apple</p>
                                </div>
                            )}
                        </div>
                    </Paper>
                </LayoutCenter>
            </div>
        </MainContent>
    );
};

const ApplicationContent = () => {
    const { totalWindowsAgentCountOnInit, managementSpace, androidEnterpriseAccount, appleEnterpriseAccount } = useContext(CoreContext);
    const location = useLocation();

    if (totalWindowsAgentCountOnInit === 0 && !androidEnterpriseAccount?.id && !appleEnterpriseAccount?.id) {
        return (
            <WaitForCompontentLoad>
                <Routes>
                    <Route
                        path="/"
                        element={<FirstTimeUser />}
                    />
                    <Route
                        path="android/*"
                        element={<AndroidRoutes />}
                    />
                    <Route
                        path="apple/*"
                        element={<AppleRoutes />}
                    />
                    <Route
                        path="*"
                        element={<RedirectToLandingPage />}
                    />
                </Routes>
                {totalWindowsAgentCountOnInit > 0}
            </WaitForCompontentLoad>
        ); //GettingStarted not implemented atm
    }

    return (
        <MainContent className="tw-p-0">
            <WaitForCompontentLoad>
                <Routes>
                    <Route
                        path="/"
                        element={<ApplicationContentLandingPage />}
                    />
                    <Route
                        path="windows/*"
                        element={<WindowsRoutes />}
                    />
                    <Route
                        path="android/*"
                        element={<AndroidRoutes />}
                    />
                    <Route
                        path="apple/*"
                        element={<AppleRoutes />}
                    />
                    <Route
                        path={`${managementSpace.namespace}/*`}
                        element={<ManagementRoutes />}
                    />
                    <Route
                        path="help/*"
                        element={<HelpRoutes />}
                    />
                    <Route
                        path="access-denied/:message"
                        element={<AccessDeniedPage />}
                    />
                    <Route path="administration/capaone">
                        <Route
                            path=":tabId"
                            element={<CapaOneAdministrationRoutes />}
                        />
                        <Route
                            index
                            element={
                                <Navigate
                                    to="analytics"
                                    replace
                                />
                            }
                        />
                    </Route>
                    <Route
                        path="*"
                        element={<RedirectToLandingPage />}
                    />
                </Routes>
                {totalWindowsAgentCountOnInit > 0}
            </WaitForCompontentLoad>
            <UnsavedChangesHandler key={location.hash} />
        </MainContent>
    ); //GettingStarted not implemented atm
};

const UnsavedChangesHandler = () => {
    const currentUnsavedChanges = useCurrentUnsavedChanges();

    return (
        <Dialog
            open={currentUnsavedChanges.showWarning}
            confirm
            unmountOnExit
        >
            <DialogTitle>
                <div className="tw-text-base">Unsaved changes detected for</div>
                <div>{currentUnsavedChanges.name}</div>
            </DialogTitle>

            <DialogContent className="tw-text-sm tw-font-medium">Do you want to keep editing or discard the changes?</DialogContent>
            <DialogActions>
                <Button
                    noMargin
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                    onClick={currentUnsavedChanges.onKeepEditing}
                >
                    Keep editing
                </Button>
                <Button onClick={currentUnsavedChanges.onDiscardChanges}>Discard changes</Button>
            </DialogActions>
        </Dialog>
    );
};

const RedirectToLandingPage = () => {
    const { appendBaseURL } = useNavigate();
    return <Navigate to={appendBaseURL('')} />;
};

const ApplicationContentLandingPage = () => {
    const { user } = useAuthContext();
    const { availableEnrollments, managementSpace, availableHelpPages } = useContext(CoreContext);
    return (
        <div className="tw-h-full tw-overflow-auto tw-bg-slate-900 tw-bg-gradient-to-b tw-from-slate-900 tw-to-slate-800 tw-p-8 tw-text-white">
            <div className="tw-m-auto tw-max-w-screen-2xl">
                <h1>Hi {user.firstName},</h1>
                <b>Let's get started using {PRODUCT_NAME}</b>
                <WindowsSpaces />
                <AndroidSpaces />
                <AppleSpaces />
                <FeatureContainer
                    iconType={managementSpace.icon}
                    title={managementSpace.title}
                    services={managementSpace.services}
                    boxClassNames={managementSpace.boxClassNames}
                    iconClassNames={managementSpace.iconClassNames}
                    titleIconClassNames={managementSpace.iconClassNames}
                />
                <FeatureContainer
                    iconType="inherit"
                    title="Enrollment"
                    services={availableEnrollments}
                    boxClassNames="tw-bg-amber-100"
                    iconClassNames="tw-text-amber-400"
                    titleIconClassNames="tw-text-amber-400"
                />
                <FeatureContainer
                    iconType="infoOutlined"
                    title="Help"
                    services={availableHelpPages}
                    boxClassNames="tw-bg-emerald-100"
                    iconClassNames="tw-text-emerald-400"
                    titleIconClassNames="tw-text-emerald-400"
                />
            </div>
        </div>
    );
};

const WindowsSpaces = () => {
    const { availableWindowsServices } = useContext(CoreContext);
    return (
        <FeatureContainer
            iconType="windows"
            title="Windows"
            services={availableWindowsServices}
            boxClassNames="tw-bg-windows tw-bg-opacity-25"
            iconClassNames="tw-text-windows"
            titleIconClassNames="tw-text-windows"
        />
    );
};
const AndroidSpaces = () => {
    const { availableAndroidServices } = useCoreContext();
    return (
        <FeatureContainer
            iconType="android"
            title="Android"
            services={availableAndroidServices}
            boxClassNames="tw-bg-android tw-bg-opacity-25"
            iconClassNames="tw-text-android"
            titleIconClassNames={'tw-text-android'}
        />
    );
};

const AppleSpaces = () => {
    const { availableAppleServices } = useCoreContext();
    return (
        <FeatureContainer
            iconType="apple"
            title="Apple"
            services={availableAppleServices}
            boxClassNames="tw-bg-appleDark tw-bg-opacity-25"
            iconClassNames="tw-text-appleDark"
            titleIconClassNames="tw-text-appleLight"
        />
    );
};

// This is a copy of AppleSpaces, but with a different titleIconClassNames. Not a great solution.
const AppleSpacesDarkLogo = () => {
    const { availableAppleServices } = useCoreContext();
    return (
        <FeatureContainer
            iconType="apple"
            title="Apple"
            services={availableAppleServices}
            boxClassNames="tw-bg-appleDark tw-bg-opacity-25"
            iconClassNames="tw-text-appleDark"
            titleIconClassNames="tw-text-appleDark"
        />
    );
};

const FeatureContainer = ({ services, boxClassNames, iconType, title, iconClassNames, titleIconClassNames, isPreRelease }) => {
    const navigate = useNavigate();
    if (services.length === 0) {
        return null;
    }
    return (
        <>
            <LayoutRow
                className="tw-my-6"
                verticalAlign="center"
            >
                <Icon
                    type={iconType}
                    className={`tw-msx-12 tw-mr-4 tw-h-12 tw-w-12 ${titleIconClassNames}`}
                />
                <Heading bold>{title}</Heading>
                {isPreRelease ? (
                    <PreReleaseTag
                        className={'tw-mx-2'}
                        size={'tiny'}
                    />
                ) : null}
            </LayoutRow>
            <Grid
                container
                spacing={2}
            >
                {services.map((service) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={service.to}
                        >
                            <ButtonBase
                                className="tw-h-full tw-w-full"
                                onClick={() => navigate.to(service.to)}
                            >
                                <Paper className="tw-h-full tw-w-full tw-overflow-hidden tw-text-left">
                                    <LayoutRow fill>
                                        <div className={`tw-h-full ${boxClassNames}`}>
                                            <LayoutCenter>
                                                <Icon
                                                    type={service.icon}
                                                    className={`tw-mx-6 tw-my-4 tw-h-8 tw-w-8 ${iconClassNames}`}
                                                />
                                            </LayoutCenter>
                                        </div>

                                        <div className="tw-overflow-hidden tw-px-4 tw-py-4">
                                            <PageTitle description={<Ellipsis>{service.description}</Ellipsis>}>
                                                <Ellipsis>{service.text}</Ellipsis>
                                            </PageTitle>
                                        </div>
                                    </LayoutRow>
                                </Paper>
                            </ButtonBase>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const SectionSwitcher = () => {
    const params = useParams();
    const [hidden, setHidden] = useState(false);
    const { managementSpace, availableWindowsServices, availableAndroidServices, availableAppleServices } = useCoreContext();

    const onNavLinkClick = (e) => {
        setHidden(true);
        setTimeout(() => {
            setHidden(false);
        });
    };

    const availableSpaces = useMemo(() => {
        const tempAvailableSpaces = [];
        if (availableWindowsServices.length > 0) {
            tempAvailableSpaces.push({
                namespace: 'windows',
                name: 'Windows',
                icon: 'windows',
                url: '../windows',
                classNames: 'tw-bg-windows tw-text-windows',
                pages: availableWindowsServices.map(({ text, icon, to }) => ({ name: text, icon, url: to, id: to })),
            });
        }
        if (availableAndroidServices.length > 0) {
            tempAvailableSpaces.push({
                namespace: 'android',
                name: 'Android',
                icon: 'android',
                url: '../android',
                classNames: 'tw-bg-android tw-text-android',
                pages: availableAndroidServices.map(({ text, icon, to }) => ({ name: text, icon, url: to, id: to })),
            });
        }
        if (availableAppleServices.length > 0) {
            tempAvailableSpaces.push({
                namespace: 'apple',
                name: 'Apple',
                icon: 'apple',
                url: '../apple',
                classNames: 'tw-bg-appleDark tw-text-appleDark',
                pages: availableAppleServices.map(({ text, icon, to }) => ({ name: text, icon, url: to, id: to })),
            });
        }
        if (managementSpace.services.length > 0) {
            tempAvailableSpaces.push({
                namespace: managementSpace.namespace,
                name: 'Management',
                icon: managementSpace.icon,
                url: `../${managementSpace.namespace}`,
                classNames: 'tw-bg-indigo-400 tw-text-indigo-400',
                pages: managementSpace.services.map(({ text, icon, to }) => ({ name: text, icon, url: to, id: to })),
            });
        }
        return tempAvailableSpaces;
    }, [availableWindowsServices, availableAndroidServices, availableAppleServices, managementSpace]);

    const { tooltipContentClassName, mainContent } = useMemo(() => {
        const selectedSpace = availableSpaces.find((space) => space.namespace === params.namespace) || {};
        return {
            tooltipContentClassName: classNames({
                'tw-grid tw-gap-12 tw-p-4': true,
                'tw-grid-cols-2': availableSpaces.length === 2,
                'tw-grid-cols-3': availableSpaces.length === 3,
                'tw-grid-cols-4': availableSpaces.length === 4,
            }),
            mainContent: (
                <div className={classNames(selectedSpace.classNames, 'tw-bg-opacity-10 tw-py-4')}>
                    <LayoutColumn verticalAlign="center">
                        <Icon type={selectedSpace.icon} />
                        {selectedSpace.isPreRelease ? <p className="tw-text-tiny tw-font-bold">Beta</p> : null}
                    </LayoutColumn>
                </div>
            ),
        };
    }, [params, availableSpaces]);

    if (availableSpaces.length < 2) {
        return null; // No need to show the SectionSwitcher.
    }

    if (hidden) {
        return mainContent;
    }

    return (
        <Tooltip
            content={
                <div className={tooltipContentClassName}>
                    {availableSpaces.map((space) => {
                        return (
                            <div key={space.namespace}>
                                <div className="tw-sp-2 tw-text-lg tw-text-white">
                                    <NavLink
                                        to={space.url}
                                        className="tw-min-w-0 tw-pl-3 tw-pr-4 tw-text-xs tw-text-inherit"
                                        startIcon={
                                            <Icon
                                                type={space.icon}
                                                className="tw-h-10 tw-w-10"
                                            />
                                        }
                                        onClick={onNavLinkClick}
                                    >
                                        {space.name}
                                        {space.isPreRelease ? (
                                            <PreReleaseTag
                                                className="tw-ml-2"
                                                size={'tiny'}
                                            />
                                        ) : null}
                                    </NavLink>
                                </div>
                                {space.pages.map((page) => {
                                    return (
                                        <div
                                            className="tw-mt-1 tw-block"
                                            key={page.name}
                                        >
                                            <NavLink
                                                to={`../${page.url}`}
                                                className="tw-min-w-0 tw-text-inherit"
                                                onClick={onNavLinkClick}
                                            >
                                                {page.name}
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            }
            interactive
            position={TOOLTIP.POSITION.RIGHT_START}
            offset={[-2, -14]}
            fullWidth
            noPadding
        >
            {mainContent}
        </Tooltip>
    );
};

const WindowsSidebar = ({ isCollapsed }) => {
    const { permissions } = useAuthContext();
    const { availableWindowsServices } = useCoreContext();

    const [links] = useState(() => {
        return availableWindowsServices
            .map((service) => {
                return { ...service, to: service.to.replace('windows/', '') };
            })
            .filter(accessDeniedFilter)
            .map(({ accessDenied, ...rest }) => ({ ...rest }));
    });

    return (
        <Navigation
            links={links}
            minWidth="fit-content"
            isCollapsed={isCollapsed}
        />
    );
};

const AndroidSidebar = ({ isCollapsed }) => {
    const { permissions } = useAuthContext();
    const { androidEnterpriseAccount, availableAndroidServices } = useCoreContext();
    const [links] = useState(() => {
        if (permissions.canViewAndroidManagement) {
            if (androidEnterpriseAccount.id) {
                return availableAndroidServices
                    .map((service) => {
                        return { ...service, to: service.to.replace('android/', '') };
                    })
                    .filter(accessDeniedFilter)
                    .map(({ accessDenied, ...rest }) => ({ ...rest }));
            }
            return [
                {
                    to: '.',
                    text: 'Enrollment',
                    icon: 'download',
                },
            ];
        }
        return [];
    });

    return (
        <Navigation
            links={links}
            minWidth="fit-content"
            isCollapsed={isCollapsed}
        />
    );
};

const AppleSidebar = ({ isCollapsed }) => {
    const { permissions } = useAuthContext();
    const { appleEnterpriseAccount, availableAppleServices } = useCoreContext();

    const links = useMemo(() => {
        if (permissions.canViewAppleManagement) {
            let tempLinks = [];
            if (!appleEnterpriseAccount?._id && !appleEnterpriseAccount?.id) {
                tempLinks = [
                    {
                        to: '',
                        text: 'Setup',
                        icon: 'configureOutlined',
                    },
                ];
                return tempLinks.filter(accessDeniedFilter).map(({ accessDenied, ...rest }) => ({ ...rest }));
            }
            return availableAppleServices
                .map((service) => {
                    return { ...service, to: service.to.replace('apple/', '') };
                })
                .filter(accessDeniedFilter)
                .map(({ accessDenied, ...rest }) => ({ ...rest }));
        }
        return [];
    }, [appleEnterpriseAccount]);
    return (
        <Navigation
            links={links}
            minWidth="fit-content"
            isCollapsed={isCollapsed}
        />
    );
};

const ManagementSidebar = ({ isCollapsed }) => {
    const { permissions } = useAuthContext();
    const { managementSpace } = useCoreContext();
    const [links] = useState(() => {
        return managementSpace.services
            .map((service) => {
                return { ...service, to: service.to.replace('management/', '') };
            })
            .filter(accessDeniedFilter)
            .map(({ accessDenied, ...rest }) => ({ ...rest }));
    });

    return (
        <Navigation
            links={links}
            minWidth="fit-content"
            isCollapsed={isCollapsed}
        />
    );
};

const WaitForCompontentLoad = ({ children }) => (
    <Suspense
        fallback={
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        }
    >
        {children}
    </Suspense>
);

const WindowsRoutes = () => {
    const { permissions } = useAuthContext();
    const location = useLocation();

    return (
        <Routes>
            <Route
                index
                element={
                    <div className="tw-m-auto tw-max-w-screen-2xl">
                        <Padding factor={2}>
                            <WindowsSpaces />
                        </Padding>
                    </div>
                }
            ></Route>
            <Route path="configuration">
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
                <Route
                    path="list"
                    element={<WindowsConfigurationList />}
                />
                {permissions.canViewAdminOnDemand && (
                    <>
                        <Route
                            path="create/adminondemand"
                            element={<WindowsConfigurationCreateAndEditAod />}
                        />
                        <Route
                            path=":configurationId/edit/adminondemand"
                            element={<WindowsConfigurationCreateAndEditAod />}
                        />
                    </>
                )}
                {permissions.canManageDrivers && (
                    <>
                        <Route
                            path=":configurationId/edit/driverinstaller"
                            element={<WindowsConfigurationCreateAndEditDrivers />}
                        />
                        <Route
                            path="create/driverinstaller"
                            element={<WindowsConfigurationCreateAndEditDrivers />}
                        />
                    </>
                )}
                <Route
                    path=":configurationId/membership"
                    element={<WindowsConfigurationMembership />}
                />
            </Route>
            <Route path="device">
                <Route
                    index
                    element={<DevicesLandingPage />}
                />
                <Route
                    path="list"
                    element={<DevicesLandingPage tabId="list" />}
                />
                <Route
                    path="dashboard"
                    element={<DevicesLandingPage tabId="dashboard" />}
                />
                <Route
                    path="filters"
                    element={<DevicesLandingPage tabId="filters" />}
                />
                <Route
                    path="tags"
                    element={<DevicesLandingPage tabId="tags" />}
                />
                <Route path=":deviceId">
                    <Route
                        index
                        element={
                            <Navigate
                                to="dashboard"
                                replace
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <WindowsEndpointContextProvider>
                                <WindowsEndpoint />
                            </WindowsEndpointContextProvider>
                        }
                    />
                </Route>
                <Route path="filter/:filterId">
                    <Route
                        path="edit"
                        element={<DeviceFilterAction editMode />}
                    />
                    <Route
                        path="apply"
                        element={<DeviceFilterAction editMode={false} />}
                    />
                </Route>
            </Route>

            <Route path="driver">
                <Route
                    path=":tabId?"
                    element={<DriverLandingPage />}
                />
            </Route>

            <Route path="updater">
                <Route
                    path=":tabId?"
                    element={<UpdaterLandingPage />}
                />
                <Route
                    path="application/:applicationId"
                    element={<UpdaterApplicationManagement key="updater-application-management" />}
                />
            </Route>

            {(permissions.canManageSoftwarePatching || permissions.canManageDrivers) && (
                <>
                    <Route
                        path="workflow"
                        element={<Workflow />}
                    />
                    <Route path="workflow/:workflowId">
                        <Route
                            index
                            element={<ViewWorkflow />}
                        />
                        <Route
                            path="edit"
                            element={<WorkflowEditor />}
                        />
                    </Route>
                </>
            )}

            <Route path="reliability">
                <Route
                    index
                    element={
                        <Navigate
                            to={`dashboard${location.search}`}
                            replace
                        />
                    }
                />
                <Route
                    path={`dashboard/:severityId?`}
                    element={
                        <ReliabilityPermissionsCheck teaserProps={{ className: 'tw-mx-auto tw-max-w-screen-2xl tw-p-4' }}>
                            <ReliabilityContextProvider>
                                <ReliabilityDashboardPage />
                            </ReliabilityContextProvider>
                        </ReliabilityPermissionsCheck>
                    }
                />
            </Route>
            {permissions.canViewExperience && (
                <Route path="experience">
                    <Route
                        index
                        element={<WindowsExperienceDashboard />}
                    />
                </Route>
            )}
            <Route
                path="security/*" // TODO: Add permissions.canViewSecurity to Route when Security Summary is no longer free, but contract based
                element={<WindowsSecurityLandingPage />}
            />
            <Route
                path="settings/:tabId?"
                element={<Settings />}
            />

            <Route path="adminOnDemand">
                <Route
                    index
                    element={
                        <Navigate
                            to="summary"
                            replace
                        />
                    }
                />
                <Route
                    // path=":tabId(dashboard|applications|processelevation|sessionelevation)?"
                    path=":tabId"
                    element={<AdminOnDemandLandingPage />}
                />
            </Route>

            <Route path="enrollment">
                <Route
                    index
                    element={<WindowsEnrollment />}
                />
                <Route
                    path="create"
                    element={<WindowsCreateAndEditEnrollmentConfiguration />}
                />
                <Route
                    path=":enrollmentId/edit"
                    element={<WindowsCreateAndEditEnrollmentConfiguration />}
                />
            </Route>
            {(permissions.canManageSoftwarePatching || permissions.canManageCustomApps) && (
                <Route path="application">
                    <Route
                        index
                        element={
                            <Navigate
                                to="list"
                                replace
                            />
                        }
                    />
                    <Route
                        path="list"
                        element={<WindowsApplicationList />}
                    />
                    {permissions.canManageSoftwarePatching && (
                        <Route
                            path={`new/repositoryapp`}
                            element={<WindowsNewRepositoryApp />}
                        />
                    )}
                    {(permissions.canManageSoftwarePatching || permissions.canManageCustomApps) && (
                        <>
                            <Route
                                path="new/customapp"
                                element={<WindowsNewCustomApp />}
                            />
                            <Route
                                path={`:applicationId/edit/${windowsApplication.type.customApp.id}`}
                                element={<WindowsEditCustomApp />}
                            />
                        </>
                    )}
                    {(permissions.canManageSoftwarePatching || permissions.canManageCustomApps) && (
                        <>
                            <Route
                                path={`new/${windowsApplication.type.script.id}`}
                                element={<WindowsNewScript />}
                            />
                            <Route
                                path={`:applicationId/edit/${windowsApplication.type.script.id}`}
                                element={<WindowsEditScript />}
                            />
                        </>
                    )}
                    {permissions.canManageSoftwarePatching && (
                        <>
                            <Route
                                path={`:applicationId/create/${windowsApplication.type.capaPacks.id}`}
                                element={<WindowsEditRepositoryApp />}
                            />
                            <Route
                                path={`:applicationConfigurationId/edit/${windowsApplication.type.capaPacks.id}`}
                                element={<WindowsEditRepositoryApp />}
                            />
                        </>
                    )}
                    <Route
                        path={`:applicationId/membership`}
                        element={<WindowsApplicationMembershipManagement isApplication={true} />}
                    />
                    <Route
                        path={`uploading/:id`}
                        element={<UploadingPage />}
                    />
                </Route>
            )}
            <Route
                path="dashboard/:dashboardId?"
                element={<WindowsDashboards />}
            />
            <Route
                path="*"
                element={<PageNotFound />}
            />
        </Routes>
    );
};

const AndroidRoutes = () => {
    const { permissions } = useAuthContext();
    const { androidEnterpriseAccount } = useCoreContext();

    if (!permissions.canViewAndroidManagement) {
        return <RedirectToLandingPage />;
    }
    if (!androidEnterpriseAccount.hasBackendConnection) {
        return (
            <EmptyState
                iconType="exclamation"
                title="Something went wrong"
                description="Please refresh the page. If the issue persists, contact our support team."
            />
        );
    }
    if (androidEnterpriseAccount.id === null) {
        return <AndroidEnrollment />;
    }

    return (
        <Routes>
            <Route path="configuration">
                <Route
                    path="list"
                    element={<AndroidConfigurationList />}
                />
                <Route
                    path="create"
                    element={<AndroidConfigurationCreateAndEdit />}
                />
                <Route
                    path=":configurationId/edit"
                    element={<AndroidConfigurationCreateAndEdit />}
                />
                <Route
                    path=":configurationId/membership"
                    element={<AndroidApplicationAndConfigurationMembershipManagement isApplication={false} />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route path="application">
                <Route
                    path="list"
                    element={<AndroidApplicationList />}
                />
                <Route
                    path="create/webapp"
                    element={<AndroidApplicationCreateAndEdit />}
                />
                <Route
                    path="create/:packageName"
                    element={<AndroidApplicationCreateAndEdit />}
                />
                <Route
                    path=":applicationId/edit"
                    element={<AndroidApplicationCreateAndEdit />}
                />
                <Route
                    path={`:configurationId/membership`}
                    element={<AndroidApplicationAndConfigurationMembershipManagement isApplication={true} />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path="home"
                element={
                    <div className="tw-m-auto tw-max-w-screen-2xl">
                        <Padding factor={2}>
                            <AndroidSpaces />
                        </Padding>
                    </div>
                }
            />
            <Route path="device">
                <Route
                    path="list"
                    element={<AndroidDeviceList />}
                />
                <Route
                    path=":deviceId/*"
                    element={<AndroidEndpoint />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path={'enrollment'}
                element={<AndroidEnrollment />}
            />
            <Route
                path={`enrollment/create`}
                element={<AndroidCreateEnrollmentConfiguration />}
            />
            <Route
                path={`enrollment/:enrollmentId/edit`}
                element={<AndroidCreateEnrollmentConfiguration />}
            />
            <Route
                path="/"
                element={<Navigate to="home" />}
            />
            <Route
                path="*"
                element={<PageNotFound />}
            />
        </Routes>
    );
};

const AppleRoutes = () => {
    const { permissions } = useAuthContext();
    const { appleEnterpriseAccount } = useCoreContext();
    // const location = useLocation();

    if (!permissions.canViewAppleManagement) {
        return <RedirectToLandingPage />;
    }
    if (!appleEnterpriseAccount?.hasCertificate) {
        return <AppleFirstTimeSetup />;
    }
    // if (!appleEnterpriseAccount?.hasBackendConnection) {
    //     return (
    //         <EmptyState
    //             iconType="exclamation"
    //             title="Something went wrong"
    //             description="Please refresh the page. If the issue persists, contact our support team."
    //         />
    //     );
    // }

    return (
        <Routes>
            <Route path="configuration">
                <Route
                    path="list"
                    element={<AppleConfigurationList />}
                />
                <Route
                    path="create"
                    element={<AppleConfigurationCreateAndEdit />}
                />
                <Route
                    path=":configurationId/edit"
                    element={<AppleConfigurationCreateAndEdit />}
                />
                <Route
                    path=":configurationId/membership"
                    element={<AppleApplicationAndConfigurationMembershipManagement isApplication={false} />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route path="application">
                <Route
                    path="list"
                    element={<AppleApplicationList />}
                />
                <Route
                    path="create/webclip"
                    element={<AppleApplicationCreateAndEdit />}
                />
                <Route
                    path="create/:bundleId/:trackId?"
                    element={<AppleApplicationCreateAndEdit />}
                />
                <Route
                    path="create"
                    element={<AppleApplicationCreateAndEdit />}
                />
                <Route
                    path=":applicationId/edit"
                    element={<AppleApplicationCreateAndEdit />}
                />
                <Route
                    path={`:applicationId/membership`}
                    element={<AppleApplicationAndConfigurationMembershipManagement isApplication={true} />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path="home"
                element={
                    <div className="tw-m-auto tw-max-w-screen-2xl">
                        <Padding factor={2}>
                            <AppleSpacesDarkLogo />
                        </Padding>
                    </div>
                }
            />
            <Route path="vpp">
                <Route
                    path="list"
                    element={<AppleVppLicenseList />}
                />
                <Route
                    path=":vppAssignments/assignments"
                    element={<AppleVPPAssignmentsList />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path={`certificates`}
                element={<AppleCertificates />}
            />
            <Route path="endpoint">
                <Route
                    path="list/:modelType"
                    element={<AppleEndpointList />}
                />
                <Route
                    path=":deviceId/*"
                    element={<AppleEndpoint />}
                />
                <Route
                    index
                    element={
                        <Navigate
                            to="list/mobile"
                            replace
                        />
                    }
                />
            </Route>
            <Route
                path={'enrollment'}
                element={<AppleEnrollment />}
            />
            <Route
                path={`enrollment/create`}
                element={<AppleCreateEnrollmentConfiguration />}
            />
            <Route
                path={`enrollment/:enrollmentId/edit`}
                element={<AppleCreateEnrollmentConfiguration />}
            />
            <Route
                path="/"
                element={<Navigate to="home" />}
            />
            <Route
                path="*"
                element={<PageNotFound />}
            />
        </Routes>
    );
};

const ManagementRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <Navigate
                        to="group"
                        replace
                    />
                }
            />
            <Route path="user">
                <Route
                    index
                    element={
                        <Navigate
                            to="list"
                            replace
                        />
                    }
                />
                <Route
                    path="list"
                    element={<UserManagementUserList />}
                />
                <Route
                    path=":userId"
                    element={<UserManagementUserID />}
                />
            </Route>
            <Route path="integration">
                <Route
                    index
                    element={<IntegrationManagementLandingPage />}
                />
                <Route
                    path=":integrationId/edit"
                    element={<EditIntegration />}
                />
            </Route>
            <Route path="group">
                <Route
                    index
                    element={<Navigate to="list" />}
                />
                <Route
                    path="list"
                    element={<GroupListManagement />}
                />
                <Route
                    path=":groupId/*"
                    element={<GroupManagement />}
                />
            </Route>
            <Route
                path="reporting/:tabId?"
                element={<ReportingManagementLandingPage />}
            />
            <Route
                path="*"
                element={<PageNotFound />}
            />
        </Routes>
    );
};

const HelpRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <Navigate
                        to="tutorials"
                        replace
                    />
                }
            />
            <Route
                path="tutorials"
                element={<TutorialsPage />}
            />
            <Route
                path="*"
                element={<PageNotFound />}
            />
        </Routes>
    );
};

const CapaOneAdministrationRoutes = () => {
    const { permissions } = useAuthContext();
    if (!permissions.canViewExperimentalFeatures) {
        return <RedirectToLandingPage />;
    }
    return <PrivateLandingPage />;
};

const ResponsiveBreakpoints = () => (
    <div
        style={{ zIndex: 1 }}
        className="tw-fixed tw-bottom-0 tw-left-0 tw-m-2 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-700 tw-p-1 tw-font-mono tw-text-xs tw-text-white sm:tw-bg-pink-500 md:tw-bg-orange-500 lg:tw-bg-green-500 xl:tw-bg-blue-500 2xl:tw-bg-teal-500 3xl:tw-bg-red-500 4xl:tw-bg-pink-500"
    >
        <div className="tw-block sm:tw-hidden">All</div>
        <div className="tw-hidden sm:tw-block md:tw-hidden lg:tw-hidden xl:tw-hidden">
            sm <span className="tw-hidden">tablet</span>
        </div>
        <div className="tw-hidden md:tw-block lg:tw-hidden xl:tw-hidden">md</div>
        <div className="tw-hidden lg:tw-block xl:tw-hidden">
            lg <span className="tw-hidden">laptop</span>
        </div>
        <div className="tw-hidden xl:tw-block 2xl:tw-hidden">
            xl <span className="tw-hidden">desktop</span>
        </div>
        <div className="tw-hidden 2xl:tw-block 3xl:tw-hidden">2xl</div>
        <div className="tw-hidden 3xl:tw-block 4xl:tw-hidden">3xl</div>
        <div className="tw-hidden 4xl:tw-block">
            4xl <span className="tw-hidden">iMac fullscreen</span>
        </div>
    </div>
);

const AccessDeniedPage = () => {
    const { message } = useParams();
    const { appendBaseURL } = useNavigate();
    return (
        <LayoutCenter>
            <div>
                <Heading>Access Denied</Heading>
                <Heading
                    subheading
                    bold
                >
                    {message}
                </Heading>
                <div className="tw-mt-6">
                    <NavLink
                        to={appendBaseURL('')}
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                    >
                        Back
                    </NavLink>
                </div>
            </div>
        </LayoutCenter>
    );
};

const SystemInfoButton = () => {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [backendBuildInfo, setBackendBuildInfo] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const close = () => setOpen(false);

    const showSystemInfo = () => {
        setOpen(true);
        api.getSystemInfo().then(setBackendBuildInfo);
    };

    return (
        <>
            <Tooltip content="System information">
                <IconButton
                    onClick={showSystemInfo}
                    className="tw-mr-3 tw-hidden sm:tw-flex"
                >
                    <Icon type="infoOutlined" />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={close}
            >
                <DialogTitle>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div>
                            <PageTitle category={PRODUCT_NAME}>System information</PageTitle>
                        </div>
                        <div>
                            <WithExperimentalFeatures tooltipPosition={TOOLTIP.POSITION.LEFT}>
                                <div>
                                    <Tooltip content="Copy system information">
                                        <div>
                                            <CopyTextButton
                                                noMargin
                                                variant={BUTTON.OUTLINED}
                                                text={`${JSON.stringify(frontendBuildInfo)} ${JSON.stringify(backendBuildInfo)}`}
                                            >
                                                Copy
                                            </CopyTextButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            </WithExperimentalFeatures>
                        </div>
                    </div>
                </DialogTitle>
                <Tabs
                    value={selectedTab}
                    onChange={(e, v) => setSelectedTab(v)}
                    variant="fullWidth"
                    onRails
                >
                    <Tab label="Frontend" />
                    <Tab label="Backend" />
                </Tabs>
                <DialogContent>
                    {Object.keys(frontendBuildInfo).map((key) => (
                        <div
                            key={key}
                            className="tw-mt-2"
                        >
                            <div className="tw-mb-0 tw-text-xs tw-capitalize">{key}</div>
                            <h2 className="tw-mt-0.5">
                                {selectedTab === 0 && (key === 'date' ? formatTimestamp(frontendBuildInfo[key]) : frontendBuildInfo[key])}
                                {selectedTab === 1 && (key === 'date' ? formatTimestamp(backendBuildInfo[key]) : backendBuildInfo[key])}
                            </h2>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        href="https://portal.capaone.com/assets/eula/Eula_Capasystems.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        EULA
                    </Button>
                    <Button
                        href="https://stats.uptimerobot.com/4Y5AGHzg7z"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Status
                    </Button>
                    <Button onClick={close}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { ApplicationLayoutAndRoutes };
