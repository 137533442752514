import {
    Tooltip,
} from '@capasystems/ui';
import {
    EPlaceholderValues
} from '@thirdparty/constants';
import React from 'react';

export type TPlaceholderValues = {
    type: EPlaceholderValues;
    value?: string;
    customTooltipText?: string;
    tooltip?: boolean;
};

export const placeholderValue = (content: TPlaceholderValues) => {
    let tooltipText = '';
    switch (content.type) {
        case EPlaceholderValues.NOT_AVAILABLE:
            tooltipText = notAvailableText(content);
            break;
        case EPlaceholderValues.NONE:
            tooltipText = noneText(content);
            break;
        case EPlaceholderValues.NOT_APPLICABLE:
            tooltipText = notApplicableText(content);
            break;
        case EPlaceholderValues.NOT_INSTALLED:
            tooltipText = notInstalledText(content);
            break;
        case EPlaceholderValues.NEVER:
            tooltipText = neverText(content);
            break;
        default:
            break;
    }

    if(content.tooltip === false) {
        return <i className="tw-text-neutral-300">{content.type}</i>;
    } else {
        return (
            <Tooltip content={content.customTooltipText || tooltipText}><i className="tw-text-neutral-300">
                {content.type}</i>
            </Tooltip>
        );
    }
};

const notAvailableText = (content: TPlaceholderValues) => {
    return `The ${content.value || 'value'} is unavailable.`;
}; 

const noneText = (content: TPlaceholderValues) => {
    return `No ${content.value || 'value'} is present.`;
};

const notApplicableText = (content: TPlaceholderValues) => {
    return `${content.value || 'This field'} does not apply to the current context.`;
};

const notInstalledText = (content: TPlaceholderValues) => {
    return `This ${content?.value || 'software'} has not been installed.`;
};

const neverText = (content: TPlaceholderValues) => {
    return `The ${content.value || 'value'} has never been synced.`;
};