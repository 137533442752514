import { TOOLTIP } from '@capasystems/constants';
import { Column, Ellipsis, EmptyState, LayoutColumn, LayoutRow, LoadingLinear, Tab, Tabs, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { formatTimestamp, isDefined, isUndefined, Url } from '@capasystems/utils';
import { TAndroidDevice } from '@db/party';
import { DeviceStateEnums, EPlaceholderValues, UpdateStatusEnums } from '@thirdparty/constants';
import { formatBytes, getLanguageName } from '@thirdparty/utils';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { placeholderValue, TailwindBadge, WidgetPaper } from '../../../../../index';

export type HardwareInfoTabProps = {
    androidData: TAndroidDevice['androidData'];
};

export const HardwareInfoTab: React.FC<HardwareInfoTabProps> = ({
    androidData: {
        hardwareInfo,
        softwareInfo,
        networkInfo,
        memoryInfo,
        memoryEvents = [],
        ownership,
        state,
        powerManagementEvents = [],
        lastStatusReportTime,
        managementMode,
    },
}) => {
    const [activeTab, setActiveTab] = useState(Url.getString('hardwareTab', 'general'));

    const managementBadge = useMemo(() => {
        if (managementMode === 'DEVICE_OWNER') {
            return (
                <Tooltip
                    content="Enrolled in Fully Managed Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="indigo"
                    >
                        Fully Managed
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (managementMode === 'PROFILE_OWNER') {
            return (
                <Tooltip
                    content="Enrolled in Work Profile Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="purple"
                    >
                        Work Profile
                    </TailwindBadge>
                </Tooltip>
            );
        }
    }, []);

    const ownershipBadge = useMemo(() => {
        if (ownership === 'COMPANY_OWNED') {
            return (
                <Tooltip
                    content="This device is owned by your organization."
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="sky"
                    >
                        Company Owned
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (ownership === 'PERSONALLY_OWNED') {
            return (
                <Tooltip
                    content="This device is personally owned by the user."
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="emerald"
                    >
                        Personally Owned
                    </TailwindBadge>
                </Tooltip>
            );
        }
    }, []);

    const tableItems = useMemo(() => {
        if (activeTab === 'general') {
            if (isUndefined(hardwareInfo)) {
                return [];
            }
            return [
                {
                    name: 'Model',
                    value: hardwareInfo.model,
                },
                {
                    name: 'Brand',
                    value: hardwareInfo.brand,
                },
                {
                    name: 'Manufacturer',
                    value: hardwareInfo.manufacturer,
                },
                {
                    name: 'Serial number',
                    value: hardwareInfo.serialNumber,
                },
                {
                    name: 'MEID',
                    value: networkInfo?.meid,
                },
                {
                    name: 'IMEI',
                    value: networkInfo?.imei,
                },
                {
                    name: 'Device State',
                    value: DeviceStateEnums[state as keyof typeof DeviceStateEnums],
                },
                {
                    name: 'Management mode',
                    value: managementBadge,
                },
                {
                    name: 'Ownership',
                    value: ownershipBadge,
                },
                {
                    name: 'RAM',
                    displayType: 'bytes',
                    used: memoryEvents.findLast((memoryEvent: any) => memoryEvent.eventType === 'RAM_MEASURED')?.byteCount,
                    available: memoryInfo.totalRam,
                },
                {
                    name: 'Battery level',
                    displayType: 'percentage',
                    value: powerManagementEvents?.findLast((event: any) => event.eventType === 'BATTERY_LEVEL_COLLECTED')?.batteryLevel,
                },
                {
                    name: 'Name of the hardware',
                    value: hardwareInfo.hardware,
                },
                {
                    name: 'Baseband version',
                    value: hardwareInfo.deviceBasebandVersion,
                },
            ];
        }
        if (activeTab === 'system') {
            if (isUndefined(softwareInfo)) {
                return [];
            }
            return [
                {
                    name: 'OS version',
                    value: softwareInfo.androidVersion,
                },
                {
                    name: 'Android build number',
                    value: softwareInfo.androidBuildNumber,
                },
                {
                    name: 'Update status',
                    value: UpdateStatusEnums[softwareInfo.systemUpdateInfo.updateStatus as keyof typeof UpdateStatusEnums],
                },
                {
                    name: 'Device policy version',
                    value: softwareInfo.androidDevicePolicyVersionName,
                },

                {
                    name: 'Device kernel version',
                    value: softwareInfo.deviceKernelVersion,
                },
                {
                    name: 'Bootloader version',
                    value:
                        softwareInfo.bootloaderVersion === 'unknown' ? (
                            placeholderValue({ type: EPlaceholderValues.NONE, customTooltipText: 'No bootloader version exists on this endpoint.' })
                        ) : (
                            softwareInfo.bootloaderVersion
                        ),
                },
                {
                    name: 'Android build time',
                    value: formatTimestamp(softwareInfo.androidBuildTime),
                },
                {
                    name: 'Security patch level',
                    value: softwareInfo.securityPatchLevel,
                },
                {
                    name: 'Primary language',
                    value: getLanguageName(softwareInfo.primaryLanguageCode),
                },
                {
                    name: 'Device build signature',
                    value: softwareInfo.deviceBuildSignature,
                },
            ];
        }
        if (activeTab === 'cellular') {
            if (isUndefined(networkInfo)) {
                return [];
            }
            const cellularRows: any[] = [];
            const checkTelephonyInfo = (value: any, name: any, index: number) => {
                if (isDefined(value)) {
                    cellularRows[index].push({
                        name: name,
                        value: value,
                    });
                }
            };

            networkInfo?.telephonyInfos?.forEach((telephonyInfo: any, index: number) => {
                cellularRows.push([]);
                checkTelephonyInfo(telephonyInfo?.phoneNumber, 'Phone Number', index);
                checkTelephonyInfo(telephonyInfo?.carrierName, 'carrier', index);
            });

            return cellularRows;
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const isCellularTab = activeTab === 'cellular';

    return (
        <div className="tw-grid tw-h-full tw-grid-cols-auto-1fr tw-gap-4 tw-pb-4 tw-pt-0">
            <div className="tw-w-48">
                <Tabs
                    value={activeTab}
                    pills
                    onChange={(_, tab) => {
                        setActiveTab(tab);
                        Url.set('hardwareTab', tab);
                    }}
                    orientation="vertical"
                >
                    <Tab
                        label="General"
                        value="general"
                        className="tw-items-start tw-text-sm tw-font-semibold"
                        disableRipple
                    />
                    <Tab
                        label="System"
                        value="system"
                        className="tw-items-start tw-text-sm tw-font-semibold"
                        disableRipple
                    />
                    {networkInfo?.telephonyInfos !== undefined && (
                        <Tab
                            label="Celllular"
                            value="cellular"
                            className="tw-items-start tw-text-sm tw-font-semibold"
                            disableRipple
                        />
                    )}
                </Tabs>
            </div>
            {isCellularTab ? (
                <div className="tw-grid tw-w-full tw-grid-cols-1 tw-gap-4 tw-overflow-auto xl:tw-grid-cols-2">
                    {tableItems.map((simCard, index) => {
                        return (
                            <HardwareInvTable
                                items={simCard}
                                title={'Sim slot ' + (index + 1)}
                                activeTab={activeTab}
                                networkInfo={networkInfo}
                                hardwareInfo={hardwareInfo}
                                isCellularTab={isCellularTab}
                            />
                        );
                    })}
                </div>
            ) : (
                <HardwareInvTable
                    items={tableItems}
                    activeTab={activeTab}
                    networkInfo={networkInfo}
                    hardwareInfo={hardwareInfo}
                    isCellularTab={isCellularTab}
                />
            )}
        </div>
    );
};

export type HardwareInvTableProps = {
    items: any[];
    title?: string;
    activeTab: string | null;
    hardwareInfo: any;
    networkInfo: any;
    isCellularTab: boolean;
};

const HardwareInvTable: React.FC<HardwareInvTableProps> = ({ items, title, activeTab, hardwareInfo, networkInfo, isCellularTab }) => {
    const noHardwareData = isUndefined(hardwareInfo) || isUndefined(networkInfo);
    return (
        <WidgetPaper
            title={title}
            headerless={isUndefined(title)}
        >
            {/* @ts-ignore - WidgetPaper does not have support for TS yet */}
            <VirtualizedTable
                disableHeader
                items={items}
                noRowsRenderer={() => (
                    <EmptyState
                        iconType="lightbulbOutlined"
                        title={isUndefined(noHardwareData) ? 'Hardware info reporting is disabled' : 'No info available'}
                        description={`Endpoint did not report ${activeTab} information`}
                    />
                )}
            >
                <Column
                    dataKey="name"
                    // @ts-ignore - WidgetPaper does not have support for TS yet
                    label="Name"
                    minWidth={isCellularTab ? 120 : 240}
                    maxWidth={240}
                    type="string"
                />
                <Column
                    dataKey="value"
                    label=""
                    minWidth={240}
                    // @ts-ignore - WidgetPaper does not have support for TS yet
                    type="string"
                    cellRenderer={valueCellRenderer}
                />
            </VirtualizedTable>
        </WidgetPaper>
    );
};

const valueCellRenderer = ({ rowData }: { rowData: any }) => {
    if (rowData.displayType === 'bytes') {
        if (isDefined(rowData.used)) {
            const percentage = (rowData.used / rowData.available) * 100;
            return (
                <LayoutColumn className="tw-mt-1 tw-w-64">
                    <LoadingLinear
                        variant="determinate"
                        value={percentage}
                        classes={{
                            root: 'tw-h-2 tw-rounded-full',
                        }}
                    />
                    <LayoutRow
                        align="space-between"
                        className="tw-mt-1 tw-text-tiny"
                    >
                        <b>{formatBytes({ value: rowData.used, decimalPoints: 1, asText: true })}</b>
                        <b>{formatBytes({ value: rowData.available, asText: true })}</b>
                    </LayoutRow>
                </LayoutColumn>
            );
        }
        return <b>{formatBytes({ value: rowData.available, asText: true })}</b>;
    }
    if (rowData.displayType === 'percentage') {
        if (isDefined(rowData.value)) {
            return (
                <LayoutColumn className="tw-mt-1 tw-w-64">
                    <LoadingLinear
                        variant="determinate"
                        value={rowData.value}
                        classes={{
                            root: classNames({
                                'tw-h-2 tw-rounded-full': true,
                                'tw-bg-red-100': rowData.value < 20,
                                'tw-bg-emerald-100': rowData.value >= 20,
                            }),
                            bar: classNames({
                                'tw-bg-red-600': rowData.value < 20,
                                'tw-bg-emerald-400': rowData.value >= 20,
                            }),
                        }}
                    />
                    <LayoutRow
                        align="space-between"
                        className="tw-mt-1 tw-text-tiny"
                    >
                        <b>{rowData.value} %</b>
                    </LayoutRow>
                </LayoutColumn>
            );
        }
        return <b className="tw-text-neutral-400">{rowData.name} is unknown</b>;
    }

    if (!isDefined(rowData.value)) {
        return placeholderValue({ type: EPlaceholderValues.NONE, customTooltipText: `No ${rowData.name} exists on this endpoint.` });
    }

    return (
        <Ellipsis>
            <b>{rowData.value}</b>
        </Ellipsis>
    );
};
