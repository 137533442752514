import { Page, Paper, Tab, Tabs, useParams } from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { QUERY_BUILDER_APPLE_MAC_DEVICES_LIST, QUERY_BUILDER_APPLE_MOBILE_DEVICES_LIST } from '@thirdparty/constants';
import { QueryBuilder, useAuthContext, useNavigate } from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration, leafsToMongo } from '@thirdparty/utils';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { AppleEndpointListDEP } from '../components/endpointList/AppleEndpointListDEP';
import { AppleEndpointListMac } from '../components/endpointList/AppleEndpointListMac';
import { AppleEndpointListMobile } from '../components/endpointList/AppleEndpointListMobile';
const queryBuilderConfigurationDep = getDefaultQueryBuilderConfiguration({ placeholder: 'Serial Number' });

const MODEL_TYPE = {
    MOBILE: { name: 'Mobile Devices', id: 'mobile' },
    MAC: { name: 'Mac', id: 'mac' },
    DEP: { name: 'DEP', id: 'dep' },
};

const AppleEndpointList = () => {
    const { modelType } = useParams();
    const navigate = useNavigate();
    const { permissions } = useAuthContext();
    const [filter, setFilter] = useState('{}');
    const [searchTerm, setSearchTerm] = useState(Url.getString('search') || '');
    const queryBuilderRef = useRef({});
    const commandsContainerRef = useRef(null);

    const onHandleSubmit = (activeLeafs) => {
        modelType === MODEL_TYPE.DEP.id
            ? setSearchTerm(activeLeafs.length > 0 ? activeLeafs[0].value : undefined)
            : setFilter(JSON.stringify(leafsToMongo(activeLeafs)));
    };

    const [allowedTypes] = useMemo(() => {
        const types = [];
        if (permissions.canViewAppleManagement) {
            types.push(MODEL_TYPE.MOBILE);
            types.push(MODEL_TYPE.MAC);
            types.push(MODEL_TYPE.DEP);
        }
        return [types];
    }, []);

    const onChangeTab = (e, tabValue) => {
        queryBuilderRef.current.clearFiltersAndApplyChanges();
        navigate.to(`apple/endpoint/list/${tabValue}`);
    };

    return (
        <Page>
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-p-4">
                <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-4">
                    <Tabs
                        value={modelType}
                        onChange={onChangeTab}
                        onRails
                    >
                        {allowedTypes.map((endpointType) => (
                            <Tab
                                key={endpointType.id}
                                value={endpointType.id}
                                disableRipple
                                label={
                                    <div className="tw-w-full tw-items-center">
                                        <div>{endpointType.name}</div>
                                    </div>
                                }
                            />
                        ))}
                    </Tabs>
                    <div
                        className={classNames('tw-flex tw-items-center', {
                            'tw-gap-x-4': modelType !== MODEL_TYPE.DEP.id,
                        })}
                    >
                        <QueryBuilder
                            defaultConfiguration={
                                modelType === MODEL_TYPE.MAC.id
                                    ? QUERY_BUILDER_APPLE_MAC_DEVICES_LIST
                                    : modelType === MODEL_TYPE.MOBILE.id
                                      ? QUERY_BUILDER_APPLE_MOBILE_DEVICES_LIST
                                      : queryBuilderConfigurationDep
                            }
                            onInit={onHandleSubmit}
                            onSubmit={onHandleSubmit}
                            className="tw-mx-autotw-max-w-screen-md tw-w-72" //Should be wide enough to support Name, IMEI, Serial and user
                            ref={queryBuilderRef}
                            key={modelType}
                        />
                        <div ref={commandsContainerRef} />
                    </div>
                </div>

                <div className="tw-mt-4">
                    <Paper className="tw-h-full tw-shadow-sm">
                        {modelType === MODEL_TYPE.MOBILE.id && (
                            <AppleEndpointListMobile
                                filter={filter}
                                commandsContainerRef={commandsContainerRef}
                            />
                        )}
                        {modelType === MODEL_TYPE.MAC.id && (
                            <AppleEndpointListMac
                                filter={filter}
                                commandsContainerRef={commandsContainerRef}
                            />
                        )}
                        {modelType === MODEL_TYPE.DEP.id && <AppleEndpointListDEP searchTerm={searchTerm} />}
                    </Paper>
                </div>
            </div>
        </Page>
    );
};
export { AppleEndpointList };
