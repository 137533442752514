import { BUTTON, SORT_DIRECTION, TOOLTIP, coreMessage } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Column,
    Divider,
    EmptyState,
    Icon,
    Link,
    Loading,
    Tooltip,
    VirtualizedTable,
    useParams,
    virtualizedTablePropTypes,
} from '@capasystems/ui';
import { Url, formatTimestamp, inRange, isUndefined, noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import {
    EPlaceholderValues,
    LEAF_ID,
    QUERY_BUILDER_SECURITY,
    windowsSecurityPortalContainerId,
    windowsSecuritySeverityRatings,
    windowsSecurityVulnerabilityManagementFocus,
} from '@thirdparty/constants';
import {
    CapaOneLink,
    CollectedOnTimestamp,
    DeviceTypeIcon,
    InfoIcon,
    PageTitle,
    PremiumFeature,
    QueryBuilder,
    TailwindBadge,
    TwoLineCellRenderer,
    WidgetPaper,
    WithExperimentalFeatures,
    placeholderValue,
    useApi,
    useAuthContext,
} from '@thirdparty/ui';
import { leafsToMongo } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React, { Fragment, useEffect, useRef, useState } from 'react';

pluralize.addUncountableRule('vulnerable software');

export const WindowsSecurityVulnerabilityManagementFocus = ({ focusArea, endpointId = null, singleEndpointView = false }) => {
    const { permissions, selectedOrganization } = useAuthContext();
    const api = useApi();
    const [dataStore, setDataStore] = useState([]);
    const [statistics, setStatistics] = useState({
        CRITICAL: <Loading size={16} />,
        HIGH: <Loading size={16} />,
        MEDIUM: <Loading size={16} />,
        LOW: <Loading size={16} />,
    });
    const [stats, setStats] = useState({
        CRITICAL: 0,
        HIGH: 0,
        MEDIUM: 0,
        LOW: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [urlIsDirty, setUrlIsDirty] = useState(false);

    const [pagingState, setPagingState] = useState({
        pageSize: 50,
        pageNumber: 1,
        sortBy: Url.getString('sortBy', focusArea.defaultSortBy),
        sortDirection: Url.getString('sortDirection', SORT_DIRECTION.DESC),
        filter: null,
    });
    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
    });
    const [matchDate, setMatchDate] = useState(null); // singleEndpointView only

    const onScrollToBottom = () => {
        setIsLoading(true);
        setPagingState((c) => ({
            ...c,
            pageNumber: c.pageNumber + 1,
        }));
    };

    const noRowsRendererDetection = () => {
        if (permissions.canViewSecurity) {
            if (errorMessage) {
                return () => (
                    <EmptyState
                        iconType="exclamation"
                        title={coreMessage.anErrorOccurred}
                        description={errorMessage}
                        color="error"
                    />
                );
            }
            if (Object.keys(pagingState.filter || {}).length > 0) {
                return () => (
                    <EmptyState
                        isSearching
                        entity={focusArea.entity}
                    />
                );
            }
            return undefined; // Use default.
        }
        return () => (
            <PremiumFeature
                description="CapaOne Security automatically detects security vulnerabilities and exposures (CVE) in applications. The findings are presented on a simple dashboard that empowers you to easily identify and update vulnerable applications."
                icon="capaoneSecurity"
                defaultSubject="CapaOne Security"
                productPage="https://www.capasystems.com/capaone-security"
            />
        );
    };

    const quickChangeCVSSScore = (windowsSecuritySeverityRating) => {
        Url.set('cvssScore.max', windowsSecuritySeverityRating.range.max);
        Url.set('cvssScore.min', windowsSecuritySeverityRating.range.min);
        setUrlIsDirty(true);
    };

    const onSubmit = (filter) => {
        api.cancel();
        setIsLoading(true);
        setDataStore([]);
        setPagingState((c) => ({
            ...c,
            pageNumber: 1,
            filter: JSON.stringify(filter),
        }));
    };

    useEffect(() => {
        if (permissions.canViewSecurity) {
            setUrlIsDirty(false);
            if (pagingState.filter !== null) {
                if (pagingState.pageNumber === 1) {
                    api.getSecurityVulnerabilityStatistics(focusArea.apiIdentifier, endpointId, pagingState.filter)
                        .then((response) => {
                            const statsData = response.reduce(
                                (obj, item) => {
                                    obj[item._id || item.severity] = item.count;
                                    return obj;
                                },
                                {
                                    CRITICAL: 0,
                                    HIGH: 0,
                                    MEDIUM: 0,
                                    LOW: 0,
                                },
                            );

                            setStats((prevStats) => {
                                if (prevStats.CRITICAL === 0 && prevStats.HIGH === 0 && prevStats.MEDIUM === 0 && prevStats.LOW === 0) {
                                    return {
                                        CRITICAL: statsData.CRITICAL,
                                        HIGH: statsData.HIGH,
                                        MEDIUM: statsData.MEDIUM,
                                        LOW: statsData.LOW,
                                    };
                                }
                                return prevStats;
                            });
                            setStatistics({
                                CRITICAL: statsData.CRITICAL,
                                HIGH: statsData.HIGH,
                                MEDIUM: statsData.MEDIUM,
                                LOW: statsData.LOW,
                            });
                        })
                        .catch((errorResponse) => {
                            setStatistics({
                                CRITICAL: EPlaceholderValues.NOT_AVAILABLE,
                                HIGH: EPlaceholderValues.NOT_AVAILABLE,
                                MEDIUM: EPlaceholderValues.NOT_AVAILABLE,
                                LOW: EPlaceholderValues.NOT_AVAILABLE,
                            });
                        });
                }

                if (singleEndpointView) {
                    api.getSecurityVulnerabilityTableDataForSingleEndpoint(focusArea.apiIdentifier, endpointId, {
                        ...pagingState,
                        orderBy: `${pagingState.sortBy},${pagingState.sortDirection}`,
                    })
                        .then((response) => {
                            pagingRef.current.totalRowCount = response.data.totalElements;

                            if (response.data.first) {
                                setDataStore(response.data.content);
                            } else {
                                setDataStore((c) => [...c, ...response.data.content]);
                            }
                            setMatchDate(response.matchDate);
                        })
                        .catch((errorResponse) => {
                            console.log(errorResponse);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    api.getSecurityVulnerabilityTableData(focusArea.apiIdentifier, {
                        ...pagingState,
                        orderBy: `${pagingState.sortBy},${pagingState.sortDirection}`,
                    })
                        .then((response) => {
                            pagingRef.current.totalRowCount = response.totalElements;
                            if (response.first) {
                                setDataStore(response.content);
                            } else {
                                setDataStore((c) => [...c, ...response.content]);
                            }
                        })
                        .catch((errorResponse) => {
                            console.log(errorResponse);
                            setErrorMessage(errorResponse.status?.message || coreMessage.anUnknownErrorOccurred);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            }
        } else {
            setIsLoading(false);
            setStatistics({
                CRITICAL: placeholderValue({ type: EPlaceholderValues.NONE, tooltip: false }),
                HIGH: placeholderValue({ type: EPlaceholderValues.NONE, tooltip: false }),
                MEDIUM: placeholderValue({ type: EPlaceholderValues.NONE, tooltip: false }),
                LOW: placeholderValue({ type: EPlaceholderValues.NONE, tooltip: false }),
            });
        }
    }, [api, pagingState, permissions]);

    return (
        <>
            <Portal container={() => document.getElementById(windowsSecurityPortalContainerId)}>
                <div className="tw-flex tw-gap-4">
                    {singleEndpointView && (
                        <CollectedOnTimestamp
                            timestamp={matchDate}
                            className="tw-py-1"
                        />
                    )}
                    <SecurityQueryBuilder
                        focusArea={focusArea}
                        urlIsDirty={urlIsDirty}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                    />
                </div>
            </Portal>
            <WidgetPaper headerless>
                <div className="tw-grid tw-h-full tw-grid-rows-auto-auto-1fr">
                    <div>{singleEndpointView && <Divider light />}</div>
                    <div className="tw-my-0 tw-grid tw-grid-cols-4 ">
                        {windowsSecuritySeverityRatings.map((windowsSecuritySeverityRating, index) => (
                            <Tooltip
                                fullWidth
                                content={
                                    <div className="tw-grid tw-grid-cols-auto-1fr tw-gap-x-6 tw-font-medium tw-leading-relaxed">
                                        <div>
                                            <div className="tw-text-tiny tw-text-gray-300">Severity</div>
                                            <b className="tw-mt-0.5">{windowsSecuritySeverityRating.name}</b>
                                            <div className="tw-mt-2 tw-text-tiny tw-text-gray-300">CVSS Score</div>
                                            <b className="tw-mt-0.5">
                                                {windowsSecuritySeverityRating.range.min} - {windowsSecuritySeverityRating.range.max}
                                            </b>
                                        </div>
                                        <div className="tw-w-56 tw-whitespace-pre-line">{windowsSecuritySeverityRating.description}</div>
                                    </div>
                                }
                                key={windowsSecuritySeverityRating.name}
                            >
                                <Button
                                    onClick={() => quickChangeCVSSScore(windowsSecuritySeverityRating)}
                                    className="border tw-m-0 tw-flex tw-justify-start tw-divide-x tw-rounded-none tw-p-0 first:tw-divide-x-0"
                                >
                                    <div className="tw-relative tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-6 tw-from-orange-50 tw-from-50% tw-to-yellow-50 tw-p-5">
                                        <Icon
                                            type={focusArea.iconType}
                                            className={classNames('tw-text-4xl', {
                                                'tw-text-red-500': index === 0,
                                                'tw-text-orange-500': index === 1,
                                                'tw-text-yellow-500': index === 2,
                                                'tw-text-blue-500': index === 3,
                                            })}
                                            size="large"
                                        />
                                        <div
                                            className={classNames('tw-flex tw-flex-col tw-items-start tw-gap-2', {
                                                'tw-text-red-950': index === 0,
                                                'tw-text-orange-950': index === 1,
                                                'tw-text-yellow-950': index === 2,
                                                'tw-text-blue-950': index === 3,
                                            })}
                                        >
                                            <div className="tw-text-2xl tw-font-semibold tw-leading-none">
                                                {isLoading ? <Loading size={16} /> : statistics[windowsSecuritySeverityRating.id]}
                                                <span className="tw-text-xs tw-text-gray-400">
                                                    {index === 0 && ` / ${stats.CRITICAL}`}
                                                    {index === 1 && ` / ${stats.HIGH}`}
                                                    {index === 2 && ` / ${stats.MEDIUM}`}
                                                    {index === 3 && ` / ${stats.LOW}`}
                                                </span>
                                            </div>

                                            <div className="tw-text-xs tw-font-semibold tw-leading-none">{windowsSecuritySeverityRating.name}</div>
                                        </div>
                                    </div>
                                </Button>
                            </Tooltip>
                        ))}

                        <div className="tw-col-span-4 tw-grid tw-h-0.5 tw-grid-cols-4">
                            <div className="tw-bg-gradient-to-r tw-from-red-400 tw-to-orange-400"></div>
                            <div className="tw-bg-gradient-to-r tw-from-orange-400  tw-to-yellow-400"></div>
                            <div className="tw-bg-gradient-to-r tw-from-yellow-400  tw-to-blue-400"></div>
                            <div className="tw-bg-gradient-to-r tw-from-blue-400  tw-to-sky-400"></div>
                        </div>
                    </div>
                    <div className="tw-h-full">
                        <VirtualizedTable
                            sortBy={pagingState.sortBy}
                            sortDirection={pagingState.sortDirection}
                            items={dataStore}
                            density={virtualizedTablePropTypes.density.spacious}
                            isLoading={isLoading}
                            urlIsDirty={urlIsDirty}
                            showRowCount={permissions.canViewSecurity}
                            disableHeader={!permissions.canViewSecurity}
                            sort={({ sortBy, sortDirection }) => {
                                api.cancel();
                                setIsLoading(true);
                                setDataStore([]);
                                Url.setMany([
                                    {
                                        name: 'sortBy',
                                        value: sortBy,
                                    },
                                    {
                                        name: 'sortDirection',
                                        value: sortDirection,
                                    },
                                ]);
                                setPagingState((c) => ({
                                    ...c,
                                    sortBy,
                                    sortDirection,
                                    pageNumber: 1,
                                }));
                            }}
                            totalRowCount={pagingRef.current.totalRowCount}
                            entity={focusArea.entity}
                            onScrollToBottom={onScrollToBottom}
                            noRowsRenderer={noRowsRendererDetection()}
                            actions={
                                singleEndpointView &&
                                focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id && (
                                    <WithExperimentalFeatures>
                                        <Button
                                            color={BUTTON.PRIMARY}
                                            href={`api/organizations/${selectedOrganization.id}/nvd/cve/device/${endpointId}/export`}
                                        >
                                            Export
                                        </Button>
                                    </WithExperimentalFeatures>
                                )
                            }
                        >
                            {focusArea.id !== windowsSecurityVulnerabilityManagementFocus.cveList.id && (
                                <Column
                                    dataKey="icon"
                                    disableSort
                                    label=""
                                    minWidth={32}
                                    maxWidth={32}
                                    type="avatar"
                                    cellRenderer={({ rowData, rowIndex }) => {
                                        if (rowData.thumbnail) {
                                            return (
                                                <Avatar
                                                    className="tw-h-10 tw-w-10 tw-text-sm tw-font-semibold tw-uppercase"
                                                    src={rowData.thumbnail}
                                                >
                                                    {rowData.product?.slice(0, 1) || 'A'}
                                                </Avatar>
                                            );
                                        }
                                        if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id) {
                                            return (
                                                <Avatar className="tw-h-10 tw-w-10 tw-bg-slate-100 tw-text-sm tw-font-semibold tw-uppercase tw-text-slate-600">
                                                    <DeviceTypeIcon
                                                        device={rowData}
                                                        className="tw-text-lg"
                                                    />
                                                </Avatar>
                                            );
                                        }
                                        if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.software.id) {
                                            return (
                                                <Avatar className="tw-h-10 tw-w-10 tw-bg-slate-100 tw-text-sm tw-font-semibold tw-uppercase tw-text-slate-600">
                                                    {rowData.product?.slice(0, 1) || 'A'}
                                                </Avatar>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            )}
                            {focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id && !singleEndpointView && (
                                <Column
                                    dataKey="icon"
                                    disableSort
                                    label=""
                                    minWidth={32}
                                    maxWidth={32}
                                    type="info"
                                    cellRenderer={({ rowData }) => {
                                        const headerClassNames = 'tw-font-medium tw-leading-relaxed tw-text-gray-300';
                                        return (
                                            <div>
                                                <Tooltip
                                                    content={
                                                        <div>
                                                            <div className={headerClassNames}>Published</div>
                                                            <div className="tw-mb-2">{formatTimestamp(rowData?.published)}</div>
                                                            <div className={headerClassNames}>Last Modified</div>
                                                            <div className="tw-mb-2">{formatTimestamp(rowData?.lastModified)}</div>
                                                            <div className={headerClassNames}>CVSS Version</div>
                                                            <div className="tw-mb-2">
                                                                {rowData?.cvssVersion === 'V20'
                                                                    ? '2.0'
                                                                    : rowData?.cvssVersion === 'V30'
                                                                      ? '3.0'
                                                                      : rowData?.cvssVersion === 'V31'
                                                                        ? '3.1'
                                                                        : ''}
                                                            </div>
                                                            <div className={headerClassNames}>{pluralize('CPE String', rowData?.uniqueMatchCPE.length)}</div>
                                                            {rowData?.uniqueMatchCPE?.map((cpe, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {cpe}
                                                                        <br />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    }
                                                    fullWidth
                                                    interactive
                                                >
                                                    <InfoIcon
                                                        content={[
                                                            { label: 'Published', value: formatTimestamp(rowData?.published) },
                                                            { label: 'Last Modified', value: formatTimestamp(rowData?.lastModified) },
                                                            {
                                                                label: 'CVSS Version',
                                                                value:
                                                                    rowData?.cvssVersion === 'V20'
                                                                        ? '2.0'
                                                                        : rowData?.cvssVersion === 'V30'
                                                                          ? '3.0'
                                                                          : rowData?.cvssVersion === 'V31'
                                                                            ? '3.1'
                                                                            : '',
                                                            },
                                                            {
                                                                label: pluralize('CPE String', rowData?.uniqueMatchCPE.length),
                                                                value: rowData?.uniqueMatchCPE?.map((cpe, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            {cpe}
                                                                            <br />
                                                                        </div>
                                                                    );
                                                                }),
                                                            },
                                                        ]}
                                                    />
                                                </Tooltip>
                                            </div>
                                        );
                                    }}
                                />
                            )}
                            <Column
                                dataKey={
                                    focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id
                                        ? 'name'
                                        : focusArea.id === windowsSecurityVulnerabilityManagementFocus.software.id
                                          ? 'product'
                                          : '_id'
                                }
                                label="Name"
                                minWidth={200}
                                type="multiLine"
                                cellRenderer={({ rowData, rowIndex }) => {
                                    if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id) {
                                        return (
                                            <CapaOneLink
                                                to={`windows/device/${rowData.deviceId}/security/${windowsSecurityVulnerabilityManagementFocus.software.id}`}
                                            >
                                                <TwoLineCellRenderer
                                                    callToAction
                                                    secondary={rowData.osName || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                    main={rowData.name || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                />
                                            </CapaOneLink>
                                        );
                                    }
                                    if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id) {
                                        const uniqueProducts = [...new Set(rowData.products.map(({ product }) => product))];
                                        return (
                                            <CapaOneLink
                                                to={
                                                    singleEndpointView
                                                        ? `windows/device/${endpointId}/security/${windowsSecurityVulnerabilityManagementFocus.cveList.id}/${rowData._id}`
                                                        : `windows/security/vulnerability/${windowsSecurityVulnerabilityManagementFocus.cveList.id}/${rowData._id}`
                                                }
                                            >
                                                <TwoLineCellRenderer
                                                    disableEllipsis
                                                    callToAction
                                                    main={rowData._id || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                    secondary={uniqueProducts.map((product, index) => {
                                                        const relevantProducts = rowData.products.filter((p) => p.product === product);
                                                        const uniqueVersions = [...new Set(relevantProducts.map(({ version }) => version))];
                                                        return (
                                                            <React.Fragment key={product}>
                                                                {index > 0 && <span>&nbsp;&middot;&nbsp;</span>}
                                                                <Tooltip
                                                                    fullWidth
                                                                    content={
                                                                        <div className="tw-text-sm">
                                                                            {singleEndpointView ? (
                                                                                <PageTitle
                                                                                    color="inherit"
                                                                                    subtitle
                                                                                    description={uniqueVersions.map((uniqueVersion) => (
                                                                                        <div className="tw-mt-0.5 tw-font-semibold">{uniqueVersion}</div>
                                                                                    ))}
                                                                                >
                                                                                    {product}
                                                                                </PageTitle>
                                                                            ) : (
                                                                                <PageTitle
                                                                                    color="inherit"
                                                                                    subtitle
                                                                                    description={`${pluralize(
                                                                                        'vulnerable version',
                                                                                        uniqueVersions.length,
                                                                                        true,
                                                                                    )}`}
                                                                                >
                                                                                    {product}
                                                                                </PageTitle>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                >
                                                                    <span>{product}</span>
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                />
                                            </CapaOneLink>
                                        );
                                    }
                                    return (
                                        <CapaOneLink
                                            to={
                                                singleEndpointView
                                                    ? `windows/device/${endpointId}/security/${windowsSecurityVulnerabilityManagementFocus.cveList.id}?softwareName=${rowData.product}&softwareName.operator=$eq`
                                                    : `windows/security/vulnerability/${windowsSecurityVulnerabilityManagementFocus.cveList.id}?softwareName=${rowData.product}&softwareName.operator=$eq`
                                            }
                                        >
                                            <TwoLineCellRenderer
                                                callToAction
                                                main={rowData.product || placeholderValue({ type: EPlaceholderValues.NONE })}
                                                secondary={rowData.vendor || placeholderValue({ type: EPlaceholderValues.NONE })}
                                            />
                                        </CapaOneLink>
                                    );
                                }}
                            />
                            {focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id && (
                                <Column
                                    dataKey="cveTags"
                                    label=""
                                    minWidth={96}
                                    maxWidth={96}
                                    type="badge"
                                    cellRenderer={({ rowData }) => {
                                        if (rowData.disputed) {
                                            return <WindowsSecurityDisputedBadge />;
                                        }
                                        return null;
                                    }}
                                />
                            )}

                            {focusArea.id == windowsSecurityVulnerabilityManagementFocus.software.id && (
                                <Column
                                    dataKey="appId"
                                    label=""
                                    minWidth={128}
                                    maxWidth={128}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="button"
                                    cellRenderer={({ rowData }) => {
                                        if (rowData.appId) {
                                            return (
                                                <CapaOneLink to={`windows/updater/application/${rowData.appId}?updater.status.tab=0`}>
                                                    <Button
                                                        variant={BUTTON.OUTLINED}
                                                        size={BUTTON.SMALL}
                                                        color={BUTTON.PRIMARY}
                                                        className="tw-text-xs tw-leading-loose"
                                                    >
                                                        Check for updates
                                                    </Button>
                                                </CapaOneLink>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            )}
                            {focusArea.id !== windowsSecurityVulnerabilityManagementFocus.endpoint.id && !singleEndpointView && (
                                <Column
                                    dataKey="vulnerableEndpoints"
                                    label="Vulnerable endpoints"
                                    minWidth={152}
                                    maxWidth={152}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="number"
                                    cellRenderer={({ rowData }) => {
                                        if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id) {
                                            return (
                                                <CapaOneLink to={`windows/security/vulnerability/cve/${rowData._id}`}>
                                                    <PageTitle
                                                        color="inherit"
                                                        description=""
                                                    >
                                                        {rowData.vulnerableEndpoints || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                    </PageTitle>
                                                </CapaOneLink>
                                            );
                                        }
                                        return (
                                            <CapaOneLink
                                                to={`windows/security/vulnerability/endpoint?softwareName=${rowData.product}&softwareName.operator=$eq`}
                                            >
                                                <PageTitle
                                                    color="inherit"
                                                    description=""
                                                >
                                                    {rowData.vulnerableEndpoints || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                </PageTitle>
                                            </CapaOneLink>
                                        );
                                    }}
                                />
                            )}
                            {focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id && (
                                <Column
                                    dataKey="lastCheckIn"
                                    label="Last Check-in"
                                    minWidth={144}
                                    maxWidth={144}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="date"
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <b>
                                                {rowData.lastCheckIn
                                                    ? dayjs(rowData?.lastCheckIn).fromNow()
                                                    : placeholderValue({
                                                          type: EPlaceholderValues.NEVER,
                                                          customTooltipText: 'This endpoint has not checked in yet.',
                                                      })}
                                            </b>
                                        );
                                    }}
                                />
                            )}
                            {focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id && (
                                <Column
                                    dataKey="vulnerableSoftware"
                                    label="Vulnerable software"
                                    minWidth={144}
                                    maxWidth={144}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="number"
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <CapaOneLink to={`windows/security/vulnerability/software?name=${rowData.name}&name.operator=$eq`}>
                                                <PageTitle
                                                    color="inherit"
                                                    description=""
                                                >
                                                    {rowData.vulnerableSoftware || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                </PageTitle>
                                            </CapaOneLink>
                                        );
                                    }}
                                />
                            )}

                            {focusArea.id !== windowsSecurityVulnerabilityManagementFocus.cveList.id && (
                                <Column
                                    dataKey="vulnerabilities"
                                    label="Detected CVEs"
                                    minWidth={120}
                                    maxWidth={120}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="number"
                                    cellRenderer={({ rowData }) => {
                                        if (focusArea.id === windowsSecurityVulnerabilityManagementFocus.endpoint.id) {
                                            return (
                                                <CapaOneLink to={`windows/security/vulnerability/cve?name=${rowData.name}&name.operator=$eq`}>
                                                    <PageTitle
                                                        color="inherit"
                                                        description=""
                                                    >
                                                        {rowData.vulnerabilities || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                    </PageTitle>
                                                </CapaOneLink>
                                            );
                                        }
                                        return (
                                            <CapaOneLink
                                                to={{
                                                    pathname: singleEndpointView
                                                        ? `windows/device/${endpointId}/security/${windowsSecurityVulnerabilityManagementFocus.cveList.id}`
                                                        : `windows/security/vulnerability/${windowsSecurityVulnerabilityManagementFocus.cveList.id}`,
                                                    search: `softwareName=${rowData.product}&softwareName.operator=$eq`,
                                                }}
                                            >
                                                <PageTitle
                                                    color="inherit"
                                                    description=""
                                                >
                                                    {rowData.vulnerabilities || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                                </PageTitle>
                                            </CapaOneLink>
                                        );
                                    }}
                                />
                            )}

                            <Column
                                dataKey={focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id ? 'CVSS' : 'highestCVSS'}
                                label={focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id ? 'CVSS Score' : 'Highest CVSS Score'}
                                labelDescription={<CommonVulnerabilityScoringSystemLabelDescription />}
                                minWidth={focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id ? 144 : 168}
                                maxWidth={focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id ? 144 : 168}
                                defaultSortDirection={SORT_DIRECTION.DESC}
                                type="number"
                                cellRenderer={({ rowData }) => {
                                    const score = rowData.CVSS || rowData.highestCVSS || 0;
                                    const windowsSecuritySeverityRating = windowsSecuritySeverityRatings.find(({ range }) =>
                                        inRange(score, Number(range.min), Number(range.max) + 0.1),
                                    );

                                    if (windowsSecuritySeverityRating) {
                                        return (
                                            <Tooltip
                                                position={TOOLTIP.POSITION.TOP_END}
                                                fullWidth
                                                content={
                                                    <div className="tw-px-1">
                                                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-2 tw-text-sm tw-font-semibold tw-leading-none">
                                                            <div className="tw-text-tiny tw-text-gray-200">CVSS Score</div>
                                                            <div className="tw-text-tiny tw-text-gray-200">Severity</div>
                                                            <div>{score.toFixed(1)}</div>
                                                            <div>{windowsSecuritySeverityRating.name}</div>
                                                        </div>

                                                        {rowData.cvssVersion && (
                                                            <div className="tw-col-span-2 tw-mt-2 tw-text-tiny tw-font-semibold tw-text-neutral-400">
                                                                {classNames({
                                                                    'Version 2.0': rowData.cvssVersion === 'V20',
                                                                    'Version 3.0': rowData.cvssVersion === 'V30',
                                                                    'Version 3.1': rowData.cvssVersion === 'V31',
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            >
                                                <TailwindBadge
                                                    color="custom"
                                                    compact
                                                    noShadow
                                                    className={windowsSecuritySeverityRating.classNames}
                                                >
                                                    {score.toFixed(1)}
                                                </TailwindBadge>
                                            </Tooltip>
                                        );
                                    }
                                    return (
                                        <TailwindBadge
                                            color="neutral"
                                            compact
                                            noShadow
                                        >
                                            {score || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                        </TailwindBadge>
                                    );
                                }}
                            />
                            {focusArea.id === windowsSecurityVulnerabilityManagementFocus.cveList.id && !singleEndpointView && (
                                <Column
                                    dataKey="riskScore"
                                    label="Risk Score"
                                    labelDescription={<WindowsSecurityRiskScoreLabelDescription />}
                                    minWidth={120}
                                    maxWidth={120}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    type="number"
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <TailwindBadge
                                                color="custom"
                                                compact
                                                noShadow
                                                className={classNames({
                                                    'tw-bg-red-100 tw-text-red-900': inRange(rowData.riskScore, 90, 100.1),
                                                    'tw-bg-orange-100 tw-text-orange-900': inRange(rowData.riskScore, 70, 90),
                                                    'tw-bg-yellow-100 tw-text-yellow-900': inRange(rowData.riskScore, 40, 70),
                                                    'tw-bg-blue-100 tw-text-blue-900': inRange(rowData.riskScore, 0, 40),
                                                    'tw-bg-neutral-100 tw-text-neutral-900': isUndefined(rowData.riskScore),
                                                })}
                                            >
                                                {rowData.riskScore || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}
                                            </TailwindBadge>
                                        );
                                    }}
                                />
                            )}
                        </VirtualizedTable>
                    </div>
                </div>
            </WidgetPaper>
        </>
    );
};

const WindowsSecurityRiskScoreLabelDescription = ({ children, position = TOOLTIP.POSITION.TOP_END }) => {
    return (
        <div className="tw-w-112">
            <h2 className="tw-mb-2">Risk Score</h2>
            <div className="tw-text-xs tw-font-medium tw-leading-relaxed">
                The Risk Score is based on the number of affected endpoints and the CVSS score. The score ranges from 0-100 to help prioritize which
                vulnerabilities to fix first.
                <br />
                <br />
                The number of affected endpoints is weighted at 25% and the CVSS score is weighted at 75%. The CVSS score holds more weight, because it only
                requires a single vulnerable endpoint to compromise an entire organization.
            </div>
        </div>
    );
};

export const CommonVulnerabilityScoringSystemLabelDescription = ({ children }) => {
    return (
        <div>
            <div className="tw-w-112">
                <PageTitle
                    subtitle
                    color="inherit"
                    descriptionClassName="tw-leading-normal"
                    description="The Common Vulnerability Scoring System (CVSS) is a standardized way to rate the severity of software vulnerabilities. 
                            It uses a score between 0 and 10 to help prioritize which vulnerabilities to fix first."
                >
                    Common Vulnerability Scoring System (CVSS)
                </PageTitle>
            </div>
            <div className="tw-mt-4 tw-grid tw-grid-cols-auto-1fr tw-gap-x-6 tw-gap-y-2 tw-font-semibold">
                <div>
                    <b>CVSS Score</b>
                </div>
                <div>
                    <b>Severity Rating</b>
                </div>
                {windowsSecuritySeverityRatings.map((windowsSecuritySeverityRating, index) => {
                    return (
                        <Fragment key={index}>
                            <div>
                                {windowsSecuritySeverityRating.range.min} - {windowsSecuritySeverityRating.range.max}
                            </div>
                            <div>{windowsSecuritySeverityRating.name}</div>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export const SecurityQueryBuilder = ({ focusArea, onInit, onSubmit, urlIsDirty }) => {
    const queryBuilderApi = useApi();

    const queryBuilderTimeoutRef = useRef();
    const { cveID, deviceId } = useParams();

    const [defaultConfiguration, setDefaultConfiguration] = useState(() => {
        const { leafs, ...remainingProperties } = QUERY_BUILDER_SECURITY;

        if (deviceId) {
            if (cveID) {
                onInit({});
                return null;
            }
            return {
                ...remainingProperties,
                leafs: leafs
                    .filter((leaf) => leaf.custom.availableForTabs.includes('endpointId') && leaf.custom.availableForTabs.includes(focusArea.id))
                    .map((leaf) => ({
                        ...leaf,
                        primary: leaf.id === LEAF_ID.SOFTWARE_NAME,
                    })),
            };
        }
        if (cveID) {
            return {
                ...remainingProperties,
                leafs: leafs
                    .filter((leaf) => leaf.custom.availableForTabs.includes('cveId'))
                    .map((leaf) => ({
                        ...leaf,
                        primary: leaf.id === LEAF_ID.NAME,
                    })),
            };
        }
        return {
            ...remainingProperties,
            leafs: leafs
                .filter((leaf) => leaf.custom.availableForTabs.includes(focusArea.id))
                .map((leaf) => ({
                    ...leaf,
                    primary:
                        (focusArea.id === 'endpoint' && leaf.id === LEAF_ID.NAME) ||
                        ((focusArea.id === 'software' || focusArea.id === 'cve') && leaf.id === LEAF_ID.SOFTWARE_NAME),
                })),
        };
    });

    const onInitHandler = (activeLeafs) => {
        onInit({
            device: leafsToMongo(activeLeafs.filter((activeLeaf) => !activeLeaf.custom.isCveFilter)),
            cve: leafsToMongo(activeLeafs.filter((activeLeaf) => activeLeaf.custom.isCveFilter)),
        });
    };

    const onSubmitHandler = (activeLeafs) => {
        onSubmit({
            device: leafsToMongo(activeLeafs.filter((activeLeaf) => !activeLeaf.custom.isCveFilter)),
            cve: leafsToMongo(activeLeafs.filter((activeLeaf) => activeLeaf.custom.isCveFilter)),
        });
    };

    const onLeafTypeStringChange = ({ leaf, event }, callback) => {
        if (leaf.apiKey) {
            clearTimeout(queryBuilderTimeoutRef.current);
            queryBuilderApi.cancel();
            const { selectionStart } = event.target; // Destruct here to ignore potential arrow-back clicks from user.
            queryBuilderTimeoutRef.current = setTimeout(() => {
                const strings = leaf.value.split(',');
                let searchString = leaf.value;
                if (strings.length > 1) {
                    let charCount = 0;
                    strings.some((s) => {
                        charCount += s.length + 1; // + 1 for the comma.
                        if (charCount > selectionStart) {
                            searchString = s.trim();
                            return true;
                        }
                        return false;
                    });
                }
                queryBuilderApi
                    .getDeviceFilterSuggestions({ key: leaf.apiKey, search: searchString })
                    .then((suggestions) => {
                        leaf.suggestions = suggestions
                            .filter((suggestion) => !strings.includes(suggestion))
                            .map((suggestion) => ({ name: suggestion, id: suggestion }));
                    })
                    .catch(noop)
                    .finally(callback);
            }, 300);
            leaf.suggestions = [];
            callback();
        }
    };

    if (defaultConfiguration) {
        return (
            <QueryBuilder
                defaultConfiguration={defaultConfiguration}
                onInit={onInitHandler}
                onSubmit={onSubmitHandler}
                urlIsDirty={urlIsDirty}
                onLeafTypeStringChange={onLeafTypeStringChange}
                showMongoHints
            />
        );
    }
    return null;
};

export const WindowsSecurityDisputedBadge = () => {
    return (
        <Tooltip
            content={
                <>
                    <div>A CVE is disputed when its vulnerability status is contested.</div>
                    <div className="tw-my-1">
                        <Link
                            className="tw-text-inherit tw-underline tw-underline-offset-4"
                            href="https://nvd.nist.gov/vuln/vulnerability-status"
                            component="a"
                            target="_blank"
                        >
                            Learn more
                        </Link>
                    </div>
                </>
            }
            interactive
        >
            <TailwindBadge
                size="small"
                color="purple"
                noShadow
            >
                Disputed
            </TailwindBadge>
        </Tooltip>
    );
};
