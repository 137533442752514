import { TOOLTIP } from '@capasystems/constants';
import { Button, Dialog, Icon, IconButton, LayoutCentered, Loading, Tooltip } from '@capasystems/ui';
import { isUndefined } from '@capasystems/utils';
import { TAndroidConfigurationWithId } from '@db/party';
import { coreAndroidManagement } from '@thirdparty/constants';
import { Heading } from '@thirdparty/ui';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useAndroidApi } from '../../hooks/useApi/useApi';

export type AndroidApplicationManagedConfigurationControllerProps = {
    application: TAndroidConfigurationWithId;
    setApplication: React.Dispatch<React.SetStateAction<Partial<TAndroidConfigurationWithId>>>;
};

export const AndroidApplicationManagedConfigurationController: React.FC<AndroidApplicationManagedConfigurationControllerProps> = ({
    application,
    setApplication,
}) => {
    const [validateManagedConfiguration, setValidateManagedConfiguration] = useState(false);

    const [applicationProperties] = application.data?.applications || [];
    const onExited = () => {
        setValidateManagedConfiguration(false);
    };

    const onClick = () => setValidateManagedConfiguration(true);

    if (application.configurationType !== coreAndroidManagement.configurationType.application || isUndefined(applicationProperties)) {
        return null;
    }
    return (
        <>
            {validateManagedConfiguration && (
                <ValidateManagedConfiguration
                    onExited={onExited}
                    applicationProperties={applicationProperties}
                    application={application}
                    setApplication={setApplication}
                />
            )}
            {applicationProperties.managedConfigurationTemplate?.templateId ? (
                <Tooltip
                    content="Edit or delete managed configuration"
                    position={TOOLTIP.POSITION.LEFT}
                >
                    <IconButton
                        color="primary"
                        onClick={onClick}
                    >
                        <Icon type="configureOutlined" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip
                    content={
                        <div className="tw-leading-loose">
                            <p>
                                Some apps designed for enterprises include built-in settings called <b>managed configurations</b> that IT admins can configure.
                            </p>
                            <p className="tw-mt-4">For example, an app may have the option to only sync data when a device is connected to Wi-Fi.</p>
                        </div>
                    }
                    position={TOOLTIP.POSITION.LEFT}
                >
                    <IconButton onClick={onClick}>
                        <Icon type="configureOutlined" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

type ValidateManagedConfigurationProps = {
    onExited: (isDirty: boolean) => void;
    applicationProperties: TAndroidConfigurationWithId['data']['applications'][0];
    application: TAndroidConfigurationWithId;
    setApplication: React.Dispatch<React.SetStateAction<Partial<TAndroidConfigurationWithId>>>;
};

const ValidateManagedConfiguration: React.FC<ValidateManagedConfigurationProps> = ({ onExited, applicationProperties, application, setApplication }) => {
    const iframeContainerRef = useRef<any>();
    const isDirty = useRef(false);
    const androidApi = useAndroidApi();
    const [open, setOpen] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onEntering = () => {
        if (applicationProperties.managedConfigurationTemplate?.templateId) {
            androidApi
                .getGooglePlayStoreWebToken()
                .then((webToken) => {
                    window.gapi.load('gapi.iframes', () => {
                        const iframe = window.gapi.iframes.getContext().openChild({
                            url: `https://play.google.com/managed/mcm?token=${webToken.value}&packageName=${applicationProperties.packageName}&mcmId=${applicationProperties.managedConfigurationTemplate.templateId}&canDelete=true`,
                            where: iframeContainerRef.current,
                            attributes: { style: 'height: 100%', scrolling: 'yes' },
                        });

                        iframe.register(
                            'onconfigdeleted',
                            () => {
                                if (application && application.id) {
                                    androidApi
                                        .updateAndroidApplication(application.id, {
                                            data: {
                                                applications: application.data.applications.map(({ managedConfigurationTemplate, ...rest }: any) => ({ ...rest })), // Remove managedConfigurationTemplate, but keep all other properties.
                                            },
                                        })
                                        .then(() => {
                                            setApplication((prev) => ({
                                                ...prev,
                                                data: {
                                                    ...prev.data,
                                                    applications: prev.data.applications.map(({ managedConfigurationTemplate, ...rest }: any) => ({ ...rest })),
                                                },
                                            }));
                                        })
                                        .then(() => {
                                            isDirty.current = true;
                                            setOpen(false);
                                        })
                                        .catch((response) => {
                                            setErrorMessage(response.data?.message || 'Failed to update android application');
                                        });
                                }
                            },
                            window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
                        );

                        iframe.register(
                            'onconfigupdated',
                            () => {
                                setOpen(false);
                            },
                            window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
                        );
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Could not generate web token');
                });
        } else {
            androidApi
                .getGooglePlayStoreApplicationInfo(applicationProperties.packageName)
                .then((response) => {
                    if (response.managedProperties) {
                        androidApi
                            .getGooglePlayStoreWebToken()
                            .then((webToken) => {
                                window.gapi.load('gapi.iframes', () => {
                                    const iframe = window.gapi.iframes.getContext().openChild({
                                        url: `https://play.google.com/managed/mcm?token=${webToken.value}&packageName=${applicationProperties.packageName}`,
                                        where: iframeContainerRef.current,
                                        attributes: { style: 'height: 100%', scrolling: 'yes' },
                                    });
                                    iframe.register(
                                        'onconfigupdated',
                                        ({ mcmId }: any) => {
                                            if(application && application.id) {
                                                androidApi
                                                    .updateAndroidApplication(application.id, {
                                                        data: {
                                                            applications: application.data.applications.map((app: any) => ({
                                                                ...app,
                                                                managedConfigurationTemplate: {
                                                                    templateId: mcmId,
                                                                },
                                                            })), // Remove managedConfigurationTemplate, but keep all other properties.
                                                        },
                                                    })
                                                    .then(() => {
                                                        setApplication((prev) => ({
                                                            ...prev,
                                                            data: {
                                                                ...prev.data,
                                                                applications: prev.data.applications.map((app: any) => ({
                                                                    ...app,
                                                                    managedConfigurationTemplate: {
                                                                        templateId: mcmId,
                                                                    },
                                                                })),
                                                            },
                                                        }));
                                                    })
                                                    .then(() => {
                                                        isDirty.current = true;
                                                        setOpen(false);
                                                    })
                                                    .catch((response) => {
                                                        setErrorMessage(response.data?.message || 'Failed to update android application');
                                                    });
                                            }
                                        },
                                        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
                                    );
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                                setErrorMessage('Could not generate web token');
                            });
                    } else {
                        setErrorMessage(`'${response.title}' does not have any configurable settings`);
                    }
                })
                .catch(() => {
                    setErrorMessage('An unknown error occurred');
                });
        }
    };

    return (
        <Dialog
            open={open}
            onExited={() => onExited(isDirty.current)}
            onClose={() => setOpen(false)}
            onEntering={onEntering}
            PaperProps={{ style: { minWidth: '80vw', height: '80vh' } }}
        >
            <div className="full tw-absolute tw-right-5 tw-top-2 tw-z-50 tw-ml-auto">
                <Tooltip
                    fullWidth
                    interactive
                    content={
                        <>
                            <Heading
                                subheading
                                bold
                                className="tw-mb-2 tw-mt-0 tw-text-current"
                            >
                                Supported Macros
                            </Heading>
                            <MacroInfoSection heading="Device imei number">$hwi.imei$</MacroInfoSection>
                            <MacroInfoSection heading="Device serial number">$hwi.serialNumber$</MacroInfoSection>
                            <MacroInfoSection heading="Device user full name">$user.fullName$</MacroInfoSection>
                            <MacroInfoSection heading="Device user principal name">$user.userPrincipalName$</MacroInfoSection>
                            <MacroInfoSection heading="Device user email">$user.email$</MacroInfoSection>
                        </>
                    }
                >
                    <Icon type="helpOutlined" />
                </Tooltip>
            </div>
            <div
                className="tw-h-full tw-w-full"
                ref={iframeContainerRef}
            >
                <LayoutCentered>
                    {errorMessage ? (
                        <div>
                            <h2>{errorMessage}</h2>
                            <Button
                                className="tw-mt-4"
                                color="primary"
                                variant="contained"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </LayoutCentered>
            </div>
        </Dialog>
    );
};

export type MacroInfoSectionProps = {
    heading: string;
    last?: boolean;
    children: React.ReactNode;
};

export const MacroInfoSection: React.FC<MacroInfoSectionProps> = ({ heading, last = false, children }) => (
    <>
        <p className="tw-mb-1 tw-mt-0">{heading}</p>
        <p
            className={classnames({
                'tw-mt-0 tw-text-xs tw-font-semibold': true,
                'tw-mb-4': !last,
                'tw-mb-0': last,
            })}
        >
            {children}
        </p>
    </>
);
