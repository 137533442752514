import { BUTTON } from '@capasystems/constants';
import { Button, Checkbox, Column, Ellipsis, EmptyState, Icon, IconButton, LayoutCentered, SavingChanges, VirtualizedTable } from '@capasystems/ui';
import { noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { WidgetPaper, useApi, useNavigate } from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder } from '../query-builder/query-builder';

const TransferList = forwardRef(
    (
        {
            items,
            cellRenderer = defaultCellRenderer,
            onAdd,
            onRemove,
            entity = 'item',
            className,
            loading = false,
            savingChanges = false,
            errorMessage = null,
            onResize,
            viewMode = false,
            setViewMode = () => null,
            hasFilter = false,
            endpointFilter = false,
            init,
            submit,
            totalRowCount = 0,
            totalRowCountAssigned = 0,
            handleCustomAction,
            deviceFilterPicker,
            setShowDeviceFilterPicker,
            urlIsDirty,
            onScrollToBottom = () => null,
            onScrollToBottomAssigned = () => null,
            checkedAll = false,
            setCheckedAll = () => null,
            checkedAllAssigned = false,
            setCheckedAllAssigned = () => null,
            cleanUp = false,
            queryBuilder,
            portalContainer = null,
            disableSelectAllAvailable = false,
            disableSelectAllAssigned = false,
            disableHeader,
            showMongoHints,
            children,
        },
        transferListRef,
    ) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [checkedAvailableItems, setCheckedAvailableItems] = useState([]);
        const [checkedAppliedItems, setCheckedAppliedItems] = useState([]);
        const [queryBuilderConfiguration] = useState(queryBuilder || getDefaultQueryBuilderConfiguration({ placeholder: `Search ${pluralize(entity)}` }));
        const [excludeList, setExcludeList] = useState([]);
        const [excludeListAssigned, setExcludeListAssigned] = useState([]);
        const [filterChecked, setFilterChecked] = useState([]);
        const [filterCheckedAssigned, setFilterCheckedAssinged] = useState([]);

        const queryBuilderApi = useApi();
        const queryBuilderTimeoutRef = useRef();
        const queryBuilderRef = useRef({});
        const navigate = useNavigate();

        const onSubmit = ([activeLeaf]) => {
            setSearchTerm(activeLeaf ? activeLeaf.value : '');
            cleanUpSelectedItems();
        };

        const [availableItems, availableItemsTotalCount, appliedItems, appliedItemsTotalCount] = useMemo(() => {
            const totalAvailableItems = items.filter((item) => !item.applied);
            const totalAppliedItems = items.filter((item) => item.applied);
            if (searchTerm) {
                return [
                    totalAvailableItems.filter((item) => item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
                    totalAvailableItems.length,
                    totalAppliedItems.filter((item) => item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
                    totalAppliedItems.length,
                ];
            }
            return [totalAvailableItems, totalAvailableItems.length, totalAppliedItems, totalAppliedItems.length];
        }, [items, searchTerm]);

        const cleanUpSelectedItems = () => {
            setCheckedAvailableItems([]);
            setCheckedAppliedItems([]);
            setCheckedAll(false);
            setCheckedAllAssigned(false);
            setExcludeList([]);
            setExcludeListAssigned([]);
        };

        useEffect(() => {
            if (cleanUp) {
                cleanUpSelectedItems();
            } else {
                if (checkedAll) {
                    setCheckedAvailableItems(
                        availableItems
                            .filter((a) => !excludeList.includes(a.id))
                            .map((item) => {
                                return item.id;
                            }),
                    );
                }
                if (checkedAllAssigned) {
                    setCheckedAppliedItems(
                        appliedItems
                            .filter((a) => !excludeListAssigned.includes(a.id))
                            .map((item) => {
                                return item.id;
                            }),
                    );
                }
            }
        }, [items]);

        useEffect(() => {
            cleanUpSelectedItems();
        }, [viewMode]);

        const onLeafTypeStringFocus = (data, callback) => {
            if (data.leaf.value.trim() === '') {
                onLeafTypeStringChange(data, callback);
            }
        };

        const onMissingLeafData = (leaf, callback) => {
            queryBuilderApi.cancel();
            queryBuilderApi
                .getDeviceFilterSuggestions({ key: leaf.id, search: '' })
                .then((suggestions) => {
                    leaf.options = [
                        ...new Set([
                            ...leaf.value, // Make sure that deleted or outdated values are still shown.
                            ...suggestions,
                        ]),
                    ].map((suggestion) => ({ id: suggestion, name: suggestion }));
                })
                .catch(noop)
                .finally(() => {
                    leaf.loading = false;
                    callback();
                });
            leaf.loading = true;
            callback();
        };

        const onLeafTypeStringChange = ({ leaf, event }, callback) => {
            clearTimeout(queryBuilderTimeoutRef.current);
            queryBuilderApi.cancel();
            const { selectionStart } = event.target; // Destruct here to ignore potential arrow-back clicks from user.
            queryBuilderTimeoutRef.current = setTimeout(() => {
                const strings = leaf.value.split(',');
                let searchString = leaf.value;
                if (strings.length > 1) {
                    let charCount = 0;
                    strings.some((s) => {
                        charCount += s.length + 1; // + 1 for the comma.
                        if (charCount > selectionStart) {
                            searchString = s.trim();
                            return true;
                        }
                        return false;
                    });
                }
                queryBuilderApi
                    .getDeviceFilterSuggestions({ key: leaf.id, search: searchString })
                    .then((suggestions) => {
                        leaf.suggestions = suggestions
                            .filter((suggestion) => !strings.includes(suggestion))
                            .map((suggestion) => ({ name: suggestion, id: suggestion }));
                    })
                    .catch(noop)
                    .finally(callback);
            }, 300);
            leaf.suggestions = [];
            callback();
        };

        return (
            <>
                <SavingChanges
                    loading={savingChanges}
                    errorMessage={errorMessage}
                />
                <div
                    className={classNames(
                        'tw-grid tw-h-full tw-grid-cols-1 tw-gap-4',
                        {
                            'tw-grid-rows-auto-1fr': !portalContainer,
                            'tw-grid-rows-auto': portalContainer,
                        },
                        className,
                    )}
                >
                    <Portal
                        disablePortal={portalContainer === null}
                        container={portalContainer}
                    >
                        <div className="tw-flex tw-justify-end tw-gap-4">
                            <QueryBuilder
                                defaultConfiguration={queryBuilderConfiguration}
                                onInit={hasFilter ? init : onSubmit}
                                onSubmit={hasFilter ? submit : onSubmit}
                                className="tw-w-72"
                                urlIsDirty={urlIsDirty}
                                onMissingLeafData={onMissingLeafData}
                                onLeafTypeStringFocus={onLeafTypeStringFocus}
                                onLeafTypeStringChange={onLeafTypeStringChange}
                                onChooseFilter={!endpointFilter ? undefined : handleCustomAction(setShowDeviceFilterPicker, true)}
                                showMongoHints={showMongoHints}
                            >
                                {deviceFilterPicker}
                            </QueryBuilder>
                            <Button
                                onClick={() => {
                                    setViewMode((c) => !c);
                                }}
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                noMargin
                            >
                                {viewMode ? 'Edit membership' : 'Leave edit mode'}
                            </Button>
                        </div>
                    </Portal>
                    {viewMode ? (
                        <WidgetPaper
                            className="tw-h-full"
                            headerless
                        >
                            <VirtualizedTable
                                items={appliedItems}
                                showRowCount={appliedItems.length > 0}
                                totalRowCount={totalRowCountAssigned}
                                entity={entity}
                                noRowsRenderer={noRowsRenderer(`No ${pluralize(entity)} assigned`, searchTerm, entity, viewMode, setViewMode)}
                                isLoading={loading}
                                disableHeader={loading || disableHeader}
                                onScrollToBottom={onScrollToBottomAssigned}
                                onResize={onResize}
                            >
                                <Column
                                    minWidth={120}
                                    dataKey="name"
                                    label={`Assigned ${pluralize(entity)}`}
                                    type="string"
                                    cellRenderer={(rowData) => cellRenderer(rowData, { isAssigned: true })}
                                />
                                {children}
                            </VirtualizedTable>
                        </WidgetPaper>
                    ) : (
                        <div className="tw-grid tw-grid-cols-1fr-auto-1fr tw-gap-4">
                            <WidgetPaper
                                className="tw-h-full"
                                headerless
                            >
                                <VirtualizedTable
                                    items={availableItems}
                                    onRowClick={({ index, rowData }) => {
                                        if (checkedAvailableItems.find((i) => i === rowData.id)) {
                                            if (checkedAll) {
                                                setExcludeList(excludeList.concat([rowData.id]));
                                            }
                                            const filteredCheckedItems = checkedAvailableItems.filter((item) => item !== rowData.id);
                                            setCheckedAvailableItems(filteredCheckedItems);
                                        } else {
                                            if (checkedAll && excludeList.includes(rowData.id)) {
                                                const filteredExcludeList = excludeList.filter((e) => e !== rowData.id);
                                                setExcludeList(filteredExcludeList);
                                            }
                                            setCheckedAvailableItems([...checkedAvailableItems, rowData.id]);
                                        }
                                    }}
                                    disableHeader={availableItems.length === 0}
                                    showRowCount={availableItems.length > 0}
                                    totalRowCount={totalRowCount}
                                    entity={entity}
                                    noRowsRenderer={noRowsRenderer(`No ${pluralize(entity)} available`, searchTerm, entity, viewMode)}
                                    isLoading={loading}
                                    onResize={onResize}
                                    onScrollToBottom={onScrollToBottom}
                                >
                                    <Column
                                        minWidth={40}
                                        maxWidth={40}
                                        dataKey="id"
                                        type="checkbox"
                                        label={
                                            <Checkbox
                                                checked={
                                                    checkedAll
                                                        ? totalRowCount - excludeList.length === totalRowCount
                                                        : checkedAvailableItems.length === totalRowCount && checkedAvailableItems.length > 0
                                                }
                                                indeterminate={
                                                    checkedAll
                                                        ? totalRowCount - excludeList.length !== totalRowCount
                                                        : checkedAvailableItems.length !== totalRowCount && checkedAvailableItems.length > 0
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCheckedAvailableItems(
                                                            availableItems.map((item) => {
                                                                return item.id;
                                                            }),
                                                        );
                                                        setFilterChecked(
                                                            availableItems.map((item) => {
                                                                return item.id;
                                                            }),
                                                        );
                                                        setCheckedAll(true);
                                                        setExcludeList([]);
                                                    } else {
                                                        setCheckedAvailableItems([]);
                                                        setCheckedAll(false);
                                                        setExcludeList([]);
                                                    }
                                                }}
                                                className="tw-ml-0"
                                                disabled={disableSelectAllAvailable}
                                            />
                                        }
                                        cellRenderer={({ rowData }) => {
                                            return (
                                                <Checkbox
                                                    className="tw-ml-0"
                                                    checked={checkedAvailableItems.find((i) => i === rowData.id) !== undefined ? true : false}
                                                />
                                            );
                                        }}
                                    />
                                    <Column
                                        minWidth={120}
                                        dataKey="name"
                                        headerRenderer={() => (
                                            <div className="tw-flex tw-flex-col tw-items-start tw-pl-2">
                                                <div>Available</div>
                                                <div style={{ fontSize: '0.9em' }}>
                                                    {checkedAvailableItems.length > 0 ? (
                                                        <span>
                                                            {checkedAll ? totalRowCount - excludeList.length : checkedAvailableItems.length}/{totalRowCount}{' '}
                                                            selected
                                                        </span>
                                                    ) : (
                                                        <span>None selected</span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        type="string"
                                        cellRenderer={(rowData) => cellRenderer(rowData, { isAssigned: false })}
                                    />
                                    {children}
                                </VirtualizedTable>
                            </WidgetPaper>
                            <div>
                                <LayoutCentered direction="column">
                                    <IconButton
                                        noMargin
                                        className="tw-mb-4"
                                        color={BUTTON.PRIMARY}
                                        variant={BUTTON.RAISED}
                                        onClick={() => {
                                            onAdd(
                                                checkedAvailableItems,
                                                excludeList,
                                                checkedAvailableItems.filter((c) => !filterChecked.includes(c)),
                                            );
                                            if (transferListRef?.current && transferListRef.current.confirmationRequired) {
                                                transferListRef.current.confirmTransfer = () => {
                                                    setCheckedAvailableItems([]);
                                                };
                                            } else {
                                                setCheckedAvailableItems([]);
                                            }
                                        }}
                                        disabled={checkedAvailableItems.length === 0 && !checkedAll}
                                    >
                                        <Icon type="arrowRight" />
                                    </IconButton>
                                    <IconButton
                                        noMargin
                                        color={BUTTON.PRIMARY}
                                        variant={BUTTON.RAISED}
                                        className="tw-mt-4"
                                        onClick={() => {
                                            onRemove(
                                                checkedAppliedItems,
                                                excludeListAssigned,
                                                checkedAppliedItems.filter((c) => !filterCheckedAssigned.includes(c)),
                                            );
                                            if (transferListRef?.current && transferListRef.current.confirmationRequired) {
                                                transferListRef.current.confirmTransfer = () => {
                                                    setCheckedAppliedItems([]);
                                                };
                                            } else {
                                                setCheckedAppliedItems([]);
                                            }
                                        }}
                                        disabled={checkedAppliedItems.length === 0}
                                    >
                                        <Icon type="arrowLeft" />
                                    </IconButton>
                                </LayoutCentered>
                            </div>
                            <WidgetPaper
                                className="tw-h-full"
                                headerless
                            >
                                <VirtualizedTable
                                    items={appliedItems}
                                    disableHeader={appliedItems.length === 0}
                                    onRowClick={({ index, rowData }) => {
                                        if (checkedAppliedItems.includes(rowData.id)) {
                                            setCheckedAppliedItems((c) => c.filter((item) => item !== rowData.id));
                                            if (checkedAllAssigned) {
                                                setExcludeListAssigned(excludeListAssigned.concat([rowData.id]));
                                            }
                                        } else {
                                            if (checkedAllAssigned && excludeListAssigned.includes(rowData.id)) {
                                                const filteredExcludeList = excludeListAssigned.filter((e) => e !== rowData.id);
                                                setExcludeListAssigned(filteredExcludeList);
                                            }
                                            setCheckedAppliedItems([...checkedAppliedItems, rowData.id]);
                                        }
                                    }}
                                    showRowCount={appliedItems.length > 0}
                                    totalRowCount={totalRowCountAssigned}
                                    entity={entity}
                                    noRowsRenderer={noRowsRenderer(`No ${pluralize(entity)} assigned`, searchTerm, entity, viewMode)}
                                    isLoading={loading}
                                    onScrollToBottom={onScrollToBottomAssigned}
                                >
                                    <Column
                                        minWidth={40}
                                        maxWidth={40}
                                        dataKey="id"
                                        label={
                                            <Checkbox
                                                checked={appliedItems.length === checkedAppliedItems.length - excludeListAssigned.length}
                                                indeterminate={
                                                    (checkedAppliedItems.length > 0 &&
                                                        appliedItems.length !== checkedAppliedItems.length - excludeListAssigned.length) ||
                                                    (checkedAppliedItems.length === 0 && checkedAllAssigned)
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCheckedAppliedItems(
                                                            appliedItems.map((item) => {
                                                                return item.id;
                                                            }),
                                                        );
                                                        setFilterCheckedAssinged(
                                                            appliedItems.map((item) => {
                                                                return item.id;
                                                            }),
                                                        );
                                                        setCheckedAllAssigned(true);
                                                        setExcludeListAssigned([]);
                                                    } else {
                                                        setCheckedAppliedItems([]);
                                                        setCheckedAllAssigned(false);
                                                        setExcludeListAssigned([]);
                                                    }
                                                }}
                                                className="tw-ml-0"
                                                disabled={disableSelectAllAssigned}
                                            />
                                        }
                                        type="checkbox"
                                        cellRenderer={({ rowData }) => {
                                            return (
                                                <Checkbox
                                                    className="tw-ml-0"
                                                    checked={checkedAppliedItems.includes(rowData.id)}
                                                />
                                            );
                                        }}
                                    />
                                    <Column
                                        minWidth={120}
                                        dataKey="name"
                                        headerRenderer={() => (
                                            <div className="tw-flex tw-flex-col tw-items-start tw-pl-2">
                                                <div>Assigned</div>
                                                <div style={{ fontSize: '0.9em' }}>
                                                    {checkedAppliedItems.length > 0 ? (
                                                        <span>
                                                            {checkedAllAssigned
                                                                ? totalRowCountAssigned - excludeListAssigned.length
                                                                : checkedAppliedItems.length}
                                                            /{totalRowCountAssigned} selected
                                                        </span>
                                                    ) : (
                                                        <span>None selected</span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        type="string"
                                        cellRenderer={(rowData) => cellRenderer(rowData, { isAssigned: true })}
                                    />
                                    {children}
                                </VirtualizedTable>
                            </WidgetPaper>
                        </div>
                    )}
                </div>
            </>
        );
    },
);

const defaultCellRenderer = ({ rowData }) => <Ellipsis>{rowData.name}</Ellipsis>;

const noRowsRenderer =
    (text, searchTerm = '', entity, viewMode, setViewMode) =>
    () => {
        return (
            <EmptyState
                title={text}
                description={null}
                isSearching={searchTerm !== ''}
                searchTerm={searchTerm}
                entity={entity}
                buttonText="Edit"
                onClick={viewMode ? () => setViewMode(false) : undefined}
            />
        );
    };

TransferList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            applied: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    savingChanges: PropTypes.bool,
    errorMessage: PropTypes.node,
    cellRenderer: PropTypes.func,
    entity: PropTypes.string,
    className: PropTypes.string,
    onResize: PropTypes.func,
    viewMode: PropTypes.bool,
    setViewMode: PropTypes.func,
    onScrollToBottom: PropTypes.func,
    onScrollToBottomAssigned: PropTypes.func,
    checkedAll: PropTypes.bool,
    setCheckedAll: PropTypes.func,
    totalRowCount: PropTypes.number,
    totalRowCountAssigned: PropTypes.number,
    checkedAllAssigned: PropTypes.bool,
    setCheckedAllAssigned: PropTypes.func,
    cleanUp: PropTypes.bool,
    hasFilter: PropTypes.bool,
    endpointFilter: PropTypes.bool,
    disableSelectAllAvailable: PropTypes.bool,
    disableSelectAllAssigned: PropTypes.bool,
};

export { TransferList };
