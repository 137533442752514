import { BUTTON, SORT_DIRECTION } from '@capasystems/constants';
import { Button, Column, Ellipsis, EmptyState, Icon, IconButton, Page, Tooltip, VirtualizedTable, useParams } from '@capasystems/ui';
import { Url, noop } from '@capasystems/utils';
import { Portal } from '@mui/material';
import { ENDPOINT_TYPE, EPlaceholderValues } from '@thirdparty/constants';
import {
    ActionsDialog,
    BatteryLevel,
    CapaOneLink,
    RenamingDialog,
    UserCellRenderer,
    UserManagementAssignUserToEndpoint,
    placeholderValue,
    useAppleApi,
    useAppleEndpointsSocket,
    useColumnPicker,
    useManagementApi,
    useNavigate,
} from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AppleEndpointListCommands } from '../command/AppleEndpointCommands';

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const AppleEndpointListMac = ({ filter, commandsContainerRef }) => {
    const columnPicker = useColumnPicker({
        id: 'apple-endpoint-list-mac',
        lockedColumns: ['Name'],
    });
    const { modelType } = useParams();
    const navigate = useNavigate();
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();

    const manageUserAction = {
        id: 'userManagement',
        name: 'User management',
    };
    const renameAction = {
        id: 'rename',
        name: 'Rename',
    };

    const [dimensions, setDimensions] = useState({});
    const [isManagingUser, setIsManagingUser] = useState(false);
    const groupActions = [renameAction, manageUserAction];

    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: {},
    });

    const queryBuilderRef = useRef({});

    const [loading, setLoading] = useState(true);
    const [isRenaming, setIsRenaming] = useState(false);

    const [endpointList, setEndpointList] = useState([]);

    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 50,
    });
    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });

    const [dirtyCount, setDirtyCount] = useState(0);

    useEffect(() => {
        fetchData();
    }, [sortingState, filter]);

    const fetchData = () => {
        appleApi.cancel();
        setLoading(true);
        appleApi
            .getEndpoints({
                modelType,
                filter: filter,
                sortBy: sortingState.sortBy,
                sortDirection: sortingState.sortDirection,
                pageNumber: sortingState.pageNumber,
                pageSize: sortingState.pageSize,
            })
            .then((response) => {
                pagingRef.current.totalRowCount = response.count;
                if (sortingState.pageNumber === 0) {
                    setEndpointList(response.content);
                } else {
                    setEndpointList((currentList) => [...currentList, ...response.content]);
                }
            })
            .catch((_) => {
                // ignore
            })
            .finally(() => {
                setLoading(false);
            });
        setDirtyCount(0);
    };

    useAppleEndpointsSocket(
        useCallback(
            ({ documentId, fullDocument, updateDescription }, { updateOperation, insertOperation, deleteOperation }) => {
                if (updateOperation) {
                    if (updateDescription.updatedFields.user && fullDocument.user) {
                        managementApi
                            .getUser(fullDocument.user)
                            .then((userResponse) => {
                                setEndpointList((currentList) =>
                                    currentList.map((device) => {
                                        if (device.id === documentId) {
                                            return {
                                                ...fullDocument,
                                                id: documentId,
                                                user: userResponse,
                                            };
                                        }
                                        return device;
                                    }),
                                );
                            })

                            .catch((error) => {
                                console.error('Can not find user', error);
                            });
                    } else {
                        setEndpointList((currentList) =>
                            currentList.map((device) => {
                                if (device.id === documentId) {
                                    return {
                                        ...fullDocument,
                                        id: documentId,
                                        user: fullDocument.user ? device.user : undefined,
                                    };
                                }
                                return device;
                            }),
                        );
                    }
                } else if (insertOperation) {
                    setDirtyCount((c) => c + 1);
                } else if (deleteOperation) {
                    setEndpointList((currentList) => {
                        return currentList.filter((device) => device.id !== documentId);
                    });
                }
            },
            [sortingState],
        ),
    );

    const onScrollToBottom = () => {
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: currentPagingState.pageNumber + 1,
        }));
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case manageUserAction.id:
                setIsManagingUser(true);
                break;
        }
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            closeMenu();
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName) => {
        setIsRenaming(false);
        appleApi
            .renameEndpoint(menuState.rowData._id, {
                name: newName,
            })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const forceRefresh = () => {
        setSortingState((currentPagingState) => ({ ...currentPagingState }));
    };

    const onOrderByChange = ({ sortBy, sortDirection }) => {
        Url.set(SORT_BY, sortBy);
        Url.set(SORT_DIR, sortDirection);
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: 0,
            sortBy,
            sortDirection,
        }));
    };
    return (
        <Page title="Mac Endpoints">
            <RenamingDialog
                onCancel={onRename}
                onSubmit={onRenameSubmit}
                currentName={menuState.rowData.name}
                open={isRenaming}
                // setError={setRenameError}
                // error={renameError !== null}
                // errorMessage={renameError}
            />
            {commandsContainerRef.current !== null && (
                <Portal container={commandsContainerRef.current}>
                    <AppleEndpointListCommands
                        filter={filter}
                        affectedEndpointsCount={pagingRef.current.totalRowCount}
                        isMac
                    />
                </Portal>
            )}
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Endpoint"
                title={menuState.rowData.name}
                pages={menuState.pages}
                actions={groupActions}
                onActionClick={onActionClick}
            />
            <UserManagementAssignUserToEndpoint
                open={isManagingUser}
                onSubmit={(user) => {
                    setIsManagingUser(false);
                }}
                endpointType={ENDPOINT_TYPE.APPLE}
                endpoint={menuState.rowData}
                onClose={() => setIsManagingUser(false)}
            />
            <VirtualizedTable
                entity="endpoint"
                items={endpointList}
                onResize={setDimensions}
                totalRowCount={pagingRef.current.totalRowCount}
                isLoading={loading}
                showRowCount
                sort={onOrderByChange}
                sortBy={sortingState.sortBy}
                sortDirection={sortingState.sortDirection}
                disableHeader={endpointList.length === 0}
                onScrollToBottom={onScrollToBottom}
                noRowsRenderer={() => {
                    const isSearching = filter !== '';
                    return (
                        <EmptyState
                            filter={filter}
                            isSearching={isSearching}
                            entity="endpoint"
                            description="Get started by setting up an enrollment configuration."
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            onClick={isSearching ? undefined : () => navigate.to('apple/enrollment')}
                            buttonText="Enrollment"
                        />
                    );
                }}
                actions={
                    dirtyCount > 0 && (
                        <Tooltip
                            content={`${pluralize('new endpoint', dirtyCount, true)} detected`}
                            open
                            arrow
                            theme="rose"
                        >
                            <Button
                                size={BUTTON.SMALL}
                                onClick={forceRefresh}
                            >
                                Refresh
                            </Button>
                        </Tooltip>
                    )
                }
                columnPicker={columnPicker}
            >
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="id"
                    label=""
                    disableSort
                    type="icon"
                    cellRenderer={({ rowData }) => {
                        return (
                            <IconButton
                                onClick={(e) => {
                                    setMenuState({
                                        open: true,
                                        anchorEl: e.currentTarget,
                                        rowData,
                                        pages: [
                                            {
                                                name: 'Software',
                                                url: `apple/endpoint/${rowData.id}/inventory/software`,
                                                icon: 'categoryOutlined',
                                            },
                                            {
                                                name: 'Hardware',
                                                url: `apple/endpoint/${rowData.id}/inventory/hardware`,
                                                icon: 'memory',
                                            },
                                            {
                                                name: 'Configurations',
                                                url: `apple/endpoint/${rowData.id}/configurations`,
                                                icon: 'androidConfiguration',
                                            },
                                            {
                                                name: 'Groups',
                                                url: `apple/endpoint/${rowData.id}/groups`,
                                                icon: 'androidGroup',
                                            },
                                        ],
                                    });
                                }}
                                noMargin
                            >
                                <Icon type="moreVert" />
                            </IconButton>
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="name"
                    label="Name"
                    type="string"
                    cellRenderer={({ rowData }) => {
                        return <CapaOneLink to={`apple/endpoint/${rowData.id}/dashboard`}>{rowData.name}</CapaOneLink>;
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="user"
                    label="User"
                    type="string"
                    cellRenderer={({ rowData }) => {
                        return (
                            <UserCellRenderer
                                user={rowData.user}
                                onAddUserClick={() => {
                                    setMenuState((c) => ({
                                        ...c,
                                        rowData,
                                    }));
                                    setIsManagingUser(true);
                                }}
                            />
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    maxWidth={240}
                    dataKey="lastCheckin"
                    label="Last check-in"
                    type="date"
                    cellRenderer={({ cellData }) => {
                        return cellData ? (
                            <Tooltip content={dayjs(cellData).format('DD-MM-YYYY HH:mm')}>
                                <div>
                                    <Ellipsis>{cellData ? dayjs(cellData).fromNow() : 'Never'}</Ellipsis>
                                </div>
                            </Tooltip>
                        ) : (
                            placeholderValue({ type: EPlaceholderValues.NEVER, customTooltipText: 'This endpoint has not checked in yet.' })
                        );
                    }}
                />
                <Column
                    disableSort
                    minWidth={96}
                    maxWidth={96}
                    dataKey="BatteryLevel"
                    label="Battery"
                    type="icon"
                    cellRenderer={({ rowData }) => {
                        return <BatteryLevel percent={rowData.data.HasBattery ? rowData.data.BatteryLevel * 100 : null} />;
                    }}
                />
                <Column
                    disableSort
                    minWidth={136}
                    maxWidth={160}
                    dataKey="serial_number"
                    label="Serial Number"
                    type="string"
                />
            </VirtualizedTable>
        </Page>
    );
};
export { AppleEndpointListMac };
