import { isUndefined } from '@capasystems/utils';
import React, { useEffect, useState } from 'react';

const STORAGE_KEY = 'capaone';

// export type TUseLocalStorageState = <T = any>(
//     localStorageId: string,
//     defaultValue?: T,
//     urlGetMethod?: (key: string, defaultValue: any) => any,
//     urlSetMethod?: (key: string, value: any) => void,
//     urlKey?: string | null
// ) => [T, React.Dispatch<React.SetStateAction<T>>];

export const useLocalStorageState = <T = any>(
    localStorageId: string,
    defaultValue?: T,
    urlGetMethod: (key: string, defaultValue: T | null) => any = () => null,
    urlSetMethod: (key: string, value: T) => void = () => null,
    urlKey: string | null = null,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() => {
        if (window.localStorage.getItem(STORAGE_KEY) === null) {
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
        }
        const urlValue = urlGetMethod(urlKey || localStorageId, null);
        if (urlValue !== null) {
            return urlValue;
        }
        const currentStorageKey = window.localStorage.getItem(STORAGE_KEY);
        if (!currentStorageKey) {
            return defaultValue;
        }
        const currentValue = JSON.parse(currentStorageKey)[localStorageId];
        if (isUndefined(currentValue)) {
            return defaultValue;
        }
        return currentValue;
    });

    useEffect(() => {
        const currentStorageKey = window.localStorage.getItem(STORAGE_KEY);
        if (!currentStorageKey) {
            return;
        }
        const storageObject = JSON.parse(currentStorageKey);
        storageObject[localStorageId] = value;
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(storageObject));
        urlSetMethod(urlKey || localStorageId, value);
    }, [value]);

    return [value, setValue];
};

export default useLocalStorageState;
