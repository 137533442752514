import { TOOLTIP } from '@capasystems/constants';
import { Icon, Tooltip } from '@capasystems/ui';
import { formatTimestamp } from '@capasystems/utils';
import { EPlaceholderValues } from '@thirdparty/constants';
import { placeholderValue } from '../../../index';

const AndroidApplicationDetails = ({ application }) => {
    const TooltipField = ({ children, label }) => {
        return (
            <div>
                <div className="tw-mb-1 tw-text-xs tw-font-medium tw-text-gray-200">{label}</div>
                <div className="tw-text-xs tw-font-semibold">{children || placeholderValue({ type: EPlaceholderValues.NOT_AVAILABLE })}</div>
            </div>
        );
    };

    return (
        <Tooltip
            noPadding
            dark
            position={TOOLTIP.POSITION.TOP_END}
            content={
                <div className="tw-grid tw-justify-between tw-gap-4 tw-p-4">
                    {/* <TooltipField label="Removable">{application?.data?.Attributes.Removable ? 'Yes' : 'No'}</TooltipField> */}
                    <TooltipField label="Install type">{application.installTypeName}</TooltipField>
                    <TooltipField label="Updated">
                        {formatTimestamp(application.updatedAt)}&nbsp;&middot;&nbsp;{application.updatedBy}
                    </TooltipField>
                    <TooltipField label="Created">{formatTimestamp(application.createdAt)}</TooltipField>
                </div>
            }
        >
            <Icon
                type="infoOutlined"
                className="tw-text-black/60"
            />
        </Tooltip>
    );
};

export { AndroidApplicationDetails };
