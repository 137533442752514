import { TOOLTIP } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import { Button, Collapse, Icon, LayoutCentered, LayoutRow, Loading, Page, Tab, Tabs, Tooltip, useParams } from '@capasystems/ui';
import { isDefined } from '@capasystems/utils';
import { TAndroidDeviceWithId } from '@db/party';
import { DeviceStateEnums, ENDPOINT_TYPE, ManagementModeEnums } from '@thirdparty/constants';
import { TailwindBadge } from '@thirdparty/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate as useCoreNavigate } from 'react-router-dom';
import { PageTitle, UserManagementAssignUserToEndpoint, UserTypeIcon, useAndroidApi, useAndroidEndpointSocket, useNavigate } from '../../../index';
import { AndroidEndpointCommands } from '../components/command/AndroidEndpointCommands';
import { EndpointApplicationsTab } from '../components/Endpoint/AndroidEndpointApplicationsTab';
import { EndpointDashboardTab } from '../components/Endpoint/AndroidEndpointDashboardTab';
import { EndpointGroupsTab } from '../components/Endpoint/AndroidEndpointGroupsTab';
import { AndroidConfigurationsWrapper } from '../components/Endpoint/Configuration/AndroidEndpointConfigurationsWrapper';
import { EndpointInventoryTab } from '../components/Endpoint/Inventory/AndroidEndpointInventory';
import { ResultingPolicyErrorsInformation } from './android-device-list';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

const manageUserAction = {
    id: 'manageUser',
    name: 'User management',
};

// Available device fields: https://developers.google.com/android/management/reference/rest/v1/enterprises.devices
const AndroidEndpoint = () => {
    const { deviceId, '*': splat } = useParams();
    const selectedTab = splat === '' ? 'settings' : splat?.split('/')[0];
    const [endpoint, setEndpoint] = useState<TAndroidDeviceWithId>();
    const androidApi = useAndroidApi();
    const [appliedPolicy, setAppliedPolicy] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { appendBaseURL } = useNavigate();
    const coreNavigate = useCoreNavigate();

    const onTabChange = (e: any, selectedTabId: any) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(appendBaseURL(`android/device/${deviceId}/${selectedTabId}`), '_blank');
        } else {
            coreNavigate(selectedTabId);
        }
    };

    useAndroidEndpointSocket(
        deviceId as string,
        useCallback<TSocketMethod<TAndroidDeviceWithId>>(({ fullDocument }, { updateOperation, deleteOperation }) => {
            if (updateOperation) {
                const { _id: id, ...rest } = fullDocument;
                setEndpoint((c) => ({
                    id,
                    ...rest,
                    user: c?.user,
                }));
                if (isDefined(fullDocument.resultingPolicy)) {
                    setAppliedPolicy(fullDocument.resultingPolicy);
                }
            } else if (deleteOperation) {
                coreNavigate('..');
            }
        }, [])
    );

    useEffect(() => {
        if(deviceId) {
            androidApi
                .getDevice(deviceId)
                .then((response) => {
                    setEndpoint(response);
                    const updateAppliedPolicy = (policyResponse: any) => {
                        setAppliedPolicy(policyResponse);
                        setLoading(false);
                    };

                    if (isDefined(response.resultingPolicy)) {
                        updateAppliedPolicy(response.resultingPolicy);
                    } else if (isDefined(response.androidData.appliedPolicyName)) {
                        const policyName = response.androidData.appliedPolicyName.split(`/`).pop();
                        androidApi
                            .getPolicy(policyName)
                            .then(updateAppliedPolicy)
                            .catch((policyResponseError) => {
                                console.log(policyResponseError);
                                setErrorMessage('Could not load device policy');
                            });
                    } else {
                        setErrorMessage(
                            DeviceStateEnums[response.androidData.state] === DeviceStateEnums.PROVISIONING ? DeviceStateEnums.PROVISIONING : 'No policy applied yet'
                        );
                    }
                })
                .catch((deviceResponseError) => {
                    console.log(deviceResponseError);
                    setErrorMessage('Could not find device');
                });
        }
    }, [androidApi, deviceId]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <b>{errorMessage}</b>
            </LayoutCentered>
        );
    }
    if (loading) {
        return (
            <LayoutCentered>
                <Loading></Loading>
            </LayoutCentered>
        );
    }
    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-auto-1fr tw-px-4">
            <AndroidEndpointTopbar
                endpoint={endpoint}
                setEndpoint={setEndpoint}
                setLoading={setLoading}
                inKioskMode={appliedPolicy.kioskCustomLauncherEnabled}
            />
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                onRails
            >
                <Tab
                    label="Dashboard"
                    value="dashboard"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Inventory"
                    value="inventory"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Configurations"
                    value="configurations"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Applications"
                    value="applications"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Groups"
                    value="groups"
                    disableRipple
                    className={tabClassName}
                />
            </Tabs>
            <div className="tw-overflow-auto">
                <Routes>
                    <Route
                        path="dashboard"
                        element={
                            <EndpointDashboardTab
                                endpoint={endpoint}
                                appliedPolicy={appliedPolicy}
                                inKioskMode={appliedPolicy.kioskCustomLauncherEnabled}
                            />
                        }
                    />
                    <Route path="inventory">
                        <Route
                            path=":inventoryId"
                            element={<EndpointInventoryTab endpoint={endpoint} />}
                        />
                        <Route
                            index
                            element={
                                <Navigate
                                    to="software"
                                    replace
                                />
                            }
                        />
                    </Route>

                    <Route
                        path="configurations"
                        element={<AndroidConfigurationsWrapper endpoint={endpoint} />}
                    />
                    <Route
                        path="applications"
                        element={<EndpointApplicationsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="groups"
                        element={<EndpointGroupsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="*"
                        element={
                            <LayoutCentered>
                                <PageTitle>Page not found</PageTitle>
                            </LayoutCentered>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

type AndroidEndpointTopbarProps = {
    endpoint?: TAndroidDeviceWithId;
    setEndpoint: React.Dispatch<React.SetStateAction<TAndroidDeviceWithId | undefined>>;
    setLoading: (loading: boolean) => void;
    inKioskMode: boolean;
};

const AndroidEndpointTopbar: React.FC<AndroidEndpointTopbarProps> = ({ endpoint, setEndpoint, setLoading, inKioskMode }) => {
    const [isManagingUser, setIsManagingUser] = useState(false);

    return (
        <Page title={endpoint?.androidData.hardwareInfo?.model}>
            <div className="tw-pb-3 tw-pt-4">
                <LayoutRow
                    verticalAlign="center"
                    className="tw-gap-x-6"
                >
                    <PageTitle
                        description={
                            <div>
                                {endpoint?.androidData.hardwareInfo?.model}
                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                                {ManagementModeEnums[endpoint?.androidData.managementMode]}
                            </div>
                        }
                    >
                        {endpoint?.name}
                    </PageTitle>
                    <EndpointUserInfo
                        endpoint={endpoint}
                        setIsManagingUser={setIsManagingUser}
                    />
                    {(() => {
                        if (endpoint?.androidData.state === 'LOST') {
                            return (
                                <Tooltip
                                    content="This device is currently in Lost Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                >
                                    <TailwindBadge
                                        color="red"
                                        className="tw-gap-1"
                                        size="small"
                                    >
                                        <Icon
                                            type="error"
                                            className="tw-text-lg"
                                        />
                                        <span>Lost Mode</span>
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        } else if (inKioskMode) {
                            return (
                                <Tooltip
                                    content="This device is currently in Kiosk Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                >
                                    <TailwindBadge
                                        color="cyan"
                                        className="tw-gap-1"
                                        size="small"
                                        dark
                                    >
                                        <Icon
                                            type="kioskMode"
                                            className="tw-text-lg"
                                        />
                                        <span>Kiosk Mode</span>
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        }
                    })()}
                    {/* @ts-ignore - this is not typed  */}
                    {endpoint && <AndroidEndpointCommands endpoint={endpoint} />}
                    <ResultingPolicyErrorsInformation endpoint={endpoint} />
                    <div className="tw-flex-1" />
                </LayoutRow>

                <UserManagementAssignUserToEndpoint
                    open={isManagingUser}
                    onSubmit={(user: any) => {
                        setIsManagingUser(false);
                        setEndpoint((c) => {
                            return c
                                ? {
                                      ...c,
                                      user,
                                  }
                                : undefined;
                        });
                    }}
                    endpointType={ENDPOINT_TYPE.ANDROID}
                    endpoint={endpoint}
                    onClose={() => {
                        setIsManagingUser(false);
                    }}
                />
            </div>
        </Page>
    );
};

type ClearAppDataDialogProps = {
    open: boolean;
    endpoint?: TAndroidDeviceWithId;
    onClose: () => void;
};

type EndpointUserInfoProps = {
    endpoint?: TAndroidDeviceWithId & { user?: any };
    setIsManagingUser: React.Dispatch<React.SetStateAction<boolean>>;
};

const EndpointUserInfo: React.FC<EndpointUserInfoProps> = ({ endpoint, setIsManagingUser }) => {
    const navigate = useNavigate();

    const [mouseEntered, setMouseEntered] = useState(false);

    if (endpoint?.user) {
        return (
            <div>
                <Button
                    variant="outlined"
                    className="tw-rounded-xl tw-border-slate-200 tw-bg-slate-100 tw-px-3 tw-py-2.5 hover:tw-bg-slate-200"
                    onClick={() => navigate.to(`management/user/${endpoint?.user.id}`)}
                    onMouseEnter={() => setMouseEntered(true)}
                    onMouseLeave={() => setMouseEntered(false)}
                    disableRipple
                >
                    <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-x-3 tw-text-left">
                        <div>
                            <UserTypeIcon
                                type={endpoint?.user.type}
                                className="tw-h-6 tw-w-6"
                                deleted={endpoint?.user.deleted}
                            />
                        </div>
                        <Tooltip
                            content="View user"
                            disableHoverListener={endpoint?.user.deleted}
                        >
                            <div>
                                <div className="tw-text-xs tw-font-semibold tw-leading-none">{endpoint.user.name}</div>
                                <div className="tw-mt-1 tw-block tw-text-xs tw-font-medium tw-leading-none">{endpoint.user.email}</div>
                            </div>
                        </Tooltip>
                        <div>
                            <Collapse
                                in={mouseEntered}
                                unmountOnExit
                                orientation="horizontal"
                            >
                                <Tooltip content={manageUserAction.name}>
                                    <Icon
                                        type="edit"
                                        color="inherit"
                                        className="tw-text-blue-500 hover:tw-text-sky-700"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsManagingUser(true);
                                        }}
                                    />
                                </Tooltip>
                            </Collapse>
                        </div>
                    </div>
                </Button>
            </div>
        );
    }
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                className="tw-py-2"
                noMargin
                onClick={() => {
                    setIsManagingUser(true);
                }}
            >
                <LayoutRow verticalAlign="center">
                    <Icon
                        type="addUser"
                        className="tw-mr-2 tw-text-xl"
                    />
                    <div className="tw-text-xs tw-font-bold">Assign user</div>
                </LayoutRow>
            </Button>
        </div>
    );
};

export { AndroidEndpoint };

