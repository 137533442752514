import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, Ellipsis, EmptyState, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { QUERY_BUILDER_REF_SOFTWARE_LIST } from '@thirdparty/constants';
import { CapaOneLink, QueryBuilder, TwoLineCellRenderer, WidgetPaper, useApi, useAuthContext, useColumnPicker, useNavigate } from '@thirdparty/ui';
import { convertCapapacksLanguage, convertCapapacksPlatform } from '@thirdparty/utils';
import { compareVersions } from 'compare-versions';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const UpdaterApplicationList = ({ portalContainer }) => {
    const api = useApi();
    const [software, setSoftware] = useState([]);
    const [sort, setSort] = useState({ by: 'application', direction: SORT_DIRECTION.ASC });
    const navigate = useNavigate();
    const [activeLeafs, setActiveLeafs] = useState([]);
    const queryBuilderRef = useRef({});
    const { user } = useAuthContext();

    const columnPicker = useColumnPicker({
        id: 'updater-application-list',
        lockedColumns: ['Name'],
        defaultHiddenColumns: [],
    });

    const onMissingLeafData = (leafRef, callback) => {
        leafRef.options = [...new Set(software.map(({ category }) => category))].map((name) => ({ name: name, id: name }));
        callback();
    };

    const onQueryBuilderSubmit = (leafs) => {
        setActiveLeafs(leafs);
    };

    const onRowClick = ({ rowData }) => navigate.to(`windows/updater/application/${rowData.applicationId}`);

    const onSort = ({ sortBy, sortDirection }) => {
        setSoftware([]);
        setSort({
            by: sortBy,
            direction: sortDirection,
        });
    };

    useEffect(() => {
        api.getApplications({
            orderBy: `${sort.by},${sort.direction}`,
            withTesting: user?.organization?.id === 1,
        })
            .then(({ content }) => {
                setSoftware(content?.filter((app) => app.versions.length > 0));
            })
            .catch(noop);

        return () => {
            api.cancel();
        };
    }, [api, sort]);

    const [filteredSoftware, isLoading] = useMemo(() => {
        let softwareResponse = software;
        const nameLeaf = activeLeafs.find((leaf) => leaf.id === 'name');
        const categoryLeaf = activeLeafs.find((leaf) => leaf.id === 'category');
        if (nameLeaf) {
            softwareResponse = softwareResponse.filter((s) => s.application.toLocaleLowerCase().includes(nameLeaf.value.toLocaleLowerCase()));
        }
        if (categoryLeaf) {
            softwareResponse = softwareResponse.filter((s) => categoryLeaf.value.includes(s.category));
        }
        return [softwareResponse, software.length === 0];
    }, [activeLeafs, software]);

    const getVersions = (application) => {
        const versions = {};

        application.versions.forEach((version) => {
            const platformAndLanguage = version.platform + version.language;
            if (!versions[platformAndLanguage]) {
                versions[platformAndLanguage] = { version: version.version, platform: version.platform, language: version.language };
                return;
            }

            //check if version is later
            if (compareVersions(version.version, versions[platformAndLanguage].version) > 0) {
                versions[platformAndLanguage] = { version: version.version, platform: version.platform, language: version.language };
                return;
            }
        });

        return Object.values(versions);
    };

    return (
        <>
            <Portal container={portalContainer}>
                <QueryBuilder
                    defaultConfiguration={QUERY_BUILDER_REF_SOFTWARE_LIST}
                    onInit={onQueryBuilderSubmit}
                    onSubmit={onQueryBuilderSubmit}
                    onMissingLeafData={onMissingLeafData}
                    ref={queryBuilderRef}
                    className="tw-w-72"
                />
            </Portal>
            <WidgetPaper
                className="tw-h-full"
                headerless
            >
                <VirtualizedTable
                    items={filteredSoftware}
                    totalRowCount={filteredSoftware.length}
                    isLoading={isLoading}
                    showRowCount
                    sort={onSort}
                    sortBy={sort.by}
                    sortDirection={sort.direction.toUpperCase()}
                    entity="application"
                    noRowsRenderer={() => (
                        <EmptyState
                            isSearching={activeLeafs.length > 0}
                            entity="software"
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                        />
                    )}
                    columnPicker={columnPicker}
                >
                    <Column
                        key="thumbnail"
                        dataKey="thumbnail"
                        label=""
                        minWidth={32}
                        maxWidth={32}
                        type="avatar"
                        cellRenderer={({ rowData }) => (
                            <Avatar
                                alt={rowData.application}
                                src={rowData.thumbnail}
                                variant="square"
                                className="tw-h-8 tw-w-8"
                            />
                        )}
                    />
                    <Column
                        key="application"
                        dataKey="application"
                        label="Name"
                        minWidth={240}
                        maxWidth={320}
                        locked
                        type="multiLine"
                        cellRenderer={({ cellData, rowData }) => (
                            <CapaOneLink to={`windows/updater/application/${rowData.applicationId}`}>
                                <TwoLineCellRenderer
                                    callToAction
                                    main={cellData}
                                    secondary={rowData.vendor}
                                />
                            </CapaOneLink>
                        )}
                    />
                    <Column
                        key="version"
                        dataKey="version"
                        label="Version"
                        minWidth={320}
                        maxWidth={320}
                        type="string"
                        cellRenderer={({ rowData }) => {
                            const versions = getVersions(rowData);

                            return (
                                <Tooltip
                                    disableHoverListener={versions.length <= 2}
                                    content={versions?.map((version, i) => (
                                        <div className={i > 0 ? 'tw-mt-2' : null}>
                                            {convertCapapacksPlatform(version.platform)} &#8226; {convertCapapacksLanguage(version.language)} &#8226;{' '}
                                            {version.version}
                                        </div>
                                    ))}
                                >
                                    <div className="tw-line-clamp-2">
                                        {versions?.map((version, i) => (
                                            <div
                                                key={`${version.platform}-${version.language}-${version.version}`}
                                                className={i > 0 ? 'tw-mt-2' : null}
                                            >
                                                {convertCapapacksPlatform(version.platform)} &#8226; {convertCapapacksLanguage(version.language)} &#8226;{' '}
                                                {version.version}
                                            </div>
                                        ))}
                                    </div>
                                </Tooltip>
                            );
                        }}
                    />
                    <Column
                        key="category"
                        dataKey="category"
                        label="Category"
                        minWidth={160}
                        maxWidth={160}
                        type="string"
                    />
                    <Column
                        key="description"
                        dataKey="description"
                        label="Description"
                        minWidth={320}
                        disableSort
                        type="string"
                        cellRenderer={({ cellData }) => <Ellipsis>{cellData}</Ellipsis>}
                    />
                </VirtualizedTable>
            </WidgetPaper>
        </>
    );
};

export { UpdaterApplicationList };
