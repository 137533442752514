import { BUTTON, coreMessage } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    EmptyState,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    Loading,
    SavingChanges,
    Select,
    Tooltip,
    UploadButton,
} from '@capasystems/ui';
import { PRODUCT_NAME, windowsApplication } from '@thirdparty/constants';
import {
    PageTitle,
    useApi,
    useAuthContext,
    useForceRefresh,
    useLocalStorageState,
    useNavigate,
    WidgetPaper,
    WindowsApplicationFormProgressContainer,
    WindowsApplicationThumbnail,
} from '@thirdparty/ui';
import React, { useEffect, useRef, useState } from 'react';
import 'react-resizable/css/styles.css';
import { useParams } from 'react-router-dom';
import { CreateNewCategory, PowerBrickContent, workflowItemCategories } from '../components/powerbricks/PowerBrickContent';

const releaseStatusOptions = Object.values(windowsApplication.releaseStatus);

const WindowsEditScript = () => {
    const { applicationId } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const [releaseStatus, setReleaseStatus] = useState(windowsApplication.releaseStatus.testing.id);
    const [name, setName] = useState('');
    const [version, setVersion] = useState('');
    const [description, setDescription] = useState('');
    const [recentlyUsedIcons, setRecentlyUsedIcons] = useLocalStorageState('windows.applications.customapps.recentlyUsedIcons.v1', []);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPrevDialog, setShowPrevDialog] = useState(false);
    const [powerBricks, setPowerBricks] = useState([]);

    const forceRefresh = useForceRefresh();
    const [showPowerBricksDialog, setShowPowerBricksDialog] = useState(false);
    const [applicationInstallBricks, setApplicationInstallBricks] = useState([]);
    const [applicationUninstallBricks, setApplicationUninstallBricks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveErrorMessage, setSaveErrorMessage] = useState(null);

    const handleOpenPowerBricksDialog = () => {
        setShowPowerBricksDialog(true);
    };

    const handleClosePowerBricksDialog = () => {
        setShowPowerBricksDialog(false);
    };

    const [savingChangesState, setSavingChangesState] = useState({
        loading: false,
    });

    const { permissions } = useAuthContext();

    useEffect(() => {
        if (applicationId) {
            Promise.all([api.getPowerPackWorkflowItems(), api.getApplicationConfiguration(applicationId)])
                .then(([items, applicationResponse]) => {
                    const wfItems = items.map((item) => {
                        const category = workflowItemCategories.find((c) => c.type === item.category);
                        if (category === undefined) {
                            workflowItemCategories.push(CreateNewCategory(item));
                        }
                        return {
                            ...item,
                            visuals: workflowItemCategories.find((c) => c.type === item.category),
                        };
                    });
                    setPowerBricks(wfItems);
                    setName(applicationResponse.name);
                    setReleaseStatus(applicationResponse.releaseStatus);
                    setVersion(applicationResponse.config.version);
                    setDescription(applicationResponse.description);
                    applicationRef.current.thumbnail = applicationResponse.thumbnail;
                    // applicationRef.current.icon = applicationResponse.fileInfo.icon ? `data:image/png;base64,${applicationResponse.fileInfo.icon}` : null;
                    setApplicationInstallBricks(
                        applicationResponse.install.map((brick) => {
                            const powerBrick = wfItems.find((item) => item.id === brick.id.split('_')[0]);
                            return {
                                ...brick,
                                visuals: powerBrick.visuals,
                            };
                        }),
                    );
                    setApplicationUninstallBricks(
                        applicationResponse.uninstall.map((brick) => {
                            const powerBrick = wfItems.find((item) => item.id === brick.id.split('_')[0]);
                            return {
                                ...brick,
                                visuals: powerBrick.visuals,
                            };
                        }),
                    );
                    setLoading(false);
                })
                .catch((errorResponse) => {
                    setErrorMessage('Could not load application details');
                    console.log(errorResponse);
                });
        }
    }, []);

    const applicationRef = useRef({
        thumbnail: '',
    });

    const onIconChange = (fileDetails) => {
        if (fileDetails) {
            const [icon] = fileDetails.files;
            if (icon) {
                setSavingChangesState({
                    loading: true,
                    loadingMessage: 'Processing image',
                    successMessage: 'Validated',
                });
                if (icon.size > 1024 * 1024) {
                    setTimeout(() => {
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            errorTitle: 'File is too big',
                            errorMessage: 'The maximum allowed file size is 1 MB',
                            loading: false,
                        }));
                    });
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(icon);
                    fileReader.onload = function () {
                        applicationRef.current.thumbnail = fileReader.result;
                        setRecentlyUsedIcons((currentIcons) => {
                            function onlyUnique(value, index) {
                                setShowPrevDialog(false);
                                return currentIcons.indexOf(value) === index;
                            }
                            currentIcons.unshift(fileReader.result);
                            setShowPrevDialog(false);
                            return currentIcons.filter(onlyUnique).slice(0, 12);
                        });
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            loading: false,
                        }));
                    };
                    fileReader.onerror = function (error) {
                        setSavingChangesState({
                            errorMessage: 'Could not read image',
                        });
                        console.log('Error: ', error);
                    };
                }
            }
        }
    };

    const InfoHelpIcon = ({ help }) => {
        if (help) {
            return (
                <Tooltip
                    extraPadding
                    dark
                    content={<div>{help}</div>}
                >
                    <Icon
                        type="infoOutlined"
                        className="tw-text-xl tw-text-gray-400"
                        style={{ marginLeft: '4px' }}
                    />
                </Tooltip>
            );
        }
        return null;
    };

    const saveChanges = () => {
        const regex = /['"$`/\\]/;
        if (regex.test(name)) {
            setSaveErrorMessage('Could not update application. Disallowed characters in application name');
        } else {
            setSavingChangesState({
                loading: true,
            });
            api.updateScriptApplication(applicationId, {
                name,
                description,
                thumbnail: applicationRef.current.thumbnail,
                releaseStatus,
                version,
                install: applicationInstallBricks,
                uninstall: applicationUninstallBricks,
            })
                .then(() => {
                    navigate.to('windows/application/list');
                })
                .catch((errorResponse) => {
                    console.log(errorResponse);
                    const contentType = errorResponse.headers?.get('content-type');
                    if ((contentType && contentType.includes('text/html')) || (errorResponse.data && errorResponse.data.includes('<'))) {
                        setSavingChangesState({
                            errorMessage: `Something went wrong. Please try again later or contact ${PRODUCT_NAME} support.`,
                            loading: false,
                        });
                    } else {
                        setSavingChangesState({
                            errorMessage: errorResponse.data || 'Could not update application',
                            loading: false,
                        });
                    }
                });
        }
    };

    const requiredFields = () => {
        const missingFields = [];
        if (name === '') {
            missingFields.push('Name');
        }
        if (version === '') {
            missingFields.push('Version');
        }
        if (applicationInstallBricks.length === 0 && applicationUninstallBricks.length === 0) {
            missingFields.push('PowerBricks');
        }

        if (missingFields.length === 0) {
            return null;
        }

        const tooltipMsg = 'You are required to fill out:';
        const missingFieldsElements = missingFields.map((field) => <span>{field}</span>);

        return (
            <div>
                <span>{tooltipMsg}</span>
                <br />
                {missingFieldsElements.reduce((prev, curr) => [prev, <br />, curr])}
            </div>
        );
    };

    if (errorMessage) {
        return (
            <div className="tw-m-8 tw-mx-auto tw-max-w-screen-lg tw-pb-4">
                <WidgetPaper
                    headerless
                    className="tw-h-112"
                >
                    <EmptyState
                        iconType="lightbulbOutlined"
                        title={coreMessage.anErrorOccurred}
                        description={errorMessage}
                        onClick={() => navigate.to('windows/application/list')}
                        buttonText="Back"
                    />
                </WidgetPaper>
            </div>
        );
    }

    if (loading) {
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }

    return (
        <div>
            <div className="tw-mx-auto tw-max-w-screen-2xl tw-overflow-auto tw-p-4">
                <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto-auto tw-items-center tw-gap-4">
                    <PageTitle category="Edit">Script</PageTitle>
                    <Tooltip content={requiredFields()}>
                        <div>
                            <Button
                                noMargin
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                disabled={name === '' || version === '' || (applicationInstallBricks.length === 0 && applicationUninstallBricks.length === 0)}
                                onClick={saveChanges}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Tooltip>
                    <Button
                        noMargin
                        onClick={() => navigate.to('windows/application/list')}
                    >
                        Cancel
                    </Button>
                </div>
                {saveErrorMessage !== null && (
                    <div className="tw-col-span-2 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{saveErrorMessage}</div>
                )}
                <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4">
                    <SavingChanges {...savingChangesState} />
                    <WidgetPaper
                        title="Application content"
                        headerClassName="tw-px-6"
                        headerless
                        className="tw-mb-4"
                    >
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Name</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the name of the application.</p>
                                                    <p>It's recommended to use the same name that's displayed in add/remove programs.</p>
                                                    <p>Example: "Adobe Acrobat Reader" </p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={name !== ''}
                                TooltipInfo={!(name !== '') ? 'Required' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the name of the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    light
                                    callToAction
                                    inputClassName="tw-font-semibold tw-h-12"
                                    fullWidth
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => {
                                        setErrorMessage(null);
                                        setName(e.target.value.trim());
                                    }}
                                />
                            </WindowsApplicationFormProgressContainer>
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Version</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the version of the application.</p>
                                                    <p>It's recommended to use the same version that's displayed in add/remove programs.</p>
                                                    <p>Example: "2023.006.20380"</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={version !== ''}
                                TooltipInfo={!(version !== '') ? 'Required' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the version of the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    light
                                    callToAction
                                    inputClassName="tw-font-semibold tw-h-12"
                                    fullWidth
                                    placeholder="Version"
                                    value={version}
                                    onChange={(e) => {
                                        const versionString = e.target.value.trim();
                                        setVersion(versionString);
                                    }}
                                />
                            </WindowsApplicationFormProgressContainer>
                        </div>
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>PowerBricks</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>PowerBricks are used to customize applications to your specific needs.</p>
                                                    <p> The intuitive interface helps to create even complex applications in a simple way.</p>
                                                    <p>No coding required.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                required={true}
                                valid={applicationInstallBricks.length > 0 || applicationUninstallBricks.length > 0}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>The PowerBrick editor makes it simple to customize packages.</p>
                                    </div>
                                }
                            >
                                <div className="tw-mb-4 tw-flex tw-justify-end">
                                    <Button
                                        size="medium"
                                        variant={BUTTON.OUTLINED}
                                        color={BUTTON.PRIMARY}
                                        noMargin
                                        className="tw-py-2 tw-text-xs"
                                        onClick={handleOpenPowerBricksDialog}
                                    >
                                        Open PowerBrick Editor
                                    </Button>
                                    <PowerBrickContent
                                        powerBricks={powerBricks}
                                        install={applicationInstallBricks}
                                        uninstall={applicationUninstallBricks}
                                        setInstall={(bricks) => setApplicationInstallBricks(bricks)}
                                        setUninstall={(bricks) => setApplicationUninstallBricks(bricks)}
                                        open={showPowerBricksDialog}
                                        handleClose={handleClosePowerBricksDialog}
                                    ></PowerBrickContent>
                                </div>
                            </WindowsApplicationFormProgressContainer>
                            <WindowsApplicationFormProgressContainer
                                label="Description"
                                valid={description !== ''}
                                required={false}
                                TooltipInfo={!(description !== '') ? 'Optional' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the description of the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    multiline
                                    rows={2}
                                    fullWidth
                                    rowsMax={5}
                                    callToAction
                                    light
                                    inputClassName="tw-px-4 tw-py-2 tw-font-medium"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </WindowsApplicationFormProgressContainer>
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Icon</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>The application icon is used in CapaOne to easily identify applications.</p>
                                                    <p>It won't have any effect on installed applications on endpoints.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={applicationRef.current.thumbnail}
                                required={false}
                                TooltipInfo={!applicationRef.current.thumbnail ? 'Optional' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the application icon that will be displayed in CapaOne.</p>
                                    </div>
                                }
                            >
                                <div className="tw-grid tw-grid-cols-1fr-auto-auto tw-items-center tw-justify-items-end tw-gap-2">
                                    <div>
                                        {recentlyUsedIcons.length > 0 && (
                                            <Dialog
                                                open={showPrevDialog}
                                                onClose={() => setShowPrevDialog(false)}
                                            >
                                                <DialogTitle>Recently used icons</DialogTitle>
                                                <DialogContent className="tw-min-h-48">
                                                    <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-gap-y-5 tw-pt-3">
                                                        {recentlyUsedIcons.map((src, index) => (
                                                            <div
                                                                className="tw-relative tw-h-11 tw-w-11 tw-justify-self-center"
                                                                key={index}
                                                            >
                                                                <IconButton
                                                                    className="tw-absolute tw-bottom-8 tw-left-8 tw-z-10"
                                                                    variant={BUTTON.RAISED}
                                                                    color={BUTTON.DANGER}
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setRecentlyUsedIcons((c) => {
                                                                            return c.filter((src, i) => i !== index);
                                                                        });
                                                                        forceRefresh();
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        type="remove"
                                                                        size="small"
                                                                        className="tw-h-3 tw-w-3"
                                                                    />
                                                                </IconButton>
                                                                <Avatar
                                                                    src={src}
                                                                    alt={applicationRef.current.name}
                                                                    variant="square"
                                                                    className="tw-h-10 tw-w-10 tw-cursor-pointer"
                                                                    onClick={() => {
                                                                        applicationRef.current.thumbnail = src;
                                                                        forceRefresh();
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <UploadButton
                                                        color={BUTTON.PRIMARY}
                                                        htmlId="upload-icon-button"
                                                        accept="image/*"
                                                        onChange={onIconChange}
                                                        noMargin
                                                        hideSelectedFiles
                                                        multiple={false}
                                                    >
                                                        Upload new
                                                    </UploadButton>
                                                    <Button
                                                        onClick={() => setShowPrevDialog(false)}
                                                        color={BUTTON.PRIMARY}
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                    </div>

                                    {applicationRef.current.thumbnail ? (
                                        <div className="tw-group tw-relative">
                                            <IconButton
                                                className="tw-absolute tw-bottom-8 tw-left-8 tw-z-10 tw-opacity-0 tw-transition-opacity tw-duration-500 tw-ease-in-out hover:tw-scale-110 group-hover:tw-opacity-100"
                                                variant={BUTTON.RAISED}
                                                color={BUTTON.DANGER}
                                                size={BUTTON.SMALL}
                                                onClick={() => {
                                                    applicationRef.current.thumbnail = null;
                                                    forceRefresh();
                                                }}
                                            >
                                                <Icon
                                                    type="remove"
                                                    size="small"
                                                />
                                            </IconButton>
                                            <WindowsApplicationThumbnail
                                                application={applicationRef.current}
                                                size="xl"
                                                onClick={() => {
                                                    setShowPrevDialog(true);
                                                }}
                                                className={`tw-cursor-pointer`}
                                            />
                                        </div>
                                    ) : recentlyUsedIcons.length > 0 ? (
                                        <Button>
                                            <WindowsApplicationThumbnail
                                                application={{
                                                    thumbnail: applicationRef.current.thumbnail,
                                                    name,
                                                }}
                                                size="xl"
                                                onClick={() => {
                                                    setShowPrevDialog(true);
                                                }}
                                            />
                                        </Button>
                                    ) : (
                                        <UploadButton
                                            className="tw-h-12 tw-w-12"
                                            color={BUTTON.PRIMARY}
                                            htmlId="upload-icon-button"
                                            accept="image/*"
                                            onChange={onIconChange}
                                            noMargin
                                            hideSelectedFiles
                                            multiple={false}
                                        >
                                            <WindowsApplicationThumbnail
                                                application={{ thumbnail: applicationRef.current.thumbnail, name }}
                                                size="xl"
                                                onClick={() => {
                                                    setShowPrevDialog(true);
                                                }}
                                            />
                                        </UploadButton>
                                    )}
                                </div>
                            </WindowsApplicationFormProgressContainer>
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Release status</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the release status of the application.</p>
                                                    <p>It's used to clearly indicate if an application has been thoroughly tested and released.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid
                                hideConnector
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the release status of the application.</p>
                                    </div>
                                }
                            >
                                <Select
                                    options={releaseStatusOptions}
                                    selectedOptions={releaseStatusOptions.filter((o) => o.id === releaseStatus)}
                                    required
                                    callToAction
                                    onChange={([{ id }]) => setReleaseStatus(id)}
                                    inputClassName="tw-font-semibold tw-h-12"
                                    light
                                />
                            </WindowsApplicationFormProgressContainer>
                        </div>
                    </WidgetPaper>
                </div>
            </div>
        </div>
    );
};

export default WindowsEditScript;
