import { SystemUpdateEnum } from '../android-management-enums';
import { userFacingMessageProperties } from '../core-android-management-constants';

export const AndroidSystemConfiguration = {
    schema: {
        title: 'System',
        category: 'System',
        type: 'object',
        schemaId: 'systemConfiguration',
        properties: {
            minimumApiLevel: {
                title: 'Minimum Api Level',
                description: 'The minimum allowed Android API level.',
                type: 'number',
            },
            frpAdminEmails: {
                title: 'FRP Admin Emails',
                description:
                    'Email addresses of device administrators for factory reset protection. When the device is factory reset, it will require one of these admins to log in with the Google account email and password to unlock the device.',
                help: "Note: If no admins are specified, the device won't provide factory reset protection.",
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            autoDateAndTimeZone: {
                title: 'Auto Date And Time Zone',
                description: 'Whether auto date, time, and time zone are enabled on a company-owned device.',
                help: "Note: If this is set, then 'Auto Time Required' is ignored.",
                type: 'string',
                options: [
                    {
                        id: 'AUTO_DATE_AND_TIME_ZONE_USER_CHOICE',
                        name: 'User choice',
                    },
                    {
                        id: 'AUTO_DATE_AND_TIME_ZONE_ENFORCED',
                        name: 'Enforce auto date and time zone',
                    },
                ],
            },
            systemUpdate: {
                title: 'System Update',
                description: 'The system update policy, which controls how OS updates are applied.',
                help: "Note: If the update type is 'Install automatically within daily maintenance window', the update window will automatically apply to Play app updates as well.",
                type: 'object',
                properties: {
                    type: {
                        title: 'Type',
                        description: 'The type of system update to configure.',
                        type: 'string',
                        options: Object.entries(SystemUpdateEnum).map(([id, name]) => ({ id, name })),
                    },
                    startMinutes: {
                        title: 'Start Minutes',
                        description:
                            "If the type is 'Install automatically within daily maintenance window', the start of the maintenance window, measured as the number of minutes after midnight in the device's local time.",
                        help: 'Note: This value must be between 0 and 1439, inclusive.',
                        type: 'number',
                    },
                    endMinutes: {
                        title: 'End Minutes',
                        description:
                            "If the type is 'Install automatically within daily maintenance window', the end of the maintenance window, measured as the number of minutes after midnight in device's local time. This value must be between 0 and 1439, inclusive. If this value is less than startMinutes, then the maintenance window spans midnight. If the maintenance window specified is smaller than 30 minutes, the actual window is extended to 30 minutes beyond the start time.",
                        help: 'Note: This value must be between 0 and 1439, inclusive. If this value is less than startMinutes, then the maintenance window spans midnight. If the maintenance window specified is smaller than 30 minutes, the actual window is extended to 30 minutes beyond the start time.',
                        type: 'number',
                    },
                    freezePeriods: {
                        title: 'Freeze Periods',
                        description:
                            'An annually repeating time period in which over-the-air (OTA) system updates are postponed to freeze the OS version running on a device.',
                        help: 'Note: To prevent freezing the device indefinitely, each freeze period must be separated by at least 60 days.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                startDate: {
                                    title: 'Start Date',
                                    description: 'The start date (inclusive) of the freeze period.',
                                    help: 'Note: Year must not be set.',
                                    type: 'object',
                                    properties: {
                                        year: {
                                            title: 'Year',
                                            description: 'Year of the date.',
                                            help: 'Note: Must be from 1 to 9999, or 0 to specify a date without a year.',
                                            type: 'number',
                                        },
                                        month: {
                                            title: 'Month',
                                            description: 'Month of a year.',
                                            help: 'Note: Must be from 1 to 12, or 0 to specify a year without a month and day.',
                                            type: 'number',
                                        },
                                        day: {
                                            title: 'Day',
                                            description: 'Day of a month.',
                                            help: "Note: Must be from 1 to 31 and valid for the year and month, or 0 to specify a year by itself or a year and month where the day isn't significant.",
                                            type: 'number',
                                        },
                                    },
                                },
                                endDate: {
                                    title: 'End Date',
                                    description: 'The end date (inclusive) of the freeze period.',
                                    help: 'Note: Must be no later than 90 days from the start date. If the end date is earlier than the start date, the freeze period is considered wrapping year-end. Note: year must not be set.',
                                    type: 'object',
                                    properties: {
                                        year: {
                                            title: 'Year',
                                            description: 'Year of the date.',
                                            help: 'Note: Must be from 1 to 9999, or 0 to specify a date without a year.',
                                            type: 'number',
                                        },
                                        month: {
                                            title: 'Month',
                                            description: 'Month of a year.',
                                            help: 'Note: Must be from 1 to 12, or 0 to specify a year without a month and day.',
                                            type: 'number',
                                        },
                                        day: {
                                            title: 'Day',
                                            description: 'Day of a month.',
                                            help: "Note: Must be from 1 to 31 and valid for the year and month, or 0 to specify a year by itself or a year and month where the day isn't significant.",
                                            type: 'number',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
            shortSupportMessage: {
                title: 'Short Support Message',
                description: 'A message displayed to the user in the settings screen wherever functionality has been disabled by the admin.',
                help: 'Note: If the message is longer than 200 characters it may be truncated.',
                type: 'object',
                properties: userFacingMessageProperties,
            },
            longSupportMessage: {
                title: 'Long Support Message',
                description: 'A message displayed to the user in the device administrators settings screen.',
                type: 'object',
                properties: userFacingMessageProperties,
            },
            deviceOwnerLockScreenInfo: {
                title: 'Device Owner Lock Screen info',
                description: 'The device owner information to be shown on the lock screen.',
                type: 'object',
                properties: userFacingMessageProperties,
            },
            displaySettings: {
                title: 'Display Settings',
                description: 'Settings related to screen brightness and screen timeout.',
                type: 'object',
                properties: {
                    screenBrightnessSettings: {
                        title: 'Screen Brightness Settings',
                        description: 'Configuration of screen brightness settings.',
                        type: 'object',
                        properties: {
                            screenBrightnessMode: {
                                title: 'Screen Brightness Mode',
                                description: 'Mode for screen brightness adjustment.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'BRIGHTNESS_USER_CHOICE',
                                        name: 'User Choice',
                                    },
                                    {
                                        id: 'BRIGHTNESS_AUTOMATIC',
                                        name: 'Automatic (Brightness auto-adjusted)',
                                    },
                                    {
                                        id: 'BRIGHTNESS_FIXED',
                                        name: 'Fixed (Brightness set to specific level)',
                                    },
                                ],
                            },
                            screenBrightness: {
                                title: 'Screen Brightness',
                                description:
                                    "The screen brightness between 1 and 255 where 1 is the lowest and 255 is the highest brightness.\n\n<i>Note: Applicable when the Screen Brightness Mode is set to 'Fixed' or 'Automatic'.</i>",
                                type: 'number',
                                required: (context: any) => {
                                    const screenBrightnessMode = context?.displaySettings?.screenBrightnessSettings?.screenBrightnessMode;

                                    if (screenBrightnessMode === 'BRIGHTNESS_FIXED') {
                                        return true;
                                    }

                                    return false;
                                },
                                shouldRender: (siblings: any) => {
                                    const screenBrightnessMode = siblings.screenBrightnessMode;

                                    if (screenBrightnessMode === 'BRIGHTNESS_FIXED' || screenBrightnessMode === 'BRIGHTNESS_AUTOMATIC') {
                                        return true;
                                    } else {
                                        delete siblings.screenBrightness;
                                        return false;
                                    }
                                },
                                validator: (context: any) => {
                                    const screenBrightnessMode = context?.displaySettings?.screenBrightnessSettings?.screenBrightnessMode;
                                    const screenBrightness = context?.displaySettings?.screenBrightnessSettings?.screenBrightness;

                                    const isRequired = screenBrightnessMode === 'BRIGHTNESS_FIXED';
                                    const isBlank = screenBrightness === null || screenBrightness === undefined;

                                    if (isRequired && isBlank) {
                                        return { valid: false };
                                    }

                                    if (!isBlank) {
                                        if (screenBrightness < 1) {
                                            return { valid: false, message: 'Please enter a value of at least 1.' };
                                        }
                                        if (screenBrightness > 255) {
                                            return { valid: false, message: 'Please enter a value no greater than 255.' };
                                        }
                                    }

                                    // Return true when validation passes
                                    return { valid: true };
                                },
                            },
                        },
                    },
                    screenTimeoutSettings: {
                        title: 'Screen Timeout Settings',
                        description: 'Configuration of screen timeout settings.',
                        type: 'object',
                        properties: {
                            screenTimeoutMode: {
                                title: 'Screen Timeout Mode',
                                description: 'Mode for screen timeout configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'SCREEN_TIMEOUT_USER_CHOICE',
                                        name: 'User Choice',
                                    },
                                    {
                                        id: 'SCREEN_TIMEOUT_ENFORCED',
                                        name: 'Enforced (Timeout set to specific value)',
                                    },
                                ],
                            },
                            screenTimeout: {
                                title: 'Screen Timeout',
                                description:
                                    "The duration of screen timeout in seconds, ending with 's' (e.g., '3.5s' for 3.5 seconds). Must be greater than 0.\n\n<i>Note: Applicable when the Screen Timeout Mode is set to 'Enforced'.\nIf 'Maximum Time To Lock' is configured under Security configuration, the screen timeout should not exceed its value.</i>",
                                type: 'string',
                                required: true,
                                shouldRender: (siblings: any) => {
                                    if (siblings.screenTimeoutMode === 'SCREEN_TIMEOUT_ENFORCED') {
                                        return true;
                                    } else {
                                        delete siblings.screenTimeout;
                                        return false;
                                    }
                                },
                                validator: (context: any) => {
                                    const screenTimeoutMode = context?.displaySettings?.screenTimeoutSettings?.screenTimeoutMode;
                                    const screenTimeout = context?.displaySettings?.screenTimeoutSettings?.screenTimeout;

                                    if (screenTimeoutMode === 'SCREEN_TIMEOUT_ENFORCED') {
                                        if (!screenTimeout || screenTimeout.trim() === '') {
                                            return { valid: false };
                                        }

                                        // Ensure the value matches the required format: a number followed by 's'
                                        const timeoutRegex = /^[0-9]+(\.[0-9]+)?s$/;
                                        if (!timeoutRegex.test(screenTimeout)) {
                                            return { valid: false, message: "Value must be a positive number followed by 's' (e.g., '3s', '3.5s')." };
                                        }

                                        // Extract the numeric part of the value and ensure it is greater than 0
                                        const numericValue = parseFloat(screenTimeout.slice(0, -1)); // Remove the trailing 's'
                                        if (numericValue <= 0) {
                                            return { valid: false, message: 'The timeout value must be greater than 0.' };
                                        }
                                    }

                                    // Return true when validation passes
                                    return { valid: true };
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
