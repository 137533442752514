import { BUTTON, coreMessage } from '@capasystems/constants';
import {
    Button,
    CopyTextButton,
    Divider,
    Drawer,
    EmptyState,
    Grid,
    Icon,
    IconButton,
    LayoutCenter,
    LayoutCentered,
    Link,
    Loading,
    Page,
    Select,
    Switch,
    Tab,
    Tabs,
} from '@capasystems/ui';
import { Url, api as baseApi, formatTimestamp, noop } from '@capasystems/utils';
import {
    ActionsDialog,
    ConfirmDialog,
    CoreContext,
    DrawerStepRow,
    InfoIcon,
    PageTitle,
    QueryBuilder,
    WidgetPaper,
    useApi,
    useAuthContext,
    useManagementApi,
    useNavigate,
} from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Configuration name' });

const viewAction = {
    id: 'view',
    name: 'View configuration',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const enrollmentConfigurationActions = [viewAction, deleteAction];

const DOWLOAD_URL_IDENTIFIER = '{downloadUrl}';
const AGENT_GRPC_UL_IDENTIFIER = '{agentGrpcUrl}';
const ORG_KEY_IDENTIFIER = '{orgkey}';
const FILE_NAME_IDENTIFIER = '{fileName}';
const TAGS_IDENTIFIER = '{tags}';
const TAGS_WRAPPER = ` TAGS='"${TAGS_IDENTIFIER}"' `; /* Do not remove the start and end spaces. */
const GROUPS_IDENTIFIER = '{groups}';
const GROUPS_WRAPPER = ` GROUPS='"${GROUPS_IDENTIFIER}"' `; /* Do not remove the start and end spaces. */
const ENROLLMENT_INDENTIFIER = '{enrollment}';
const ENROLLMENT_WRAPPER = ` ENROLLMENT_ID="${ENROLLMENT_INDENTIFIER}" `;
const CLEAN_RELATIONS_IDENTIFIER = '{cleanRelations}';

const WindowsEnrollment = () => {
    const api = useApi();
    const navigate = useNavigate();

    const [selectedConfiguration, setSelectedConfiguration] = useState({
        extraFields: {},
        data: {},
    });
    const [entities, setEntities] = useState({
        groups: [],
        configurations: [],
        applications: [],
        initialized: false,
    });
    const [showDrawer, setShowDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteEnrollmentConfigurationState, setDeleteEnrollmentConfigurationState] = useState({
        open: false,
        isProcessing: false,
        failed: false,
    });

    const [loading, setLoading] = useState(true);
    const [configurationList, setConfigurationList] = useState([]);

    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        configuration: {
            name: '',
        },
    });
    const [configurationMarkedForDelete, setConfigurationMarkedForDelete] = useState({ id: null });
    const queryBuilderRef = useRef({});

    const fetchData = () => {
        api.getEnrollmentConfiguration()
            .then((data) => {
                setConfigurationList(data);
                const enrollmentId = Url.getString('enrollmentId');
                if (enrollmentId !== null) {
                    openDrawer(data.filter((enrollment) => enrollment.id === enrollmentId)[0]);
                }
            })
            .catch(noop)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const closeDeleteDialog = () => {
        setConfigurationMarkedForDelete((c) => ({ ...c, id: null }));
    };

    const onConfirmDelete = () => {
        // setConfigurationMarkedForDelete(c => ({ ...c, isProcessing: true, anErrorOccurred: false }));
        // useEffect(() => {
        //     //something with deleteEnrollment
        // })
        // androidApi.deleteAndroidConfiguration(configurationMarkedForDelete.id).then(() => {
        //     setConfigurationList(c => {
        //         return c.filter(configuration => configuration.id !== configurationMarkedForDelete.id);
        //     });
        //     closeDeleteDialog();
        // }).catch(() => {
        //     setConfigurationMarkedForDelete(c => ({ ...c, isProcessing: false, anErrorOccurred: true }));
        // });
    };

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const openDrawer = (conf) => {
        setSelectedConfiguration(conf);
        setShowDrawer(true);
    };

    const deleteEnrollmentConfiguration = (event, requestedFromDashboard = false) => {
        setShowDrawer(false);
        Url.set('enrollmentId', null);
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: false,
            failed: false,
            requestedFromDashboard,
        });
    };

    const onCancelDeleteEnrollmentConfiguration = () => {
        if (!deleteEnrollmentConfigurationState.requestedFromDashboard) {
            setShowDrawer(true);
        }
        setDeleteEnrollmentConfigurationState({
            open: false,
            isProcessing: false,
            failed: false,
        });
    };

    const onConfirmDeleteEnrollmentConfiguration = () => {
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: true,
            failed: false,
        });
        api.deleteEnrollment(selectedConfiguration.id)
            .then(() => {
                fetchData();
                setDeleteEnrollmentConfigurationState({
                    open: false,
                    isProcessing: true,
                    failed: false,
                });
            })
            .catch(() => {
                setDeleteEnrollmentConfigurationState({
                    open: true,
                    isProcessing: false,
                    failed: true,
                });
            });
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case viewAction.id:
                openDrawer(menuState.configuration);
                break;
            case deleteAction.id:
                setSelectedConfiguration(menuState.configuration);
                deleteEnrollmentConfiguration(null, true);
                break;
        }
    };

    const memoizedConfigurationList = useMemo(() => {
        if (searchTerm) {
            return configurationList
                .filter((c) => c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                .sort((a, b) =>
                    a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase() ? -1 : 0,
                );
        }
        return configurationList.sort((a, b) =>
            a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase() ? -1 : 0,
        );
    }, [searchTerm, configurationList]);

    if (loading) {
        return (
            <LayoutCenter>
                <Loading></Loading>
            </LayoutCenter>
        );
    }

    if (configurationList.length === 0) {
        return (
            <div className="tw-m-auto tw-h-full">
                <EmptyState
                    entity="Enrollment configuration"
                    onClick={() => navigate.to('windows/enrollment/create')}
                    description="An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices"
                />
            </div>
        );
    }

    return (
        <>
            <ConfirmDialog
                onConfirm={onConfirmDelete}
                onCancel={closeDeleteDialog}
                open={configurationMarkedForDelete.id !== null}
                title={`Delete ${configurationMarkedForDelete.name}?`}
                isProcessing={configurationMarkedForDelete.isProcessing}
            >
                {configurationMarkedForDelete.anErrorOccurred && (
                    <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{configurationMarkedForDelete.name}". Please try again.</b>
                )}
            </ConfirmDialog>
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Endrollment configuration"
                title={menuState.configuration.name}
                pages={menuState.pages}
                actions={enrollmentConfigurationActions}
                onActionClick={onActionClick}
            />
            <ConfirmDialog
                onConfirm={onConfirmDeleteEnrollmentConfiguration}
                onCancel={onCancelDeleteEnrollmentConfiguration}
                title={`Delete ${selectedConfiguration.name}?`}
                isProcessing={deleteEnrollmentConfigurationState.isProcessing}
                open={deleteEnrollmentConfigurationState.open}
            >
                {deleteEnrollmentConfigurationState.failed && (
                    <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{selectedConfiguration.name}". Please try again.</b>
                )}
            </ConfirmDialog>

            <Drawer
                open={showDrawer}
                anchor="right"
                PaperProps={{
                    style: {
                        minWidth: '20vw',
                        maxWidth: '100vw',
                        width: 860,
                    },
                }}
                onClose={() => {
                    setShowDrawer(false);
                    Url.set('enrollmentId', null);
                }}
            >
                <div className="tw-grid tw-h-full tw-grid-rows-auto-auto-1fr">
                    <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4 tw-px-4 tw-pb-2 tw-pt-4">
                        <div>
                            <PageTitle
                                category="Enrollment configuration"
                                description={selectedConfiguration.description}
                            >
                                {selectedConfiguration.name}
                            </PageTitle>
                        </div>
                        <div>
                            <Button
                                noMargin
                                variant={BUTTON.TEXT}
                                onClick={() => navigate.to(`windows/enrollment/${selectedConfiguration.id}/edit`)}
                            >
                                Edit
                            </Button>
                            <Button
                                noMargin
                                color={BUTTON.DANGER}
                                variant={selectedConfiguration.expired ? BUTTON.RAISED : BUTTON.TEXT}
                                onClick={deleteEnrollmentConfiguration}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                    <SelectedConfigurationContent
                        selectedConfiguration={selectedConfiguration}
                        entities={entities}
                    />
                </div>
            </Drawer>
            <Page title="Enrollment">
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                    <div className="tw-flex tw-justify-end">
                        <QueryBuilder
                            defaultConfiguration={queryBuilderConfiguration}
                            onInit={onSubmit}
                            onSubmit={onSubmit}
                            className="tw-mr-4 tw-w-64"
                            ref={queryBuilderRef}
                        />
                        <Button
                            noMargin
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                            onClick={() => navigate.to('windows/enrollment/create')}
                        >
                            New
                        </Button>
                    </div>
                    {memoizedConfigurationList.length === 0 ? (
                        <WidgetPaper headerless>
                            <EmptyState
                                entity="Enrollment configuration"
                                isSearching={searchTerm !== ''}
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                onClick={() => navigate.to('windows/enrollment/create')}
                            />
                        </WidgetPaper>
                    ) : (
                        <div className="tw-overflow-auto">
                            <Grid
                                container
                                spacing={2}
                            >
                                {memoizedConfigurationList.map((configuration) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={4}
                                            key={configuration.id}
                                        >
                                            <WidgetPaper
                                                className="tw-h-full tw-overflow-auto"
                                                title={configuration.name}
                                                description={configuration.description || <i className="tw-opacity-25">No description available</i>}
                                                truncateHeaderElements
                                                actions={
                                                    <div className="tw-w-16">
                                                        <InfoIcon
                                                            content={[
                                                                { label: coreMessage.createdAt, value: formatTimestamp(configuration.createdAt) },
                                                                { label: coreMessage.updatedAt, value: formatTimestamp(configuration.updatedAt) },
                                                            ]}
                                                        />
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setMenuState({
                                                                    open: true,
                                                                    anchorEl: e.currentTarget,
                                                                    configuration,
                                                                    pages: [
                                                                        {
                                                                            name: 'Edit',
                                                                            icon: 'editOutlined',
                                                                            url: `windows/enrollment/${configuration.id}/edit`,
                                                                        },
                                                                        {
                                                                            name: 'Duplicate',
                                                                            icon: 'clone',
                                                                            url: `windows/enrollment/create?duplicateId=${configuration.id}`,
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                            noMargin
                                                        >
                                                            <Icon type="moreVert" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <Divider light />
                                                    <div
                                                        className={classNames({
                                                            'tw-grid tw-bg-slate-50/25 tw-text-slate-900': true,
                                                            'tw-grid-cols-1fr-auto-1fr-auto-1fr': true,
                                                        })}
                                                    >
                                                        <div className="tw-p-4">
                                                            <LayoutCentered direction="column">
                                                                <div className="tw-text-2xl tw-font-semibold">{configuration.groupRefIds?.length}</div>
                                                                <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                                    {pluralize('Group', configuration.groupRefIds?.length)}
                                                                </div>
                                                            </LayoutCentered>
                                                        </div>
                                                        <Divider
                                                            orientation="vertical"
                                                            light
                                                        />
                                                        <div className="tw-p-4">
                                                            <LayoutCentered direction="column">
                                                                <div className="tw-text-2xl tw-font-semibold">{configuration.configurationRefIds?.length}</div>
                                                                <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                                    {pluralize('Configuration', configuration.configurationRefIds?.length)}
                                                                </div>
                                                            </LayoutCentered>
                                                        </div>
                                                        <Divider
                                                            orientation="vertical"
                                                            light
                                                        />
                                                        <div className="tw-p-4">
                                                            <LayoutCentered direction="column">
                                                                <div className="tw-text-2xl tw-font-semibold">{configuration.tagRefIds?.length}</div>
                                                                <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                                    {pluralize('Tag', configuration.tagRefIds?.length)}
                                                                </div>
                                                            </LayoutCentered>
                                                        </div>
                                                    </div>
                                                    <Divider light />
                                                    <Button
                                                        fullWidth
                                                        noMargin
                                                        onClick={() => openDrawer(configuration)}
                                                        size="large"
                                                        color={BUTTON.PRIMARY}
                                                        className="tw-rounded-none tw-py-4 tw-text-xs"
                                                    >
                                                        View
                                                        <Icon
                                                            type="arrowRight"
                                                            size="small"
                                                            className="tw-ml-2"
                                                        />
                                                    </Button>
                                                </div>
                                            </WidgetPaper>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    )}
                </div>
            </Page>
        </>
    );
};

export const SelectedConfigurationContent = ({ selectedConfiguration, entities }) => {
    const { showBetaFlag } = useContext(CoreContext);
    const { selectedOrganization } = useAuthContext();
    const api = useApi();
    const managementApi = useManagementApi();
    const [selectedTab, setSelectedTab] = useState('simple');
    const [organization, setOrganization] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [configurations, setConfigurations] = useState([]);
    const [selectedConfigurations, setSelectedConfigurations] = useState([]);
    const [tagSearchTerm, setTagSearchTerm] = useState('');
    const [groupSearchTerm, setGroupSearchTerm] = useState('');
    const [configSearchTerm, setConfigSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [cleanRelations, setCleanRelations] = useState(true);
    const [osType, setOsType] = useState({
        name: 'Windows',
        description: '64-bit versions',
        selected: true,
        icon: 'windows',
        downloadKey: 'win-x64',
        isPowerShellCommand: true,
        installCommand: `$ProgressPreference='SilentlyContinue';Invoke-WebRequest -Uri ${DOWLOAD_URL_IDENTIFIER} -OutFile ${FILE_NAME_IDENTIFIER}; msiexec /i ${FILE_NAME_IDENTIFIER} ENDPOINT_URL="${AGENT_GRPC_UL_IDENTIFIER}" ORG_KEY="${ORG_KEY_IDENTIFIER}"${ENROLLMENT_INDENTIFIER}${CLEAN_RELATIONS_IDENTIFIER}/l*v install.log /qn`,
    });

    const agentUrl = useRef('');

    useEffect(() => {
        setLoading(true);
        Promise.all([managementApi.getGroups(), api.getConfigurations(), api.getTags(), api.getOrganizations()])
            .then(([groupResponse, configurationResponse, tagResponse, orgResponse]) => {
                setGroups(groupResponse);
                setSelectedGroups(groupResponse.filter((group) => selectedConfiguration.groupRefIds.includes(group.id)));
                setTags(tagResponse.content);
                setSelectedTags(tagResponse.content.filter((tag) => selectedConfiguration.tagRefIds.includes(tag.id)));
                setConfigurations(configurationResponse.content);
                setSelectedConfigurations(
                    configurationResponse.content.filter((configurations) => selectedConfiguration.configurationRefIds.includes(configurations.id)),
                );
                agentUrl.current = orgResponse.agentGrpcUrl;
                setOsType((current) => {
                    const downloadUrl = orgResponse.download[current.downloadKey] || '';
                    const fileName = downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1);
                    return {
                        ...current,
                        installCommand: current.installCommand
                            .replace(AGENT_GRPC_UL_IDENTIFIER, orgResponse.agentGrpcUrl)
                            .replace(DOWLOAD_URL_IDENTIFIER, downloadUrl)
                            .replaceAll(FILE_NAME_IDENTIFIER, fileName),
                    };
                });
                setOrganization(orgResponse.organizations.filter((org) => org.id === selectedOrganization.id));
            })
            .catch(noop)
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let command = '';
    let TAGS_IDENTIFIER_REPLACER = ' ';
    let GROUPS_IDENTIFIER_REPLACER = ' ';
    let CLEAN_RELATIONS_REPLACER = '';
    let ENROLLMENT_INDENTIFIER_REPLACER = selectedConfiguration.id;
    if (selectedTags.length > 0) {
        const joinedTags = selectedTags.map(({ name }) => name).join(';');
        TAGS_IDENTIFIER_REPLACER = TAGS_WRAPPER.replace(TAGS_IDENTIFIER, joinedTags);
    }
    if (selectedGroups.length > 0) {
        const joinedGroups = selectedGroups.map(({ name }) => name).join(';');
        GROUPS_IDENTIFIER_REPLACER = GROUPS_WRAPPER.replace(GROUPS_IDENTIFIER, joinedGroups);
    }
    if (cleanRelations) {
        CLEAN_RELATIONS_REPLACER = 'CLEAN_RELATIONS="true" ';
    }
    command = osType.installCommand
        .replace(ORG_KEY_IDENTIFIER, organization[0]?.orgKey)
        .replace(TAGS_IDENTIFIER, TAGS_IDENTIFIER_REPLACER)
        .replace(GROUPS_IDENTIFIER, GROUPS_IDENTIFIER_REPLACER)
        .replace(ENROLLMENT_INDENTIFIER, ENROLLMENT_WRAPPER.replace(ENROLLMENT_INDENTIFIER, ENROLLMENT_INDENTIFIER_REPLACER))
        .replace(CLEAN_RELATIONS_IDENTIFIER, CLEAN_RELATIONS_REPLACER);

    const downloadEnrollmentConfiguration = () => {
        const blob = new Blob([command], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'Install_CapaOne_Agent.ps1';
        link.href = url;
        link.click();
    };

    const complianceCheckPSScript =
        "$Win32Product= Get-WmiObject -Class Win32_Product | Where Name -like 'CapaOne Agent' \n" +
        'if ($Win32Product -eq $null){$CapaOneAgentInstalled=$false}else{$CapaOneAgentInstalled=$true} \n' +
        '$Hash = @{ CapaOneAgentInstalled = $CapaOneAgentInstalled } \n' +
        'Return $Hash | ConvertTo-Json -Compress';

    const downloadComplianceCheckPowerShell = () => {
        const blob = new Blob([complianceCheckPSScript], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'CapaOne_Agent_Installed.ps1';
        link.href = url;
        link.click();
    };

    const complianceCheckJSONScript = {
        Rules: [
            {
                SettingName: 'CapaOneAgentInstalled',
                Operator: 'IsEquals',
                DataType: 'Boolean',
                Operand: true,
                MoreInfoUrl: 'https://www.capasystems.com',
                RemediationStrings: [
                    {
                        Language: 'en_US',
                        Title: 'CapaOneAgentInstalled',
                        Description: 'The CapaOne Agent must be installed to be compliant',
                    },
                ],
            },
        ],
    };

    const downloadComplianceCheckJson = () => {
        const fileData = JSON.stringify(complianceCheckJSONScript);
        const blob = new Blob([fileData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'CapaOne_Agent_Installed.json';
        link.href = url;
        link.click();
    };

    const CleanRelationsStep = () => (
        <>
            <PageTitle
                subtitle
                description="Keep groups, tags and configurations the endpoint currently have and do not apply the relations from this enrollment configuration - only
                    applicable for PowerShell and Microsoft Intune"
                descriptionClassName="tw-text-sm tw-text-gray-400"
                color="inherit"
            >
                Keep relations
            </PageTitle>
            <Switch
                checked={!cleanRelations}
                onChange={(e) => setCleanRelations(!cleanRelations)}
            />
        </>
    );

    return (
        <>
            <Tabs
                value={selectedTab}
                centered
                onChange={(e, tabValue) => setSelectedTab(tabValue)}
                variant="fullWidth"
                className="tw-mb-4 tw-rounded-none"
                onRails
            >
                <Tab
                    disableRipple
                    label="Simple"
                    value="simple"
                />
                <Tab
                    disableRipple
                    label="PowerShell"
                    value="powershell"
                />
                <Tab
                    disableRipple
                    label="Microsoft Intune"
                    value="intune"
                />
                <Tab
                    disableRipple
                    label="Microsoft SCCM"
                    value="sccm"
                />
                <Tab
                    disableRipple
                    label="Settings"
                    value="settings"
                />
            </Tabs>
            {selectedTab === 'settings' && (
                <div className="tw-px-6 tw-pb-6">
                    {loading ? (
                        <LayoutCenter>
                            <Loading></Loading>
                        </LayoutCenter>
                    ) : (
                        <div className="tw-mt-2 tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-x-4 tw-gap-y-6 tw-font-bold">
                            <PageTitle
                                subtitle
                                description="Groups that enrolled endpoints will be a member of"
                                descriptionClassName="tw-text-sm tw-text-gray-400"
                                color="inherit"
                            >
                                Groups
                            </PageTitle>
                            <Select
                                options={groups.filter((g) => g.name.toLocaleLowerCase().includes(groupSearchTerm))}
                                selectedOptions={selectedGroups}
                                onChange={setSelectedGroups}
                                callToAction
                                light
                                fullWidth
                                style={{ width: 300 }}
                                placeholder="None"
                                multiline={selectedGroups.length > 1}
                                disabled
                                rowsMax={Infinity}
                                rows={1}
                                instantChange
                                multiple
                                searchTerm={groupSearchTerm}
                                onSearch={(e) => {
                                    setGroupSearchTerm(e);
                                }}
                                onClose={() => setGroupSearchTerm('')}
                            />
                            <PageTitle
                                subtitle
                                description="Configurations that will be applied to enrolled endpoints"
                                descriptionClassName="tw-text-sm tw-text-gray-400"
                                color="inherit"
                            >
                                Configurations
                            </PageTitle>
                            <Select
                                options={configurations.filter((c) => c.name.toLocaleLowerCase().includes(configSearchTerm))}
                                selectedOptions={selectedConfigurations}
                                onChange={setSelectedConfigurations}
                                callToAction
                                light
                                fullWidth
                                style={{ width: 300 }}
                                placeholder="None"
                                multiline={selectedConfigurations.length > 1}
                                disabled
                                rowsMax={Infinity}
                                rows={1}
                                instantChange
                                multiple
                                searchTerm={configSearchTerm}
                                onSearch={(e) => {
                                    setConfigSearchTerm(e);
                                }}
                                onClose={() => setConfigSearchTerm('')}
                            />
                            <PageTitle
                                subtitle
                                description="Tags that will be applied to enrolled endpoints"
                                descriptionClassName="tw-text-sm tw-text-gray-400"
                                color="inherit"
                            >
                                Tags
                            </PageTitle>
                            <Select
                                multiple
                                options={tags.filter((t) => t.name.toLocaleLowerCase().includes(tagSearchTerm))}
                                selectedOptions={selectedTags}
                                onChange={setSelectedTags}
                                callToAction
                                light
                                instantChange
                                placeholder="None"
                                disabled
                                multiline={selectedTags.length > 1}
                                rows={1}
                                rowsMax={Infinity}
                                style={{ width: 300 }}
                                searchTerm={tagSearchTerm}
                                onSearch={(e) => {
                                    setTagSearchTerm(e);
                                }}
                                onClose={() => setTagSearchTerm('')}
                            />

                            <CleanRelationsStep />
                        </div>
                    )}
                </div>
            )}
            <div className={classNames({ 'tw-px-6 tw-pb-6': selectedTab !== 'settings' })}>
                {selectedTab === 'powershell' && (
                    <div>
                        <PageTitle>Requirements</PageTitle>
                        <DrawerStepRow
                            badgeContent={1}
                            requirements
                        >
                            PowerShell · with administrator rights
                        </DrawerStepRow>
                        <PageTitle className="tw-mt-8">Procedure</PageTitle>
                        <DrawerStepRow badgeContent={1}>Open PowerShell with administrator rights</DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>Run the following PowerShell script to enroll your Windows endpoint to CapaOne</DrawerStepRow>
                        <WidgetPaper
                            title="PowerShell"
                            description="Run with administrator rights"
                            color="slate"
                            actions={
                                <CopyTextButton
                                    text={command}
                                    variant={BUTTON.RAISED}
                                    noMargin
                                    className="tw-rounded-full"
                                >
                                    Copy to clipboard
                                </CopyTextButton>
                            }
                            dark
                            className="tw-mt-6"
                        >
                            <div className="tw-px-4 tw-pb-4">
                                <div className="tw-w-full tw-break-words tw-text-xs tw-font-bold tw-leading-relaxed">
                                    <code>{command}</code>
                                </div>
                            </div>
                        </WidgetPaper>
                    </div>
                )}
                {selectedTab === 'intune' && (
                    <div>
                        <PageTitle>Deploy CapaOne Agent</PageTitle>
                        <DrawerStepRow badgeContent={1}>Create a group containing the endpoints that should have the CapaOne Agent installed</DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Download script:{' '}
                            {
                                <Link
                                    onClick={downloadEnrollmentConfiguration}
                                    style={{ verticalAlign: 'top' }}
                                >
                                    InstallCapaOneAgent.ps1
                                </Link>
                            }
                            <div className="tw-mt-2 tw-block tw-text-xs tw-font-semibold tw-text-gray-400">
                                This script includes the logic to download the latest Agent from CapaOne and install it with the given configuration
                            </div>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>Import the PowerShell script and assign the newly created group</DrawerStepRow>
                        <PageTitle className="tw-mt-8">Create Compliance Check (optional)</PageTitle>
                        <DrawerStepRow badgeContent={1}>
                            Download script:{' '}
                            {
                                <Link
                                    onClick={downloadComplianceCheckPowerShell}
                                    style={{ verticalAlign: 'top' }}
                                >
                                    CapaOneAgentInstalled.ps1
                                </Link>
                            }
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Download file:{' '}
                            {
                                <Link
                                    onClick={downloadComplianceCheckJson}
                                    style={{ verticalAlign: 'top' }}
                                >
                                    CapaOneAgentInstalled.json
                                </Link>
                            }
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>Add the CapaOneAgentInstalled.ps1 as a discovery script</DrawerStepRow>
                        <DrawerStepRow badgeContent={4}>Create and assign the compliance policy</DrawerStepRow>
                        <div className="tw-mt-16">
                            <DrawerStepRow
                                badgeContent={
                                    <Icon
                                        className="tw-text-black/60"
                                        type="infoOutlined"
                                        color="inherit"
                                        size="medium"
                                    />
                                }
                            >
                                <div className="tw-font-medium">
                                    Please refer to&nbsp;
                                    <Link style={{ verticalAlign: 'top' }}>
                                        <a
                                            className="tw-font-semibold tw-text-inherit"
                                            href="https://capasystems.atlassian.net/wiki/spaces/CP/pages/19446366215/CapaOne+InTune+Integration"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            this page
                                        </a>
                                    </Link>
                                    , for detailed information
                                </div>
                            </DrawerStepRow>
                        </div>
                    </div>
                )}
                {selectedTab === 'sccm' && (
                    <div>
                        <PageTitle>Procedure</PageTitle>
                        <DrawerStepRow badgeContent={1}>
                            Download zip file:{' '}
                            <Link>
                                <a
                                    color={BUTTON.PRIMARY}
                                    href={`/${baseApi.defaults.baseURL}/enrollment/${selectedConfiguration.id}/download/sccm?orgKey=${organization[0]?.orgKey}`}
                                    download="COA SCCM.zip"
                                >
                                    COA SCCM.zip
                                </a>
                            </Link>
                            <div className="tw-mt-2 tw-block tw-text-xs tw-font-semibold tw-text-gray-400">
                                This zip file contains an install and uninstall script for COA ready to be imported to SCCM.
                            </div>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Extract the downloaded zip file to a share available to SCCM
                            <div className="tw-mt-2 tw-block tw-text-xs tw-font-semibold tw-text-gray-400">
                                The extracted contents must be in a single folder e.g. \\sccmserver\smspackages\COA\{'<content of zip file>'}.
                            </div>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>Import the extracted files to SCCM</DrawerStepRow>
                        <div className="tw-mt-16">
                            <DrawerStepRow
                                badgeContent={
                                    <Icon
                                        className="tw-text-black/60"
                                        type="infoOutlined"
                                        color="inherit"
                                        size="medium"
                                    />
                                }
                            >
                                <div className="tw-font-medium">
                                    Please refer to&nbsp;
                                    <Link style={{ verticalAlign: 'top' }}>
                                        <a
                                            className="tw-font-semibold tw-text-inherit"
                                            href="https://capasystems.atlassian.net/wiki/spaces/CP/pages/19637469185/CapaOne+SCCM+Integration"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            this page
                                        </a>
                                    </Link>
                                    , for detailed information
                                </div>
                            </DrawerStepRow>
                        </div>
                    </div>
                )}
                {selectedTab === 'simple' && (
                    <div>
                        <p class="tw-text-gray-400 tw-text-xs">
                            The CapaOne Agent can be effortlessly installed on endpoints using this simple installer.
                            <br />
                            This simplifies the installation process, allowing users to manually deploy the CapaOne Agent with ease.
                        </p>

                        <DrawerStepRow badgeContent={1}>
                            Download the CapaOne Agent installer. &nbsp;
                            <Link>
                                <a
                                    color={BUTTON.PRIMARY}
                                    href={`/${baseApi.defaults.baseURL}/enrollment/${selectedConfiguration.id}/download`}
                                    download="CapaOneAgentInstaller.zip"
                                >
                                    COA_Installer.exe
                                </a>
                            </Link>
                            <div className="tw-mt-2 tw-block tw-text-xs tw-text-gray-400">
                                The installation has a long file name, do not change the name of the installer, we are using the information for enrolling the
                                endpoint.
                            </div>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Run the CapaOne Agent Installer:
                            <div className="tw-mt-2 tw-block tw-text-xs tw-font-semibold tw-text-gray-400">
                                Double-click on the downloaded CapaOne Agent installation file to start the installation process.
                                <br />
                                If prompted by any security warnings, click "Run" or "Yes" to proceed.
                            </div>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>
                            Complete the Installation and Enrollment:
                            <div className="tw-mt-2 tw-block tw-text-xs tw-font-semibold tw-text-gray-400">
                                Allow the installer to complete the installation process. This may take a few moments.
                                <br />
                                Once the installation is successful, the endpoint will be visible in the endpoint’s menu.
                            </div>
                        </DrawerStepRow>
                    </div>
                )}
            </div>
        </>
    );
};

export default WindowsEnrollment;
