export const AndroidCrossProfileConfiguration = {
    schema: {
        title: 'Cross Profile',
        type: 'object',
        category: 'Security',
        schemaId: 'crossProfileConfiguration',
        properties: {
            crossProfilePolicies: {
                title: 'Cross Profile Policies',
                description: 'Cross-profile policies applied on the device.',
                type: 'object',
                properties: {
                    showWorkContactsInPersonalProfile: {
                        title: 'Show Work Contacts In Personal Profile',
                        description: 'Whether contacts stored in the work profile can be shown in personal profile contact searches and incoming calls.',
                        type: 'string',
                        options: [
                            {
                                id: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED',
                                name: 'Disallow work profile contacts in personal profile',
                            },
                            {
                                id: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED',
                                name: 'Allow work profile contacts in personal profile',
                            },
                            {
                                id: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM',
                                name: 'Disallow work profile contacts in personal profile except system apps',
                            },
                        ],
                    },
                    workProfileWidgetsDefault: {
                        title: 'Work Profile Widgets Default',
                        description:
                            'Controls if work profile applications are allowed to add widgets to the home screen, where no app-specific policy is defined. Otherwise, the app-specific policy will have priority over this.',
                        type: 'string',
                        options: [
                            {
                                id: 'WORK_PROFILE_WIDGETS_DEFAULT_ALLOWED',
                                name: 'Allow work profile widgets by default',
                            },
                            {
                                id: 'WORK_PROFILE_WIDGETS_DEFAULT_DISALLOWED',
                                name: 'Disallow work profile widgets by default',
                            },
                        ],
                    },
                    exemptionsToShowWorkContactsInPersonalProfile: {
                        title: 'Work Contacts In Personal Profile',
                        description:
                            'List of apps which are excluded from the ShowWorkContactsInPersonalProfile setting.',
                        type: 'object',
                        properties: {
                            packageName: {
                                title: 'Package Name',
                                description: 'The package name of the app.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                    crossProfileCopyPaste: {
                        title: 'Cross Profile Copy Paste',
                        description: 'Whether text copied from one profile (personal or work) can be pasted in the other profile.',
                        type: 'string',
                        options: [
                            {
                                id: 'COPY_FROM_WORK_TO_PERSONAL_DISALLOWED',
                                name: 'Disallow copy/paste from work profile to personal profile',
                            },
                            {
                                id: 'CROSS_PROFILE_COPY_PASTE_ALLOWED',
                                name: 'Allow copy/paste from work profile to personal profile',
                            },
                        ],
                    },
                    crossProfileDataSharing: {
                        title: 'Cross Profile Data Sharing',
                        description: 'Controls whether data from one profile (personal or work) can be shared with apps in the other profile, specifically through simple data sharing via intents. Examples include actions like opening a web browser, map, document, or sharing content. Management of other cross-profile communication channels (e.g., contact search, copy/paste, or connected work & personal apps) is configured separately.',
                        type: 'string',
                        options: [
                            {
                                id: 'CROSS_PROFILE_DATA_SHARING_DISALLOWED',
                                name: 'Disallow data sharing between work profile and personal profile',
                            },
                            {
                                id: 'DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED',
                                name: 'Disallow data sharing from work profile to personal profile',
                            },
                            {
                                id: 'CROSS_PROFILE_DATA_SHARING_ALLOWED',
                                name: 'Allow data sharing between work profile and personal profile',
                            },
                        ],
                    },
                },
            },
        },
    },
};
