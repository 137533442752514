import { BUTTON, SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Button, Column, EmptyState, Icon, IconButton, LayoutCentered, LayoutRow, Loading, Page, Paper, VirtualizedTable } from '@capasystems/ui';
import { getSortingFunction, noop } from '@capasystems/utils';
import { LEAF_ID, QUERY_BUILDER_APPLE_APPLICATION_LIST } from '@thirdparty/constants';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionsDialog,
    AppleApplicationPicker,
    CapaOneLink,
    ConfirmDialogWarning,
    Heading,
    LinkCountRenderer,
    PriorityController,
    PriorityControllerButton,
    PriorityVisualization,
    QueryBuilder,
    RenamingDialog,
    TailwindBadge,
    TwoLineCellRenderer,
    useAppleApi,
    useAppleApplicationsSocket,
    useColumnPicker,
    useManagementApi,
    useMessageContext,
} from '../../../index';

import { AppleApplicationDetails } from '@thirdparty/ui';

const renameAction = {
    id: 'rename',
    name: 'Rename',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const applicationActions = [renameAction, deleteAction];

const AppleApplicationList = () => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const columnPicker = useColumnPicker({
        id: 'apple-application-list',
        lockedColumns: ['Name'],
    });
    const [applicationList, setApplicationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: {
            name: '',
        },
    });
    const { showErrorMessage } = useMessageContext();
    const [isRenaming, setIsRenaming] = useState(false);
    const [deleteState, setDeleteState] = useState({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const [sortingState, setSortingState] = useState({
        sortBy: 'name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const [priorityControllerIsOpen, setPriorityControllerIsOpen] = useState(false);
    const queryBuilderRef = useRef({});

    const refreshApplicationsList = useCallback(() => {
        setLoading(true);
        Promise.all([appleApi.getAppleApplications(), managementApi.getGroups()])
            .then(([applicationsResponse, groupsResponse]) => {
                setApplicationList(
                    applicationsResponse
                        .sort((a, b) => (a.createdAt || a.createdDate).localeCompare(b.createdAt || b.createdDate))
                        .map((application, index) => {
                            const { data, ...remaining } = application;
                            const { Attributes = {}, Options = {}, ...remainingData } = data;
                            return {
                                priority: index + 1, // Only if priority is not set.
                                ...remaining,
                                groupMemberCount: groupsResponse.filter((group) => group.applicationRefs.some(({ refId }) => refId === application.id)).length,
                                endpointMemberCount: application.endpointRefIds.length,
                                data: {
                                    Attributes: {
                                        Removable: true,
                                        ...Attributes,
                                    },
                                    Options: {
                                        PurchaseMethod: 0,
                                        ...Options,
                                    },
                                    ...remainingData,
                                },
                            };
                        }),
                );
                setErrorMessage(null);
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Could not get applications');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [appleApi]);

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    useAppleApplicationsSocket(
        useCallback(
            ({ updateDescription, documentId, fullDocument, operationType }, { insertOperation, updateOperation, deleteOperation }) => {
                if (updateOperation) {
                    setApplicationList((currentApplications) => {
                        return currentApplications.map((application) => {
                            if (application.id === documentId) {
                                return {
                                    ...application,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return application;
                        });
                    });
                } else if (insertOperation) {
                    refreshApplicationsList();
                } else if (deleteOperation) {
                    setApplicationList((currentList) => {
                        return currentList.filter((application) => application.id !== documentId);
                    });
                }
            },
            [refreshApplicationsList],
        ),
    );

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName) => {
        appleApi
            .updateAppleApplication(menuState.rowData.id, { name: newName })

            .then(() => setIsRenaming(false))
            .catch((errorResponse) => {
                showErrorMessage(errorResponse.data.message, { autoHideDuration: 2500 });
            });
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });

            appleApi
                .deleteAppleApplication(menuState.rowData.id, true)
                .then(() => {
                    setApplicationList((a) => {
                        return a.filter((application) => application.id !== menuState.rowData.id);
                    });
                    closeDeleteDialog();
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onSubmit = (activeLeafs) => {
        setAppliedFilters(activeLeafs);
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    const openPriorityController = () => {
        setPriorityControllerIsOpen(true);
    };

    const closePriorityController = () => {
        setPriorityControllerIsOpen(false);
    };

    const onPriorityControllerSubmit = (updatedItems) => {
        closePriorityController();
        appleApi
            .updateAppleApplicationPriority(updatedItems)
            .then(noop)
            .catch(() => {
                openPriorityController();
            });
    };

    const applicationIsRemovable = (application) => {
        return application.data.Attributes.Removable;
    };

    const applicationIsVPP = (application) => {
        return application.data.Options.PurchaseMethod === 1;
    };

    useEffect(refreshApplicationsList, [refreshApplicationsList]);

    const [memoizedApplicationList, virtualizedTableProps] = useMemo(() => {
        const isNumeric = ['priority', 'endpointMemberCount', 'groupMemberCount'].includes(sortingState.sortBy);
        const sortingFunction = getSortingFunction(sortingState, isNumeric);

        let filteredApps = applicationList.sort(sortingFunction);
        if (appliedFilters.length > 0) {
            appliedFilters.map((appliedFilter) => {
                if (appliedFilter.id === LEAF_ID.OS_TYPE) {
                    filteredApps = filteredApps.filter((filteredapp) => filteredapp.supportedPlatforms.includes(appliedFilter.value[0]));
                } else if (appliedFilter.id === LEAF_ID.NAME) {
                    filteredApps = filteredApps.filter((filteredapp) => filteredapp.name.toLowerCase().includes(appliedFilter.value.toLowerCase()));
                } else if (appliedFilter.id === LEAF_ID.IS_VPP) {
                    filteredApps = filteredApps.filter((filteredapp) => filteredapp.data.Options.PurchaseMethod === parseInt(appliedFilter.value[0]));
                } else if (appliedFilter.id === LEAF_ID.REMOVABLE) {
                    filteredApps = filteredApps.filter((filteredapp) => {
                        return filteredapp.data.Attributes.Removable === JSON.parse(appliedFilter.value[0]);
                    });
                }
            });
        }

        const tableProps = {
            totalRowCount: filteredApps.length,
        };

        return [filteredApps, tableProps];
    }, [applicationList, appliedFilters, sortingState]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    <Heading>{errorMessage}</Heading>
                    <Button
                        onClick={refreshApplicationsList}
                        className="tw-mt-4"
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        disabled={loading}
                    >
                        Retry
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    if (loading) {
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }

    return (
        <>
            <Page title="Applications">
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                    <LayoutRow align="space-between">
                        <div className="tw-ml-auto tw-flex tw-items-center tw-gap-4">
                            {applicationList.length > 0 && (
                                <QueryBuilder
                                    defaultConfiguration={QUERY_BUILDER_APPLE_APPLICATION_LIST}
                                    onInit={onSubmit}
                                    onSubmit={onSubmit}
                                    className="tw-w-72"
                                    ref={queryBuilderRef}
                                />
                            )}
                            <AppleApplicationPicker
                                noMargin
                                className="tw-right-0"
                            />
                        </div>
                    </LayoutRow>

                    <Paper
                        className={classNames({
                            'tw-h-full tw-shadow-sm': true,
                        })}
                    >
                        <VirtualizedTable
                            items={memoizedApplicationList}
                            disableHeader={applicationList.length === 0}
                            entity="application"
                            totalRowCount={memoizedApplicationList.length}
                            showRowCount
                            noRowsRenderer={() => (
                                <EmptyState
                                    isSearching={searchTerm !== ''}
                                    searchTerm={searchTerm}
                                    entity="application"
                                    onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                >
                                    <AppleApplicationPicker
                                        noMargin
                                        className="tw-right-0"
                                        buttonText="Create"
                                    />
                                </EmptyState>
                            )}
                            actions={
                                <>
                                    <PriorityControllerButton onClick={openPriorityController} />
                                    <PriorityController
                                        open={priorityControllerIsOpen}
                                        onCancel={closePriorityController}
                                        onSubmit={onPriorityControllerSubmit}
                                        items={applicationList}
                                        rowRenderer={(item) => (
                                            <div>
                                                <div className="tw-font-bold">{item.name}</div>
                                                <div className="tw-text-xs tw-text-neutral-700">{item.installTypeName}</div>
                                            </div>
                                        )}
                                    />
                                </>
                            }
                            sort={setSortingState}
                            sortBy={sortingState.sortBy}
                            sortDirection={sortingState.sortDirection}
                            columnPicker={columnPicker}
                            {...virtualizedTableProps}
                        >
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="id"
                                disableSort
                                label=""
                                type="icon"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <IconButton
                                            onClick={(e) => {
                                                setMenuState({
                                                    open: true,
                                                    anchorEl: e.currentTarget,
                                                    rowData,
                                                    pages: [
                                                        {
                                                            name: 'Endpoints',
                                                            url: `apple/application/${rowData.id}/membership?tab=endpoints`,
                                                            icon: 'appleEndpoint',
                                                        },
                                                        {
                                                            name: 'Groups',
                                                            url: `apple/application/${rowData.id}/membership?tab=groups`,
                                                            icon: 'appleGroup',
                                                        },
                                                        {
                                                            name: 'Edit',
                                                            url: `apple/application/${rowData.id}/edit`,
                                                            icon: 'editOutlined',
                                                        },
                                                    ],
                                                });
                                            }}
                                            noMargin
                                        >
                                            <Icon type="moreVert" />
                                        </IconButton>
                                    );
                                }}
                            />
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="iconUrl"
                                label=""
                                type="avatar"
                                cellRenderer={({ rowData }) => {
                                    if (rowData.iconUrl) {
                                        return (
                                            <Avatar
                                                src={rowData.iconUrl}
                                                alt={rowData.name}
                                                variant="rounded"
                                                className="tw-h-8 tw-w-8"
                                            />
                                        );
                                    }
                                    return (
                                        <Icon
                                            type="app"
                                            className="tw-h-8 tw-w-8"
                                        />
                                    );
                                }}
                            />
                            <Column
                                minWidth={160}
                                dataKey="name"
                                label="Name"
                                type="multiLine"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <CapaOneLink to={`apple/application/${rowData.id}/membership`}>
                                            <TwoLineCellRenderer
                                                main={rowData.name}
                                                callToAction
                                                secondary={rowData.vendor || ''}
                                            />
                                        </CapaOneLink>
                                    );
                                }}
                            />
                            <Column
                                minWidth={96}
                                maxWidth={96}
                                dataKey="priority"
                                label="Priority"
                                type="multiLine"
                                cellRenderer={({ rowData }) => {
                                    const blockSize = 100 / Math.max(applicationList.length - 1, 1);
                                    const percentage = rowData.priority === 1 ? 100 : blockSize * (applicationList.length - rowData.priority);
                                    return (
                                        <PriorityVisualization
                                            percentage={percentage}
                                            priority={rowData.priority}
                                        />
                                    );
                                }}
                            />
                            <Column
                                minWidth={96}
                                maxWidth={96}
                                dataKey="endpointMemberCount"
                                label="Endpoints"
                                defaultSortDirection={SORT_DIRECTION.DESC}
                                type="number"
                                cellRenderer={({ cellData, rowData }) => {
                                    return (
                                        <LinkCountRenderer
                                            cellData={cellData}
                                            link={`apple/application/${rowData.id}/membership?tab=endpoints`}
                                        />
                                    );
                                }}
                            />
                            <Column
                                minWidth={96}
                                maxWidth={96}
                                dataKey="groupMemberCount"
                                label="Groups"
                                defaultSortDirection={SORT_DIRECTION.DESC}
                                type="number"
                                cellRenderer={({ cellData, rowData }) => {
                                    return (
                                        <LinkCountRenderer
                                            cellData={cellData}
                                            link={`apple/application/${rowData.id}/membership?tab=groups`}
                                        />
                                    );
                                }}
                            />
                            <Column
                                minWidth={64}
                                maxWidth={64}
                                dataKey="isVpp"
                                type="badge"
                                cellRenderer={({ rowData }) => {
                                    if (applicationIsVPP(rowData)) {
                                        return (
                                            <TailwindBadge
                                                color="teal"
                                                size="small"
                                                noShadow
                                            >
                                                VPP
                                            </TailwindBadge>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Column
                                width={64}
                                maxWidth={64}
                                minWidth={64}
                                flexGrow={1}
                                disableSort
                                dataKey="id"
                                label=""
                                className="tw-overflow-visible"
                                type="info"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <div className="tw-flex tw-place-content-end">
                                            <AppleApplicationDetails application={rowData} />
                                        </div>
                                    );
                                }}
                            />
                        </VirtualizedTable>
                    </Paper>
                </div>
            </Page>
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Application"
                title={menuState.rowData.name}
                pages={menuState.pages}
                actions={applicationActions}
                onActionClick={onActionClick}
            />
            <RenamingDialog
                onCancel={onRename}
                onSubmit={onRenameSubmit}
                currentName={menuState.rowData.name}
                open={isRenaming}
            />
            <ConfirmDialogWarning
                onConfirm={onDelete}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                name={menuState.rowData.name}
                isProcessing={deleteState.isProcessing}
                error={deleteState.anErrorOccurred ? `Could not delete "${menuState.rowData.name}". Please try again.` : null}
            />
        </>
    );
};

export { AppleApplicationList };
