import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Loading } from '@capasystems/ui';
import classNames from 'classnames';
import React from 'react';

export type ConfirmDialogProps = {
    children: React.ReactNode;
    onConfirm?: () => void;
    onCancel: () => void;
    onExited?: () => void;
    open: boolean;
    title?: React.ReactNode;
    subtitle?: string;
    isProcessing?: boolean;
    aditionalActions?: React.ReactNode;
    disabled?: boolean;
    dialogContentClassName?: string;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    children,
    onConfirm,
    title = 'Confirmation required',
    subtitle,
    onCancel,
    isProcessing = false,
    aditionalActions,
    disabled,
    dialogContentClassName,
    ...rest
}) => (
    <Dialog
        onClose={onCancel}
        confirm
        {...rest}
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent className={dialogContentClassName}>{children}</DialogContent>
        <DialogActions>
            {aditionalActions}
            {onConfirm && (
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                    className={classNames({
                        'tw-invisible': isProcessing,
                    })}
                    disabled={disabled}
                    noMargin
                >
                    Confirm
                </Button>
            )}
            <Button
                onClick={onCancel}
                className={classNames({
                    'tw-invisible': isProcessing,
                })}
            >
                {onConfirm ? 'Cancel' : 'Close'}
            </Button>
            {isProcessing && (
                <Loading
                    size={16}
                    className="tw-mr-4"
                />
            )}
        </DialogActions>
    </Dialog>
);

export default ConfirmDialog;
