import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import { Avatar, Button, Column, EmptyState, Icon, IconButton, LayoutCentered, LayoutRow, Loading, Page, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { getSortingFunction, isDefined, noop } from '@capasystems/utils';
import { TAndroidConfigurationWithId } from '@db/party';
import { LEAF_ID, QUERY_BUILDER_ANDROID_APPLICATION_LIST, coreAndroidManagement } from '@thirdparty/constants';
import { AndroidApplicationDetails, LinkCountRenderer } from '@thirdparty/ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionsDialog,
    AndroidApplicationPicker,
    CapaOneLink,
    ConfirmDialogWarning,
    Heading,
    PriorityController,
    PriorityControllerButton,
    PriorityVisualization,
    QueryBuilder,
    RenamingDialog,
    TwoLineCellRenderer,
    WidgetPaper,
    useAndroidApi,
    useAndroidApplicationsSocket,
    useColumnPicker,
    useManagementApi,
} from '../../../index';
import { streamlineApplicationDocument } from '../../hooks/useApi/android-connection';

const renameAction = {
    id: 'rename',
    name: 'Rename',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const applicationActions = [renameAction, deleteAction];

const AndroidApplicationList = () => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const columnPicker = useColumnPicker({
        id: 'android-application-list',
        lockedColumns: ['Name'],
    });
    const [applicationList, setApplicationList] = useState<TAndroidConfigurationWithId[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [appliedFilters, setAppliedFilters] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [menuState, setMenuState] = useState<{
        open: boolean;
        anchorEl: any;
        rowData: Partial<TAndroidConfigurationWithId>;
        pages: any[];
    }>({
        open: false,
        anchorEl: null,
        rowData: {
            name: '',
            id: '',
        },
        pages: [],
    });
    const [isRenaming, setIsRenaming] = useState(false);
    const [deleteState, setDeleteState] = useState<{ open: boolean; isProcessing?: boolean; anErrorOccurred?: boolean }>({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const [sortingState, setSortingState] = useState({
        sortBy: 'name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const [priorityControllerIsOpen, setPriorityControllerIsOpen] = useState(false);
    const queryBuilderRef = useRef<any>({});

    const refreshApplicationsList = useCallback(() => {
        setLoading(true);
        Promise.all([androidApi.getApplications(), managementApi.getGroups()])
            .then(([applicationsResponse, groupsResponse]) => {
                setApplicationList(
                    applicationsResponse
                        .sort((a: any, b: any) => (a.createdAt || a.createdDate).localeCompare(b.createdAt || b.createdDate))
                        .map((application: any, index: any) => ({
                            priority: index + 1, // Only if priority is not set.
                            ...application,
                            groupMemberCount: groupsResponse.filter((group: any) =>
                                group.applicationRefs.some(({ refId }: { refId: any }) => refId === application.id),
                            ).length,
                            endpointMemberCount: application.endpointRefIds.length,
                        })),
                );
                setErrorMessage(null);
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Could not get applications');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [androidApi]);

    useAndroidApplicationsSocket(
        useCallback<TSocketMethod<TAndroidConfigurationWithId>>(
            ({ documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
                const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
                if (updateOperation) {
                    setApplicationList((currentApplications: any) => {
                        return currentApplications.map((application: any) => {
                            if (application.id === documentId) {
                                return {
                                    ...streamlinedApplicationDocument,
                                    id: documentId,
                                };
                            }
                            return application;
                        });
                    });
                } else if (insertOperation) {
                    refreshApplicationsList();
                } else if (deleteOperation) {
                    setApplicationList((a) => {
                        return a.filter((application) => application.id !== documentId);
                    });
                }
            },
            [refreshApplicationsList],
        ),
    );

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        androidApi
            .updateAndroidApplication(menuState.rowData.id as string, { name: newName })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });

            androidApi
                .deleteApplication(menuState.rowData.id as string, true)
                .then(() => {
                    setApplicationList((a) => {
                        return a.filter((application) => application.id !== menuState.rowData.id);
                    });
                    closeDeleteDialog();
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
            if (menuState.rowData.id) {
            }
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onSubmit = (activeLeafs: any) => {
        setAppliedFilters(activeLeafs);
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    const openPriorityController = () => {
        setPriorityControllerIsOpen(true);
    };

    const closePriorityController = () => {
        setPriorityControllerIsOpen(false);
    };

    const onPriorityControllerSubmit = (updatedItems: any) => {
        closePriorityController();
        androidApi
            .updateAndroidApplications(updatedItems)
            .then(noop)
            .catch(() => {
                openPriorityController();
            });
    };

    useEffect(refreshApplicationsList, [refreshApplicationsList]);

    const [memoizedApplicationList, virtualizedTableProps] = useMemo(() => {
        const isNumeric = ['priority', 'endpointMemberCount', 'groupMemberCount'].includes(sortingState.sortBy);
        const sortingFunction = getSortingFunction(sortingState, isNumeric);

        let filteredApps = applicationList.sort(sortingFunction);
        if (appliedFilters.length > 0) {
            appliedFilters.map((appliedFilters) => {
                if (appliedFilters.id === LEAF_ID.NAME) {
                    filteredApps = filteredApps.filter((filteredApp) => filteredApp.name.toLowerCase().includes(appliedFilters.value.toLowerCase()));
                } else if (appliedFilters.id === LEAF_ID.ANDROID_APPLICATION_TYPE) {
                    filteredApps = filteredApps.filter((filteredApp) => filteredApp.configurationType.includes(appliedFilters.value[0]));
                } else if (appliedFilters.id === LEAF_ID.ANDROID_APP_INSTALL_TYPE) {
                    filteredApps = filteredApps.filter((filteredApp) => {
                        return filteredApp.installType?.includes(appliedFilters.value[0]);
                    });
                }
            });
        }

        const tableProps = {
            totalRowCount: filteredApps.length,
        };
        return [filteredApps, tableProps];
    }, [applicationList, appliedFilters, sortingState]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    {/* @ts-ignore -  Heading is not typed */}
                    <Heading>{errorMessage}</Heading>
                    <Button
                        onClick={refreshApplicationsList}
                        className="tw-mt-4"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                    >
                        Retry
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    if (loading) {
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }

    return (
        <Page title="Applications">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <LayoutRow align="space-between">
                    <div className="tw-ml-auto tw-flex tw-items-center tw-gap-4">
                        {applicationList.length > 0 && (
                            <QueryBuilder
                                // @ts-ignore -  QueryBuilder is not typed
                                defaultConfiguration={QUERY_BUILDER_ANDROID_APPLICATION_LIST}
                                onInit={onSubmit}
                                onSubmit={onSubmit}
                                className="tw-w-full"
                                ref={queryBuilderRef}
                            />
                        )}
                        <AndroidApplicationPicker
                            noMargin
                            className="tw-right-0"
                        />
                    </div>
                </LayoutRow>
                <ActionsDialog
                    open={menuState.open}
                    anchorEl={menuState.anchorEl}
                    onClose={closeMenu}
                    category="Application"
                    title={menuState.rowData.name as string}
                    pages={menuState.pages}
                    actions={applicationActions}
                    onActionClick={onActionClick}
                />
                <RenamingDialog
                    onCancel={onRename}
                    onSubmit={onRenameSubmit}
                    currentName={menuState.rowData.name as string}
                    open={isRenaming}
                />
                <ConfirmDialogWarning
                    onConfirm={onDelete}
                    onCancel={closeDeleteDialog}
                    open={deleteState.open}
                    name={menuState.rowData.name || ''}
                    isProcessing={deleteState.isProcessing || false}
                    error={deleteState.anErrorOccurred ? `Could not delete "${menuState.rowData.name}". Please try again.` : undefined}
                />

                <WidgetPaper
                    headerless
                    className="tw-h-full tw-shadow-sm"
                >
                    {/* @ts-ignore - VirtualizedTable is not typed */}
                    <VirtualizedTable
                        items={memoizedApplicationList}
                        disableHeader={applicationList.length === 0}
                        entity="application"
                        // @ts-ignore -  VirtualizedTable is not typed
                        totalRowCount={memoizedApplicationList.length}
                        showRowCount
                        noRowsRenderer={() => (
                            <EmptyState
                                isSearching={searchTerm !== ''}
                                searchTerm={searchTerm}
                                entity="application"
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            >
                                <AndroidApplicationPicker buttonText="Create" />
                            </EmptyState>
                        )}
                        /* @ts-ignore - actions is not typed */
                        actions={
                            <>
                                {/* @ts-ignore - PriorityController is not typed */}
                                <PriorityControllerButton onClick={openPriorityController} />
                                {/* @ts-ignore - PriorityController is not typed */}
                                <PriorityController
                                    open={priorityControllerIsOpen}
                                    onCancel={closePriorityController}
                                    onSubmit={onPriorityControllerSubmit}
                                    // @ts-ignore -  PriorityController is not typed
                                    items={applicationList}
                                    rowRenderer={(item) => (
                                        <div>
                                            <div className="tw-font-bold">{item.name}</div>
                                            <div className="tw-text-xs tw-text-neutral-700">{item.installTypeName}</div>
                                        </div>
                                    )}
                                />
                            </>
                        }
                        /* @ts-ignore - sort is not typed */
                        sort={setSortingState}
                        // @ts-ignore -  VirtualizedTable is not typed
                        sortBy={sortingState.sortBy}
                        /* @ts-ignore - sortDirection is not typed */
                        sortDirection={sortingState.sortDirection}
                        /* @ts-ignore - columnPicker is not typed */
                        columnPicker={columnPicker}
                        {...virtualizedTableProps}
                    >
                        <Column
                            minWidth={32}
                            maxWidth={32}
                            dataKey="id"
                            disableSort
                            label=""
                            type="icon"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <IconButton
                                        onClick={(e) => {
                                            setMenuState({
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                rowData,
                                                pages: [
                                                    {
                                                        name: 'Endpoints',
                                                        url: `android/application/${rowData.id}/membership?tab=endpoints`,
                                                        icon: 'androidEndpoint',
                                                    },
                                                    {
                                                        name: 'Groups',
                                                        url: `android/application/${rowData.id}/membership?tab=groups`,
                                                        icon: 'androidGroup',
                                                    },
                                                    {
                                                        name: 'Edit',
                                                        url: `android/application/${rowData.id}/edit`,
                                                        icon: 'editOutlined',
                                                    },
                                                ],
                                            });
                                        }}
                                        noMargin
                                    >
                                        <Icon type="moreVert" />
                                    </IconButton>
                                );
                            }}
                        />
                        <Column
                            minWidth={32}
                            maxWidth={32}
                            dataKey="iconUrl"
                            label=""
                            type="avatar"
                            cellRenderer={({ rowData }) => {
                                if (rowData.iconUrl) {
                                    return (
                                        <Avatar
                                            src={rowData.iconUrl}
                                            alt={rowData.name}
                                            variant="rounded"
                                            className="tw-h-8 tw-w-8"
                                        />
                                    );
                                }
                                return (
                                    <Icon
                                        type="app"
                                        className="tw-h-8 tw-w-8"
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={240}
                            dataKey="name"
                            label="Name"
                            type="multiLine"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <CapaOneLink to={`android/application/${rowData.id}/membership`}>
                                        <TwoLineCellRenderer
                                            main={rowData.name}
                                            callToAction
                                            secondary={rowData?.vendor || ''}
                                        />
                                    </CapaOneLink>
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="priority"
                            label="Priority"
                            type="multiLine"
                            cellRenderer={({ rowData }) => {
                                const blockSize = 100 / Math.max(applicationList.length - 1, 1);
                                const percentage = rowData.priority === 1 ? 100 : blockSize * (applicationList.length - rowData.priority);
                                return <PriorityVisualization percentage={percentage} />;
                            }}
                        />

                        <Column
                            minWidth={96}
                            maxWidth={96}
                            dataKey="endpointMemberCount"
                            label="Endpoints"
                            defaultSortDirection={SORT_DIRECTION.DESC}
                            type="number"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <LinkCountRenderer
                                        cellData={cellData}
                                        link={`android/application/${rowData.id}/membership?tab=endpoints`}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={96}
                            maxWidth={96}
                            dataKey="groupMemberCount"
                            label="Groups"
                            defaultSortDirection={SORT_DIRECTION.DESC}
                            type="number"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <LinkCountRenderer
                                        cellData={cellData}
                                        link={`android/application/${rowData.id}/membership?tab=groups`}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="configurationType"
                            label="Application type"
                            type="icon"
                            cellRenderer={({ rowData }) => {
                                if (rowData.configurationType === coreAndroidManagement.configurationType.application) {
                                    const [application] = rowData.data.applications || [];
                                    if (isDefined(application)) {
                                        return (
                                            <Tooltip
                                                content="View app in Google Play store"
                                                position={TOOLTIP.POSITION.LEFT}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    href={`https://play.google.com/store/apps/details?id=${application.packageName}`}
                                                    target="google_play_store"
                                                    // size="small"
                                                    noMargin
                                                >
                                                    <Icon type="googlePlayStore" />
                                                </IconButton>
                                            </Tooltip>
                                        );
                                    }
                                    return null;
                                }

                                return (
                                    <Tooltip
                                        content="Web app"
                                        position={TOOLTIP.POSITION.LEFT}
                                    >
                                        <Icon
                                            type="googlePlayWebApp"
                                            className="tw-mr-2 tw-text-sky-900"
                                        />
                                    </Tooltip>
                                );
                            }}
                        />
                        <Column
                            // @ts-ignore - VirtualizedTable is not typed
                            width={64}
                            maxWidth={64}
                            minWidth={64}
                            flexGrow={1}
                            disableSort
                            dataKey="id"
                            label=""
                            className="tw-overflow-visible"
                            type="info"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <div className="tw-flex tw-place-content-end">
                                        <AndroidApplicationDetails application={rowData} />
                                    </div>
                                );
                            }}
                        />
                    </VirtualizedTable>
                </WidgetPaper>
            </div>
        </Page>
    );
};

export { AndroidApplicationList };

