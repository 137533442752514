import { BUTTON, SORT_DIRECTION, coreMessage } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import {
    Avatar,
    Button,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Paper,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
} from '@capasystems/ui';
import { Url, getSortingFunction, isDefined, noop } from '@capasystems/utils';
import { TAndroidConfigurationWithId, TAndroidDeviceWithId, TAppleApplicationWithId, TAppleConfigurationWithId } from '@db/party';
import { LEAF_ID, LEAF_OPERATOR, QUERY_BUILDER_REF_DEVICE, windowsApplication } from '@thirdparty/constants';
import {
    ActionsDialog,
    CapaOneLink,
    ConfirmDialog,
    ConfirmDialogWarning,
    LinkCountRenderer,
    PageTitle,
    QueryBuilder,
    TailwindBadge,
    TransferList,
    TwoLineCellRenderer,
    WindowsApplicationConfigDetails,
    WindowsApplicationNameAndVersion,
    WindowsApplicationThumbnail,
    WindowsApplicationsTransferList,
    WindowsEndpointsFilterPicker,
    useAndroidApi,
    useAndroidApplicationsSocket,
    useAndroidConfigurationsSocket,
    useAndroidEndpointsSocket,
    useApi,
    useAppleApi,
    useAppleApplicationsSocket,
    useAppleConfigurationsSocket,
    useAppleEndpointsSocket,
    useApplicationSocket,
    useColumnPicker,
    useConfigurationSocket,
    useDevicesSocket,
    useGroupSocket,
    useGroupsSocket,
    useManagementApi,
} from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration, leafsToMongo, queryBuilderQueryStringToUrlParams, windowsApplicationUtils } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { streamlineApplicationDocument } from '../hooks/useApi/android-connection';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';

const newGroupAction = {
    id: 'new',
    name: 'New',
};
const editAction = {
    id: 'edit',
    icon: 'editOutlined',
    color: BUTTON.DEFAULT,
    name: 'Edit',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
    color: BUTTON.PRIMARY,
};
const groupActions = [editAction, deleteAction];
const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Group name' });

export const GroupListManagement = () => {
    const managementApi = useManagementApi();
    const columnPicker = useColumnPicker({
        id: 'management-group-list',
        lockedColumns: ['Name'],
    });
    const [groupsList, setGroupsList] = useState<any[]>([]);
    const [editGroupState, setEditGroupState] = useState({
        open: false,
        selectedGroup: {},
    });
    const [menuState, setMenuState] = useState<{ open: boolean; anchorEl: any; rowData: any; pages?: any[] }>({
        open: false,
        anchorEl: null,
        rowData: {},
    });
    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(true);
    const [deleteState, setDeleteState] = useState<{ open: boolean; isProcessing?: boolean; anErrorOccurred?: boolean }>({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const [sortingState, setSortingState] = useState({
        sortBy: 'name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const queryBuilderRef = useRef<any>({});

    useEffect(() => {
        fetchGroups();
    }, []);

    const onSubmit = ([activeLeaf]: [any]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const fetchGroups = () => {
        // api.cancel();
        setLoading(true);
        managementApi
            .getGroups()
            .then((groupsResponse) => {
                setGroupsList(groupsResponse);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSocketNotification = useCallback<TSocketMethod>(
        ({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setGroupsList((currentGroups) => {
                    return currentGroups.map((group: any) => {
                        if (group.id === documentId) {
                            return {
                                ...group,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return group;
                    });
                });
            } else if (insertOperation) {
                setGroupsList((currentGroups) => [
                    ...currentGroups,
                    {
                        ...fullDocument,
                        id: documentId,
                    },
                ]);
                // fetchGroups();
            } else if (deleteOperation) {
                setGroupsList((currentList) => {
                    return currentList.filter((group) => group.id !== documentId);
                });
            }
        },
        [],
    );

    useGroupsSocket(onSocketNotification);

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            managementApi
                .deleteGroup(menuState.rowData.id)
                .then(() => {
                    setGroupsList((currentGroupsList) => {
                        return currentGroupsList.filter((group) => group.id !== menuState.rowData.id);
                    });
                    closeDeleteDialog();
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case newGroupAction.id:
                setEditGroupState({
                    open: true,
                    selectedGroup: {},
                });
                break;
            case editAction.id:
                setEditGroupState({
                    open: true,
                    selectedGroup: menuState.rowData,
                });
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    const memoizedGroupsList = useMemo(() => {
        const isNumeric = ['endpoints', 'configurations', 'applications'].includes(sortingState.sortBy);
        const isDate = ['updatedAt'].includes(sortingState.sortBy);
        const sortingFunction = getSortingFunction(sortingState, isNumeric, isDate);
        if (searchTerm) {
            return groupsList
                .filter((c) => c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                .map((g) => {
                    return { ...g, endpoints: g.endpointRefs.length, configurations: g.configurationRefs.length, applications: g.applicationRefs.length };
                })
                .sort(sortingFunction);
        }
        return groupsList
            .map((g) => {
                return { ...g, endpoints: g.endpointRefs.length, configurations: g.configurationRefs.length, applications: g.applicationRefs.length };
            })
            .sort(sortingFunction);
    }, [sortingState, searchTerm, groupsList]);

    return (
        <>
            <EditOrCreateGroupDialog
                {...editGroupState}
                onCancel={() =>
                    setEditGroupState((c) => ({
                        ...c,
                        open: false,
                    }))
                }
                onSubmit={() =>
                    setEditGroupState((c) => ({
                        ...c,
                        open: false,
                    }))
                }
            />
            {/* @ts-ignore - this is not typed yet */}
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Group"
                title={menuState.rowData.name || ''}
                pages={menuState.pages}
                actions={groupActions}
                //@ts-ignore - this is not typed
                onActionClick={onActionClick}
            />
            <ConfirmDialogWarning
                onConfirm={onDelete}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                name={menuState.rowData.name}
                isProcessing={deleteState.isProcessing ?? false}
            />
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-flex tw-justify-end tw-gap-4">
                    <QueryBuilder
                        // @ts-ignore
                        defaultConfiguration={queryBuilderConfiguration}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        ref={queryBuilderRef}
                        className="tw-w-64"
                    />
                    <Button
                        noMargin
                        color="primary"
                        variant="contained"
                        onClick={() => onActionClick(newGroupAction)}
                    >
                        {newGroupAction.name}
                    </Button>
                </div>
                <Paper className="tw-h-full tw-shadow-sm">
                    <VirtualizedTable
                        /* @ts-ignore - VirtualizedTable is not typed */
                        items={memoizedGroupsList}
                        totalRowCount={memoizedGroupsList.length}
                        isLoading={loading}
                        /* @ts-ignore - VirtualizedTable is not typed */
                        sort={setSortingState}
                        // @ts-ignore
                        sortBy={sortingState.sortBy}
                        /* @ts-ignore - VirtualizedTable is not typed */
                        sortDirection={sortingState.sortDirection}
                        disableHeader={memoizedGroupsList.length === 0}
                        noRowsRenderer={() => (
                            <EmptyState
                                entity="group"
                                searchTerm={searchTerm}
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                isSearching={searchTerm !== ''}
                                onClick={() => onActionClick(newGroupAction)}
                            />
                        )}
                        /* @ts-ignore - VirtualizedTable is not typed */
                        columnPicker={columnPicker}
                    >
                        <Column
                            minWidth={32}
                            maxWidth={32}
                            dataKey="id"
                            label=""
                            disableSort
                            type="icon"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <IconButton
                                        onClick={(e) => {
                                            setMenuState({
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                rowData,
                                                pages: [
                                                    {
                                                        name: 'View',
                                                        icon: 'visibility',
                                                        url: `management/group/${rowData.id}`,
                                                    },
                                                ],
                                            });
                                        }}
                                        noMargin
                                    >
                                        <Icon type="moreVert" />
                                    </IconButton>
                                );
                            }}
                        />
                        <Column
                            minWidth={160}
                            dataKey="name"
                            label="Name"
                            type="string"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <CapaOneLink
                                        to={`management/group/${rowData.id}`}
                                        className="tw-font-semibold"
                                    >
                                        <Ellipsis>{rowData.name}</Ellipsis>
                                    </CapaOneLink>
                                );
                            }}
                        />
                        <Column
                            minWidth={160}
                            dataKey="description"
                            label="Description"
                            type="string"
                        />
                        <Column
                            minWidth={72}
                            maxWidth={72}
                            disableSort
                            dataKey="type"
                            label="Type"
                            type="icon"
                            cellRenderer={({ rowData }) => (
                                <>
                                    {rowData.type.includes('windows') && (
                                        <Icon
                                            type="windows"
                                            color="primary"
                                        />
                                    )}
                                    {rowData.type.includes('android') && (
                                        <span className="tw-text-android">
                                            <Icon
                                                type="android"
                                                color="inherit"
                                            />
                                        </span>
                                    )}
                                    {rowData.type.includes('apple') && (
                                        <span className="tw-text-appleDark">
                                            <Icon
                                                type="apple"
                                                color="inherit"
                                            />
                                        </span>
                                    )}
                                </>
                            )}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="endpoints"
                            label="Endpoints"
                            type="number"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <LinkCountRenderer
                                        cellData={cellData}
                                        link={`management/group/${rowData.id}/windows/endpoints`}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="configurations"
                            label="Configurations"
                            type="number"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <LinkCountRenderer
                                        cellData={cellData}
                                        link={`management/group/${rowData.id}/windows/configurations`}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="applications"
                            label="Applications"
                            type="number"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <LinkCountRenderer
                                        cellData={cellData}
                                        link={`management/group/${rowData.id}/windows/applications`}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={160}
                            maxWidth={160}
                            dataKey="updatedAt"
                            label="Updated"
                            type="string"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <Tooltip content={dayjs(rowData.updatedAt).format('DD-MM-YYYY HH:mm')}>
                                        <div>
                                            <Ellipsis>{dayjs(rowData.updatedAt).fromNow()}</Ellipsis>
                                        </div>
                                    </Tooltip>
                                )
                            }}
                        />
                    </VirtualizedTable>
                </Paper>
            </div>
        </>
    );
};

export type EditOrCreateGroupDialogProps = {
    open: boolean;
    onCancel: () => void;
    onSubmit: (input: any) => void;
    selectedGroup?: any;
};

export const EditOrCreateGroupDialog: React.FC<EditOrCreateGroupDialogProps> = ({ open, onCancel, onSubmit, selectedGroup = {} }) => {
    const managementApi = useManagementApi();
    const [groupNameError, setGroupNameError] = useState<string | null>(null);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');

    useEffect(() => {
        if (open) {
            setGroupNameError(null);
            setGroupName(selectedGroup.name || '');
            setGroupDescription(selectedGroup.description || '');
        }
    }, [open]);

    const createGroup = () => {
        if (groupName.trim() !== '') {
            if (selectedGroup.id) {
                managementApi
                    .updateGroup(selectedGroup.id, {
                        name: groupName.trim(),
                        description: groupDescription.trim(),
                    })
                    .then((response) => {
                        onSubmit(response);
                    })
                    .catch((response) => {
                        setGroupNameError(response.data.message || coreMessage.anUnknownErrorOccurred);
                    });
            } else {
                managementApi
                    .createGroup({
                        name: groupName.trim(),
                        description: groupDescription.trim(),
                    })
                    .then((response) => {
                        onSubmit(response);
                    })
                    .catch((response) => {
                        setGroupNameError(response.data.message || coreMessage.anUnknownErrorOccurred);
                    });
            }
        } else {
            setGroupNameError('Group name is required');
        }
    };

    return (
        <Page title="Groups">
            <Dialog
                open={open}
                onClose={onCancel}
            >
                <DialogContent>
                    <PageTitle
                        category={selectedGroup.id ? 'Edit' : 'New'}
                        className="tw-mb-4"
                    >
                        Group
                    </PageTitle>
                    <Input
                        autoFocus
                        callToAction
                        fullWidth
                        placeholder="Name"
                        onChange={(e) => {
                            setGroupNameError(null);
                            setGroupName(e.target.value);
                        }}
                        value={groupName}
                        onSubmit={createGroup}
                        error={groupNameError !== null}
                        helperText={groupNameError}
                    />
                    <Input
                        callToAction
                        fullWidth
                        placeholder="Description"
                        multiline
                        rows={2}
                        onChange={(e) => {
                            setGroupDescription(e.target.value);
                        }}
                        value={groupDescription}
                        className="tw-mt-4"
                    />
                </DialogContent>
                <DialogActions className="tw-mt-2 tw-pr-4">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={createGroup}
                        disabled={groupName.trim() === ''}
                    >
                        {selectedGroup.id ? 'Save changes' : 'Create'}
                    </Button>
                    <Button
                        onClick={onCancel}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
};

export const GroupManagement = () => {
    const { groupId, '*': splat } = useParams();
    const navigate = useNavigate();
    const { permissions } = useAuthContext();
    const managementApi = useManagementApi();
    const [group, setGroup] = useState<any>({});
    const [actionsDialogState, setActionsDialogState] = useState<{
        open: boolean;
        anchorEl: any;
    }>({
        open: false,
        anchorEl: null,
    });

    const [deleteState, setDeleteState] = useState<{
        open: boolean;
        isProcessing?: boolean;
        anErrorOccurred?: boolean;
    }>({
        open: false,
        isProcessing: false,
        anErrorOccurred: false,
    });
    const [inEditMode, setInEditMode] = useState(false);
    const [initialState, setInitialState] = useState<{
        loading?: boolean;
        errorMessage?: string;
    }>({ loading: true });

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            managementApi
                .deleteGroup(group.id)
                .then(noop)
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
        }
    };

    const onActionClick = (action: any) => {
        closeActionsDialog();
        switch (action.id) {
            case editAction.id:
                setInEditMode(true);
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    useEffect(() => {
        if (!splat) {
            if (permissions.canViewWindowsManagement) {
                navigate('windows/endpoints', { replace: true });
            } else if (permissions.canViewAndroidManagement) {
                navigate('android/endpoints', { replace: true });
            }
        }

        managementApi
            .getGroup(groupId as string)
            .then((response) => {
                setGroup(response);
                setInitialState({});
            })
            .catch((error) => {
                setInitialState({
                    errorMessage: error.data.message || coreMessage.anUnknownErrorOccurred,
                });
            });
    }, []);

    useGroupSocket(
        groupId as string,
        useCallback<TSocketMethod>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (deleteOperation) {
                navigate('../list');
            } else {
                setGroup((currentGroupState: any) => ({
                    ...currentGroupState,
                    ...updateDescription.updatedFields,
                    ...fullDocument,
                }));
            }
        }, []),
    );

    const memoizedTabs = useMemo(() => {
        const memoTabs = [];

        memoTabs.push({
            value: 'windowsheader',
            disabled: true,
            name: 'Windows',
        });
        memoTabs.push({
            value: 'windows/endpoints',
            name: 'Endpoints',
        });
        memoTabs.push({
            value: 'windows/configurations',
            name: 'Configurations',
        });
        memoTabs.push({
            value: 'windows/applications',
            name: 'Applications',
        });

        if (permissions.canViewAndroidManagement) {
            memoTabs.push({
                value: 'androidheader',
                disabled: true,
                className: 'tw-mt-6',
                name: 'Android',
            });
            memoTabs.push({
                value: 'android/endpoints',
                name: 'Endpoints',
            });
            memoTabs.push({
                value: 'android/configurations',
                name: 'Configurations',
            });
            memoTabs.push({
                value: 'android/applications',
                name: 'Applications',
            });
        }
        if (permissions.canViewAppleManagement) {
            memoTabs.push({
                value: 'appleheader',
                disabled: true,
                className: 'tw-mt-6',
                name: 'Apple',
            });
            memoTabs.push({
                value: 'apple/endpoints',
                name: 'Endpoints',
            });
            memoTabs.push({
                value: 'apple/configurations',
                name: 'Configurations',
            });
            memoTabs.push({
                value: 'apple/applications',
                name: 'Applications',
            });
        }

        return memoTabs;
    }, [permissions]);

    if (initialState.loading) {
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }

    if (initialState.errorMessage) {
        return (
            <LayoutCentered>
                <PageTitle category={coreMessage.anErrorOccurred}>{initialState.errorMessage}</PageTitle>
            </LayoutCentered>
        );
    }

    return (
        <div className="tw-grid tw-h-full tw-grid-cols-auto-1fr tw-grid-rows-auto-1fr">
            <div className="tw-col-span-2 tw-flex tw-items-center tw-gap-4 tw-p-4">
                <PageTitle
                    category="Group"
                    description={group.description}
                >
                    {group.name}
                </PageTitle>
                <div>
                    <IconButton
                        onClick={(e) => {
                            setActionsDialogState({
                                open: true,
                                anchorEl: e.currentTarget,
                            });
                        }}
                        noMargin
                    >
                        <Icon type="moreVert" />
                    </IconButton>
                    <EditOrCreateGroupDialog
                        open={inEditMode}
                        selectedGroup={group}
                        onCancel={() => setInEditMode(false)}
                        onSubmit={() => setInEditMode(false)}
                    />
                    {/* @ts-ignore - this is not typed yet */}
                    <ActionsDialog
                        open={actionsDialogState.open}
                        anchorEl={actionsDialogState.anchorEl}
                        onClose={closeActionsDialog}
                        category="Group"
                        title={group.name}
                        actions={groupActions}
                        // @ts-ignore - this is not typed yet
                        onActionClick={onActionClick}
                    />
                    <ConfirmDialog
                        onConfirm={onDelete}
                        onCancel={closeDeleteDialog}
                        open={deleteState.open}
                        title={`Delete ${group.name}?`}
                        isProcessing={deleteState.isProcessing}
                    >
                        {deleteState.anErrorOccurred && <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{group.name}". Please try again.</b>}
                    </ConfirmDialog>
                </div>
            </div>
            <div className="tw-pl-4 xl:tw-w-64">
                <Tabs
                    value={splat}
                    onChange={(_, newTabValue) => {
                        navigate(newTabValue);
                    }}
                    pills
                    variant="fullWidth"
                    orientation="vertical"
                >
                    {memoizedTabs.map((tab) => {
                        if (tab.disabled) {
                            return (
                                <Tab
                                    value={tab.value}
                                    disabled
                                    className={classNames('tw-opacity-100', tab.className)}
                                    label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{tab.name}</div>}
                                />
                            );
                        }
                        return (
                            <Tab
                                value={tab.value}
                                label={<div className="tw-w-full tw-text-left">{tab.name}</div>}
                            />
                        );
                    })}
                </Tabs>
            </div>
            <div className="tw-h-full tw-px-4 tw-pb-4">
                <Routes>
                    <Route
                        path="windows/endpoints"
                        element={<WindowsEndpointMembershipManagement group={group} />}
                    />
                    <Route
                        path="windows/configurations"
                        element={<WindowsConfigurationMembershipManagement group={group} />}
                    />
                    <Route
                        path="windows/applications"
                        element={<WindowsApplicationMembershipManagement group={group} />}
                    />
                    {permissions.canViewAndroidManagement && (
                        <>
                            <Route
                                path="android/endpoints"
                                element={<AndroidEndpointMembershipManagement group={group} />}
                            />
                            <Route
                                path="android/configurations"
                                element={<AndroidConfigurationMembershipManagement group={group} />}
                            />
                            <Route
                                path="android/applications"
                                element={<AndroidApplicationMembershipManagement group={group} />}
                            />
                        </>
                    )}
                    {permissions.canViewAppleManagement && (
                        <>
                            <Route
                                path="apple/endpoints"
                                element={<AppleEndpointMembershipManagement group={group} />}
                            />
                            <Route
                                path="apple/configurations"
                                element={<AppleConfigurationMembershipManagement group={group} />}
                            />
                            <Route
                                path="apple/applications"
                                element={<AppleApplicationMembershipManagement group={group} />}
                            />
                        </>
                    )}
                    <Route
                        path="*"
                        element={<PageTitle category={coreMessage.anErrorOccurred}>The requested tab was not found</PageTitle>}
                    />
                </Routes>
            </div>
        </div>
    );
};

type AppleEndpointMembershipManagementProps = {
    group: any;
};

const AppleEndpointMembershipManagement: React.FC<AppleEndpointMembershipManagementProps> = ({ group }) => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [appleEndpoints, setAppleEndpoints] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);

    const modelsRef = useRef<{
        [key: string]: string;
    }>({});

    useAppleEndpointsSocket(
        useCallback<TSocketMethod>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setAppleEndpoints((currentAndroidEndpoints) => {
                    return currentAndroidEndpoints.map((endpoint) => {
                        if (endpoint.id === documentId) {
                            return {
                                ...endpoint,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return endpoint;
                    });
                });
            } else if (insertOperation) {
                setAppleEndpoints((currentAndroidEndpoints) => {
                    return [
                        ...currentAndroidEndpoints,
                        {
                            ...fullDocument,
                            id: documentId,
                            name: fullDocument.name,
                            model: fullDocument.data?.ModelName.toLocaleLowerCase().includes('mac')
                                ? fullDocument.data?.ModelName
                                : modelsRef.current[fullDocument.data?.ProductName],
                        },
                    ];
                });
            } else if (deleteOperation) {
                setAppleEndpoints((currentAndroidEndpoints) => {
                    return currentAndroidEndpoints.filter((endpoint) => endpoint.id !== documentId);
                });
            }
        }, []),
    );

    useEffect(() => {
        Promise.allSettled([appleApi.getAppleModels(), appleApi.getEndpoints({ pageSize: 99999 })]).then((results) => {
            const [appleModelsResult, endpointsResult] = results;
            if (appleModelsResult.status === 'fulfilled' && endpointsResult.status === 'fulfilled') {
                const models: any = {};
                appleModelsResult.value.split('\n').forEach((model) => {
                    const splittetModel = model.split(':');
                    if (splittetModel.length !== 2) {
                        return;
                    }
                    models[splittetModel[0].trim()] = splittetModel[1].trim();
                });

                modelsRef.current = models;

                setAppleEndpoints(
                    endpointsResult.value.content.map((d: any) => {
                        if (!d.data?.ModelName) {
                            return d;
                        }

                        if (d.data.ModelName.toLocaleLowerCase().includes('mac')) {
                            d.model = d.data.ModelName;
                        } else {
                            d.model = modelsRef.current[d.data?.ProductName];
                        }

                        return d;
                    }),
                );
                setTransferListProps({});
            } else {
                setErrorMessage('Could not get endpoints');
            }
        });
    }, [appleApi]);

    const onAdd = (endpointIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addAppleEndpointsToGroup(group.id, endpointIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const onRemove = (endpointIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeAndroidEndpointsFromGroup(group.id, endpointIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`apple/endpoint/${rowData.id}/groups`}>
                    <TwoLineCellRenderer
                        main={rowData.name}
                        callToAction
                        secondary={rowData.model}
                    />
                </CapaOneLink>
            );
        }
        return (
            <TwoLineCellRenderer
                main={rowData.name}
                callToAction
                secondary={rowData.model}
            />
        );
    };

    const memoizedEndpoints = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'deviceName',
        });
        return appleEndpoints
            .map((androidEndpoint) => ({
                ...androidEndpoint,
                applied: group.endpointRefs.some((groupEndpoint: any) => groupEndpoint.refId === androidEndpoint.id),
            }))
            .sort(sortingFunction);
    }, [group, appleEndpoints]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedEndpoints}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="endpoint"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedEndpoints.filter((i) => !i.applied).length}
            totalRowCountAssigned={memoizedEndpoints.filter((i) => i.applied === true).length}
            {...transferListProps}
        />
    );
};

type AndroidEndpointMembershipManagementProps = {
    group: any;
};

const AndroidEndpointMembershipManagement: React.FC<AndroidEndpointMembershipManagementProps> = ({ group }) => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [androidEndpoints, setAndroidEndpoints] = useState<TAndroidDeviceWithId[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);
    const navigate = useNavigate();

    useAndroidEndpointsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setAndroidEndpoints((currentAndroidEndpoints) => {
                    return currentAndroidEndpoints.map((endpoint) => {
                        if (endpoint.id === documentId) {
                            return {
                                ...endpoint,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return endpoint;
                    });
                });
            } else if (insertOperation) {
                setAndroidEndpoints((currentAndroidEndpoints) => {
                    return [
                        ...currentAndroidEndpoints,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setAndroidEndpoints((currentAndroidEndpoints) => {
                    return currentAndroidEndpoints.filter((endpoint) => endpoint.id !== documentId);
                });
            }
        }, []),
    );

    useEffect(() => {
        androidApi
            .getDevices({ pageSize: 99999 })
            .then((endpoints) => {
                setAndroidEndpoints(endpoints.content);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get endpoints');
            });
    }, [androidApi]);

    const onAdd = (endpointIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addAndroidEndpointsToGroup(group.id, endpointIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const onRemove = (endpointIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeAndroidEndpointsFromGroup(group.id, endpointIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`android/device/${rowData.id}/groups`}>
                    <TwoLineCellRenderer
                        main={rowData.name}
                        callToAction
                        secondary={rowData.androidData.hardwareInfo?.model}
                    />
                </CapaOneLink>
            );
        }
        return (
            <TwoLineCellRenderer
                main={rowData.name}
                callToAction
                secondary={rowData.androidData.hardwareInfo?.model}
            />
        );
    };

    const memoizedEndpoints = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return androidEndpoints
            .map((androidEndpoint) => ({
                ...androidEndpoint,
                applied: group.endpointRefs.some((groupEndpoint: any) => groupEndpoint.refId === androidEndpoint.id),
            }))
            .sort(sortingFunction);
    }, [group, androidEndpoints]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedEndpoints}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="endpoint"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedEndpoints.filter((i) => !i.applied).length}
            totalRowCountAssigned={memoizedEndpoints.filter((i) => i.applied === true).length}
            {...transferListProps}
        />
    );
};

type AndroidApplicationMembershipManagementProps = {
    group: any;
};

const AndroidApplicationMembershipManagement: React.FC<AndroidApplicationMembershipManagementProps> = ({ group }) => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [applicationsList, setApplicationsList] = useState<TAndroidConfigurationWithId[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [dimensions, setDimensions] = useState({});
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        androidApi
            .getApplications()
            .then((applications) => {
                setApplicationsList(applications);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
            });
    }, [androidApi]);

    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return applicationsList
            .map((application) => ({
                ...application,
                applied: group.applicationRefs.some((a: any) => a.refId === application.id),
            }))
            .sort(sortingFunction);
    }, [group, applicationsList]);

    useAndroidApplicationsSocket(
        useCallback<TSocketMethod<TAndroidConfigurationWithId>>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation }) => {
            const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
            if (updateOperation) {
                setApplicationsList((currentApplications) => {
                    return currentApplications.map((application) => {
                        if (application.id === documentId) {
                            return {
                                ...streamlinedApplicationDocument,
                                id: documentId,
                            };
                        }
                        return application;
                    });
                });
            } else if (insertOperation) {
                setApplicationsList((currentApplications) => {
                    return [
                        ...currentApplications,
                        {
                            ...streamlinedApplicationDocument,
                            id: documentId,
                        },
                    ];
                });
            }
        }, []),
    );

    const onAdd = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addApplicationsToGroup(group.id, applicationIds, 'android')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const onRemove = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });

        managementApi
            .removeApplicationsFromGroup(group.id, applicationIds, 'android')
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`android/application/${rowData.id}/membership?tab=groups`}>
                    <AndroidApplicationCellRenderer
                        dimensions={dimensions}
                        {...rowData}
                    />
                </CapaOneLink>
            );
        }
        return (
            <AndroidApplicationCellRenderer
                dimensions={dimensions}
                {...rowData}
            />
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedApplications}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="application"
            onResize={setDimensions}
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedApplications.filter((a) => !a.applied).length}
            totalRowCountAssigned={memoizedApplications.filter((a) => a.applied === true).length}
            {...transferListProps}
        />
    );
};

type AndroidApplicationCellRendererProps = {
    iconUrl: string;
    name: string;
    installTypeName: string;
    dimensions: any;
};

const AndroidApplicationCellRenderer: React.FC<AndroidApplicationCellRendererProps> = ({ iconUrl, name, installTypeName, dimensions }) => {
    if (dimensions.width < 400) {
        return (
            <TwoLineCellRenderer
                main={name}
                callToAction
                secondary={installTypeName}
            />
        );
    }
    return (
        <LayoutRow verticalAlign="center">
            {iconUrl ? (
                <Avatar
                    src={iconUrl}
                    alt={name}
                    variant="rounded"
                    className="tw-h-8 tw-w-8"
                />
            ) : (
                <Icon
                    type="app"
                    className="tw-h-8 tw-w-8"
                />
            )}
            <div className="tw-ml-4 tw-flex-1 tw-overflow-auto">
                <TwoLineCellRenderer
                    main={name}
                    callToAction
                    secondary={installTypeName}
                />
            </div>
        </LayoutRow>
    );
};

type AppleApplicationMembershipManagementProps = {
    group: any;
};

const AppleApplicationMembershipManagement: React.FC<AppleApplicationMembershipManagementProps> = ({ group }) => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [applicationsList, setApplicationsList] = useState<TAppleApplicationWithId[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [dimensions, setDimensions] = useState<{
        width?: number;
        height?: number;
    }>({});
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        appleApi
            .getAppleApplications()
            .then((applications) => {
                setApplicationsList(applications);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
            });
    }, [appleApi]);

    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return applicationsList
            .map((application) => ({
                ...application,
                applied: group.applicationRefs.some((a: any) => a.refId === application.id),
            }))
            .sort(sortingFunction);
    }, [group, applicationsList]);

    useAppleApplicationsSocket(
        useCallback<TSocketMethod<TAppleApplicationWithId>>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation }) => {
            // const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
            if (updateOperation) {
                setApplicationsList((currentApplications) => {
                    return currentApplications.map((application) => {
                        if (application.id === documentId) {
                            return {
                                ...fullDocument,
                                id: documentId,
                            };
                        }
                        return application;
                    });
                });
            } else if (insertOperation) {
                setApplicationsList((currentApplications) => {
                    return [
                        ...currentApplications,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            }
        }, []),
    );

    const onAdd = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addApplicationsToGroup(group.id, applicationIds, 'apple')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const onRemove = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });

        managementApi
            .removeApplicationsFromGroup(group.id, applicationIds, 'apple')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`apple/application/${rowData.id}/membership?tab=groups`}>
                    <AppleApplicationCellRenderer
                        dimensions={dimensions}
                        {...rowData}
                    />
                </CapaOneLink>
            );
        }
        return (
            <AppleApplicationCellRenderer
                dimensions={dimensions}
                {...rowData}
            />
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedApplications}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="application"
            onResize={setDimensions}
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedApplications.filter((a) => !a.applied).length}
            totalRowCountAssigned={memoizedApplications.filter((a) => a.applied === true).length}
            {...transferListProps}
        />
    );
};

type AppleApplicationCellRendererProps = {
    iconUrl: string;
    name: string;
    installTypeName: string;
    dimensions: any;
};

const AppleApplicationCellRenderer: React.FC<AppleApplicationCellRendererProps> = ({ iconUrl, name, installTypeName, dimensions }) => {
    if (dimensions.width < 400) {
        return (
            <TwoLineCellRenderer
                main={name}
                callToAction
                secondary={installTypeName}
            />
        );
    }
    return (
        <LayoutRow verticalAlign="center">
            {iconUrl ? (
                <Avatar
                    src={iconUrl}
                    alt={name}
                    variant="rounded"
                    className="tw-h-8 tw-w-8"
                />
            ) : (
                <Icon
                    type="app"
                    className="tw-h-8 tw-w-8"
                />
            )}
            <div className="tw-ml-4 tw-flex-1 tw-overflow-auto">
                <TwoLineCellRenderer
                    main={name}
                    callToAction
                    secondary={installTypeName}
                />
            </div>
        </LayoutRow>
    );
};

type AndroidConfigurationMembershipManagementProps = {
    group: any;
};

const AndroidConfigurationMembershipManagement: React.FC<AndroidConfigurationMembershipManagementProps> = ({ group }) => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [configurationsList, setConfigurationsList] = useState<TAndroidConfigurationWithId[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        androidApi
            .getAndroidConfigurations()
            .then((configurations) => {
                setConfigurationsList(configurations);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
            });
    }, [androidApi]);

    const memoizedConfigurations = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return configurationsList
            .map((configuration) => ({
                ...configuration,
                applied: group.configurationRefs.some((config: any) => config.refId === configuration.id),
            }))
            .sort(sortingFunction);
    }, [group, configurationsList]);

    useAndroidConfigurationsSocket(
        useCallback<TSocketMethod<TAndroidConfigurationWithId>>(
            ({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
                // TODO: Use groups socket to catch updates too
                if (updateOperation) {
                    setConfigurationsList((currentConfigurations) => {
                        return currentConfigurations.map((configuration) => {
                            if (configuration.id === documentId) {
                                return {
                                    ...configuration,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return configuration;
                        });
                    });
                } else if (insertOperation) {
                    setConfigurationsList((currentConfigurations) => {
                        return [
                            ...currentConfigurations,
                            {
                                ...fullDocument,
                                id: documentId,
                            },
                        ];
                    });
                } else if (deleteOperation) {
                    setConfigurationsList((currentList) => {
                        return currentList.filter((configuration) => configuration.id !== documentId);
                    });
                }
            },
            [],
        ),
    );

    const onAdd = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addAndroidConfigurationsToGroup(group.id, configurationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const onRemove = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeConfigurationsFromGroup(group.id, configurationIds, 'android')
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`android/configuration/${rowData.id}/membership?tab=groups`}>
                    <b>{rowData.name}</b>
                </CapaOneLink>
            );
        }
        return <b>{rowData.name}</b>;
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedConfigurations}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="configuration"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedConfigurations.filter((c) => !c.applied).length}
            totalRowCountAssigned={memoizedConfigurations.filter((c) => c.applied === true).length}
            {...transferListProps}
        />
    );
};

type WindowsEndpointMembershipManagementProps = {
    group: any;
};

const WindowsEndpointMembershipManagement: React.FC<WindowsEndpointMembershipManagementProps> = ({ group }) => {
    const api = useApi();
    const managementApi = useManagementApi();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        loading?: boolean;
        savingChanges?: boolean;
        errorMessage?: string;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);
    const [pagingState, setPagingState] = useState<{
        pageNumber: number;
        pageSize: number;
        sortBy: string;
        sortDirection: string;
        filters: any;
    }>({
        pageNumber: 1,
        pageSize: 100,
        sortBy: Url.getString('sortBy', 'name') as string,
        sortDirection: Url.getString('sortDir', SORT_DIRECTION.ASC) as string,
        filters: null,
    });
    const [pagingStateAppliedList, setPagingStateAppliedList] = useState({
        pageNumber: 1,
        pageSize: 100,
        sortBy: Url.getString('sortBy', 'name'),
        sortDirection: Url.getString('sortDir', SORT_DIRECTION.ASC),
        filters: null,
    });
    const [devices, setDevices] = useState<any[]>([]);
    const [errorDetails, setErrorDetails] = useState(null);
    const [showDeviceFilterPicker, setShowDeviceFilterPicker] = useState(false);
    const [urlIsDirty, setUrlIsDirty] = useState(false);
    const [groupValue, setGroupValue] = useState(group);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedAllRemove, setCheckedAllRemove] = useState(false);
    const [cleanUp, setCleanUp] = useState(false);

    const pagingRef = useRef<{
        isFirstPage: boolean;
        isLastPage: boolean | undefined;
        totalRowCount: number;
        isPureDeviceNameSearch: boolean;
    }>({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });

    const pagingRefApplied = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });

    useDevicesSocket(
        useCallback<TSocketMethod>(
            ({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
                // if ( updateOperation ){
                //     setDevices(currentItems => {
                //         return currentItems.map(endpoint => {
                //             if (endpoint.id === documentId) {
                //                 return ({
                //                     ...endpoint,
                //                     ...updateDescription.updatedFields,
                //                     applied: fullDocument.groupIds.includes(group.id),
                //                 });
                //             }
                //             return endpoint;
                //         });
                //     });
                // }
                if (insertOperation) {
                    setDevices((currentItems) => {
                        return [
                            ...currentItems,
                            {
                                ...fullDocument,
                                id: documentId,
                                applied: false,
                            },
                        ];
                    });
                } else if (deleteOperation) {
                    setDevices((currentList) => {
                        return currentList.filter((endpoint) => endpoint.id !== documentId);
                    });
                }
            },
            [group],
        ),
    );

    const onFiltersChange = (filters: any) => {
        setCleanUp(true);
        pagingRef.current.isFirstPage = true;
        pagingRef.current.isLastPage = undefined;
        setPagingState((currentPagingState: any) => ({
            ...currentPagingState,
            pageNumber: 1,
            filters: JSON.stringify(filters),
        }));
        pagingRefApplied.current.isFirstPage = true;
        pagingRefApplied.current.isLastPage = undefined;
        setPagingStateAppliedList((currentPagingState: any) => ({
            ...currentPagingState,
            pageNumber: 1,
            filters: JSON.stringify(filters),
        }));
    };

    const onInit = (activeLeafs: any, _: any, negations: any) => {
        onSubmit(activeLeafs, _, negations);
    };

    const onSubmit = (activeLeafs: any, _: any, negations: any) => {
        if (activeLeafs.length === 1) {
            pagingRef.current.isPureDeviceNameSearch = isDefined(
                activeLeafs.find((leaf: any) => leaf.id === 'name' && leaf.operator.id === LEAF_OPERATOR.CONTAINS.id),
            );
        } else {
            pagingRef.current.isPureDeviceNameSearch = false;
        }
        const mongoedLeafs = leafsToMongo(activeLeafs, negations); // A filter object
        onFiltersChange(mongoedLeafs);
    };

    const handleCustomAction = (requestedAction: any, value: any) => () => {
        requestedAction(value);
    };

    const fetchData = () => {
        if (pagingState.filters) {
            setTransferListProps({ loading: true });
            Promise.all([
                managementApi.getAvailableWindowsEndpointsForGroup(groupValue.id, 1, pagingState.filters),
                managementApi.getWindowsEndpointsAssignedToGroup(groupValue.id, 1, pagingState.filters),
            ])
                .then(([available, assigned]) => {
                    pagingRef.current.isFirstPage = available.first;
                    pagingRef.current.isLastPage = available.last;
                    pagingRef.current.totalRowCount = available.totalElements;
                    pagingRefApplied.current.isFirstPage = assigned.first;
                    pagingRefApplied.current.isLastPage = assigned.last;
                    pagingRefApplied.current.totalRowCount = assigned.totalElements;
                    setDevices(
                        available.content
                            .map((a: any) => {
                                return { ...a, applied: false };
                            })
                            .concat(
                                assigned.content.map((a: any) => {
                                    return { ...a, applied: true };
                                }),
                            ),
                    );
                })
                .catch(() => {
                    // setErrorMessage(setErrorDetails);
                })
                .finally(() => {
                    setTransferListProps({ loading: false });
                });
        }
    };

    const fetchAvailableList = () => {
        setTransferListProps({ loading: true });
        managementApi
            .getAvailableWindowsEndpointsForGroup(groupValue.id, pagingState.pageNumber, pagingState.filters)
            .then((response) => {
                pagingRef.current.isFirstPage = response.first;
                pagingRef.current.isLastPage = response.last;
                pagingRef.current.totalRowCount = response.totalElements;
                setDevices((currentDevices) => [
                    ...currentDevices,
                    ...response.content.map((r: any) => {
                        return { ...r, applied: false };
                    }),
                ]);
            })
            .catch(() => {
                // setErrorMessage(setErrorDetails);
            })
            .finally(() => {
                setTransferListProps({ loading: false });
            });
    };

    const fetchAppliedList = () => {
        setTransferListProps({ loading: true });
        managementApi
            .getWindowsEndpointsAssignedToGroup(groupValue.id, pagingStateAppliedList.pageNumber, pagingState.filters)
            .then((response) => {
                pagingRefApplied.current.isFirstPage = response.first;
                pagingRefApplied.current.isLastPage = response.last;
                pagingRefApplied.current.totalRowCount = response.totalElements;
                setDevices((currentDevices) => [
                    ...currentDevices,
                    ...response.content.map((r: any) => {
                        return { ...r, applied: true };
                    }),
                ]);
            })
            .catch(() => {
                // setErrorMessage(setErrorDetails);
            })
            .finally(() => {
                setTransferListProps({ loading: false });
                setCleanUp(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [pagingState.filters]);

    useEffect(() => {
        if (pagingState.pageNumber > 1) {
            fetchAvailableList();
        }
    }, [pagingState.pageNumber]);

    useEffect(() => {
        if (pagingStateAppliedList.pageNumber > 1) {
            fetchAppliedList();
        }
    }, [pagingStateAppliedList.pageNumber]);

    useEffect(() => {
        setCheckedAll(false);
        setCheckedAllRemove(false);
    }, [viewMode]);

    const onAdd = (endpointIds: string[], excludedEndpoints: string[], includedEndpoints: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addWindowsEndpointsToGroup(
                groupValue.id,
                checkedAll ? [] : endpointIds,
                pagingState.filters,
                checkedAll ? excludedEndpoints : [],
                checkedAll ? includedEndpoints : [],
            )
            .then(fetchData)
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const onRemove = (endpointIds: string[], excludedEndpoints: string[], includedEndpoints: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeWindowsEndpointsFromGroup(
                groupValue.id,
                checkedAllRemove ? [] : endpointIds,
                pagingState.filters,
                checkedAllRemove ? excludedEndpoints : [],
                checkedAllRemove ? includedEndpoints : [],
            )
            .then(fetchData)
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('endpoint', endpointIds.length)}`,
                });
            });
    };

    const savedFilterPicked = (filters: any) => {
        const tempFilter = filters.query.replace('"', '"');
        setPagingState((currentPagingState) => ({
            ...currentPagingState,
            filters: tempFilter,
        }));
        setCleanUp(true);
        const urlParams = queryBuilderQueryStringToUrlParams(filters.query, QUERY_BUILDER_REF_DEVICE);
        navigate(`?${urlParams}`);
        setUrlIsDirty(true);
        setShowDeviceFilterPicker(false);
    };

    const onScrollToBottom = () => {
        if (!pagingRef.current.isLastPage) {
            setCleanUp(false);
            setPagingState((currentPagingState) => ({
                ...currentPagingState,
                pageNumber: currentPagingState.pageNumber + 1,
            }));
        }
    };

    const onScrollToBottomApplied = () => {
        if (!pagingRefApplied.current.isLastPage) {
            setCleanUp(false);
            setPagingStateAppliedList((currentPagingState) => ({
                ...currentPagingState,
                pageNumber: currentPagingState.pageNumber + 1,
            }));
        }
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`windows/device/${rowData.id}`}>
                    <b>{rowData.name}</b>
                </CapaOneLink>
            );
        }
        return <b>{rowData.name}</b>;
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={devices}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="endpoint"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            hasFilter={true}
            init={onInit}
            submit={onSubmit}
            endpointFilter={true}
            totalRowCount={pagingRef.current.totalRowCount}
            totalRowCountAssigned={pagingRefApplied.current.totalRowCount}
            handleCustomAction={handleCustomAction}
            deviceFilterPicker={
                <WindowsEndpointsFilterPicker
                    open={showDeviceFilterPicker}
                    onClose={handleCustomAction(setShowDeviceFilterPicker, false)}
                    filterPick={savedFilterPicked}
                    groups={true}
                />
            }
            setShowDeviceFilterPicker={setShowDeviceFilterPicker}
            urlIsDirty={urlIsDirty}
            onScrollToBottom={onScrollToBottom}
            onScrollToBottomAssigned={onScrollToBottomApplied}
            checkedAll={checkedAll}
            setCheckedAll={setCheckedAll}
            checkedAllAssigned={checkedAllRemove}
            setCheckedAllAssigned={setCheckedAllRemove}
            cleanUp={cleanUp}
            queryBuilder={QUERY_BUILDER_REF_DEVICE}
            {...transferListProps}
        />
    );
};

type WindowsConfigurationMembershipManagementProps = {
    group: any;
};

const WindowsConfigurationMembershipManagement: React.FC<WindowsConfigurationMembershipManagementProps> = ({ group }) => {
    const api = useApi();
    const managementApi = useManagementApi();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);
    const [availableConfigurations, setAvailableConfigurations] = useState<any[]>([]);
    const [activeFilters, setActiveFilters] = useState<any[] | null>(null);
    const [groupValue, setGroupValue] = useState<any>(group);
    const [cleanUp, setCleanUp] = useState(false);

    useConfigurationSocket(
        useCallback<TSocketMethod>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setAvailableConfigurations((currentConfigurations) => {
                    return currentConfigurations.map((configuration) => {
                        if (configuration.id === documentId) {
                            return {
                                ...configuration,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return configuration;
                    });
                });
            } else if (insertOperation) {
                setAvailableConfigurations((currentConfigurations) => {
                    return [
                        ...currentConfigurations,
                        {
                            ...fullDocument,
                            id: documentId,
                            applied: false,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setAvailableConfigurations((currentList) => {
                    return currentList.filter((configuration) => configuration.id !== documentId);
                });
            }
        }, []),
    );

    const onSubmit = (activeLeafs: any) => {
        setCleanUp(true);
        setActiveFilters(activeLeafs);
    };

    const fetchData = () => {
        if (activeFilters === null) {
            return;
        }
        setTransferListProps({ loading: true });
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        Promise.all([managementApi.getWindowsConfigurationsForGroup(groupValue.id), api.getConfigurations(searchFilter?.value)])
            .then(([groupResponse, configurationResponse]) => {
                const tempConfigs = configurationResponse.content.map((c: any) => {
                    if (groupResponse.configurationRefs.find((d: any) => d.refId === c.id)) {
                        return { ...c, applied: true, name: c.name };
                    }
                    return { ...c, applied: false };
                });
                setAvailableConfigurations(tempConfigs);
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
                setTransferListProps({
                    savingChanges: false,
                });
            })
            .finally(() => {
                setTransferListProps({ loading: false });
                setCleanUp(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [activeFilters]);

    const onAdd = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addWindowsConfigurationsToGroup(group.id, configurationIds)
            .then(fetchData)
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const onRemove = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeConfigurationsFromGroup(group.id, configurationIds, 'windows')
            .then(fetchData)
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`windows/configuration/${rowData.id}/membership`}>
                    <TwoLineCellRenderer
                        main={rowData.name}
                        callToAction
                        secondary={rowData.type}
                    />
                </CapaOneLink>
            );
        }
        return (
            <TwoLineCellRenderer
                main={rowData.name}
                callToAction
                secondary={rowData.type}
            />
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={availableConfigurations}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="configuration"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            hasFilter={true}
            submit={onSubmit}
            init={onSubmit}
            totalRowCount={availableConfigurations.filter((c) => !c.applied).length}
            totalRowCountAssigned={availableConfigurations.filter((c) => c.applied === true).length}
            cleanUp={cleanUp}
            {...transferListProps}
        />
    );
};

type WindowsApplicationMembershipManagementProps = {
    group: any;
};

const WindowsApplicationMembershipManagement: React.FC<WindowsApplicationMembershipManagementProps> = ({ group }) => {
    const api = useApi();
    const managementApi = useManagementApi();
    const [applicationsList, setApplicationsList] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [groupValue, setGroupValue] = useState(group);
    const [dimensions, setDimensions] = useState<{
        width?: number;
        height?: number;
    }>({});
    const [viewMode, setViewMode] = useState(true);

    const requiredConfigFields = (config: any) => {
        if (!config.commandLineOptions || config.commandLineOptions.trim() === '') {
            return false;
        }
        return true;
    };

    useEffect(() => {
        Promise.all([managementApi.getWindowsApplicationsForGroup(groupValue.id), api.getApplicationConfigurations()])
            .then(([groupResponse, applicationResponse]) => {
                const tempApplications = applicationResponse.map((a: any) => {
                    if (groupResponse.applicationRefs.find((d: any) => d.refId === a.id)) {
                        return { ...a, applied: true, name: a.name };
                    }
                    return { ...a, applied: false };
                });
                const filteredList = tempApplications.filter((application: any) => {
                    return !(
                        application.type === windowsApplication.type.customApp.id &&
                        ((!requiredConfigFields(application.config) && application.fileInfo?.fileUploaded === true && application.fileInfo?.failed === false) ||
                            application.fileInfo?.failed ||
                            application.fileInfo?.fileUploaded === false)
                    );
                });
                setApplicationsList(filteredList);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get applications');
            });
    }, [api]);

    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return applicationsList
            .map((application) => ({
                ...application,
                applied: group.applicationRefs.some((a: any) => a.refId === application.id),
            }))
            .filter(windowsApplicationUtils.fileUploadCompleted)
            .sort(sortingFunction);
    }, [group, applicationsList]);

    useApplicationSocket(
        useCallback<TSocketMethod>(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation }) => {
            const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
            if (updateOperation) {
                setApplicationsList((currentApplications) => {
                    return currentApplications.map((application) => {
                        if (application.id === documentId) {
                            return {
                                ...streamlinedApplicationDocument,
                                id: documentId,
                            };
                        }
                        return application;
                    });
                });
            } else if (insertOperation) {
                setApplicationsList((currentApplications) => {
                    return [
                        ...currentApplications,
                        {
                            ...streamlinedApplicationDocument,
                            id: documentId,
                        },
                    ];
                });
            }
        }, []),
    );

    const onAdd = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addApplicationsToGroup(group.id, applicationIds, 'windows')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const onRemove = (applicationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });

        managementApi
            .removeApplicationsFromGroup(group.id, applicationIds, 'windows')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }, { isAssigned }: { isAssigned: boolean }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`windows/application/${rowData.id}/membership?tab=groups`}>
                    <WindowsApplicationCellRenderer
                        dimensions={dimensions}
                        viewMode={viewMode}
                        isAssigned={isAssigned}
                        application={rowData}
                    />
                </CapaOneLink>
            );
        }
        return (
            <WindowsApplicationCellRenderer
                dimensions={dimensions}
                viewMode={viewMode}
                isAssigned={isAssigned}
                application={rowData}
            />
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        // @ts-ignore - this is not typed yet
        <WindowsApplicationsTransferList
            items={memoizedApplications}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="application"
            onResize={setDimensions}
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedApplications.filter((a) => !a.applied).length}
            totalRowCountAssigned={memoizedApplications.filter((a) => a.applied === true).length}
            {...transferListProps}
        />
    );
};

type WindowsApplicationCellRendererProps = {
    application: any;
    dimensions: any;
    viewMode: boolean;
    isAssigned: boolean;
};

const WindowsApplicationCellRenderer: React.FC<WindowsApplicationCellRendererProps> = ({ application, dimensions, viewMode, isAssigned }) => {
    return (
        <LayoutRow verticalAlign="center">
            {dimensions.width > 400 && (
                // @ts-ignore - this is not typed yet
                <WindowsApplicationThumbnail
                    application={application}
                    size="large"
                    className="tw-mr-4"
                />
            )}
            <div className="tw-flex-1 tw-overflow-hidden">
                <TwoLineCellRenderer
                    // @ts-ignore - this is not typed yet
                    main={<WindowsApplicationNameAndVersion application={application} />}
                    callToAction
                    // @ts-ignore - this is not typed yet
                    secondary={<WindowsApplicationConfigDetails application={application} />}
                />
            </div>
            {application.deprecated && (
                <Tooltip content="The selected version for this application is no longer supported in CapaOne. Edit or delete the application">
                    {/* @ts-ignore - this is not typed yet */}
                    <TailwindBadge
                        size="small"
                        color="red"
                    >
                        Deprecated
                    </TailwindBadge>
                </Tooltip>
            )}
        </LayoutRow>
    );
};

type AppleConfigurationMembershipManagementProps = {
    group: any;
};

const AppleConfigurationMembershipManagement: React.FC<AppleConfigurationMembershipManagementProps> = ({ group }) => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [configurationsList, setConfigurationsList] = useState<TAppleConfigurationWithId[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{
        savingChanges?: boolean;
        errorMessage?: string;
        loading?: boolean;
    }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        appleApi
            .getAppleConfigurations()
            .then((configurations) => {
                setConfigurationsList(configurations);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
            });
    }, [appleApi]);

    const memoizedConfigurations = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return configurationsList
            .map((configuration) => ({
                ...configuration,
                applied: group.configurationRefs.some((config: any) => config.refId === configuration.id),
            }))
            .sort(sortingFunction);
    }, [group, configurationsList]);

    useAppleConfigurationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            // TODO: Use groups socket to catch updates too
            if (updateOperation) {
                setConfigurationsList((currentConfigurations) => {
                    return currentConfigurations.map((configuration) => {
                        if (configuration.id === documentId) {
                            return {
                                ...configuration,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return configuration;
                    });
                });
            } else if (insertOperation) {
                setConfigurationsList((currentConfigurations) => {
                    return [
                        ...currentConfigurations,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setConfigurationsList((currentList) => {
                    return currentList.filter((configuration) => configuration.id !== documentId);
                });
            }
        }, []),
    );

    const onAdd = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addAppleConfigurationsToGroup(group.id, configurationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const onRemove = (configurationIds: string[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeConfigurationsFromGroup(group.id, configurationIds, 'apple')
            .then(() => {
                setTransferListProps({});
            })
            .catch((response) => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: TAppleConfigurationWithId }) => {
        if (viewMode) {
            return (
                // @ts-ignore - this is not typed yet
                <CapaOneLink to={`apple/configuration/${rowData.id}/membership?tab=groups`}>
                    <b>{rowData.name}</b>
                </CapaOneLink>
            );
        }
        return <b>{rowData.name}</b>;
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-ignore - this is not typed yet
            items={memoizedConfigurations}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="configuration"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedConfigurations.filter((c) => !c.applied).length}
            totalRowCountAssigned={memoizedConfigurations.filter((c) => c.applied === true).length}
            {...transferListProps}
        />
    );
};
